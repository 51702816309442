import { notification, Button } from 'antd';
import { get } from "lodash"

export const showSuccess = (str, strings) => {
    notification.success({
        key: "success-notif",
        message: strings(str)
    });
}

export const showError = (str, strings, description, error) => {
    const code = get(error, ["response", "data", "error"], null)
    if (!code) {
        notification.error({message: strings(str), description: description});
    }
}

export const showInfo = (str, strings, description) => {
    const key = `open${Date.now()}`;
    const btn = (
        <Button type="primary" size="small" onClick={() => notification.close(key)}>
            Ok
        </Button>
    );
    notification.config({duration: 0})
    notification.info({message: strings(str), description: description, btn, key});
    notification.config({duration: 6})
}
