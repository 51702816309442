import { createAction } from "../../utils";

// Types
export const types = {
  GET_COURSES_REQUEST: "GET_COURSES_REQUEST",
  GET_COURSES_SUCCESS: "GET_COURSES_SUCCESS",
  GET_COURSES_FAILURE: "GET_COURSES_FAILURE",

  GET_COURSE_REQUEST: "GET_COURSE_REQUEST",
  GET_COURSE_SUCCESS: "GET_COURSE_SUCCESS",
  GET_COURSE_FAILURE: "GET_COURSE_FAILURE",

  DELETE_TASK_REQUEST: "DELETE_TASK_REQUEST",
  DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAILURE: "DELETE_TASK_FAILURE",

  CREATE_COURSE_REQUEST: "CREATE_COURSE_REQUEST",
  CREATE_COURSE_SUCCESS: "CREATE_COURSE_SUCCESS",
  CREATE_COURSE_FAILURE: "CREATE_COURSE_FAILURE",

  MODIFY_COURSE_REQUEST: "MODIFY_COURSE_REQUEST",
  MODIFY_COURSE_SUCCESS: "MODIFY_COURSE_SUCCESS",
  MODIFY_COURSE_FAILURE: "MODIFY_COURSE_FAILURE",

  UPDATE_COURSES_STATE_REDUC: "UPDATE_COURSES_STATE_REDUC",

  DELETE_ORDER_SUCCESS: "DELETE_ORDER_SUCCESS",

  DELETE_COURSE_SUCCESS: "DELETE_COURSE_SUCCESS",

  RESET_TASKS_REDUC: "RESET_TASKS_REDUC",

  RESET_CURRENT_COURSE_REDUC: "RESET_CURRENT_COURSE_REDUC",

  MODIFY_TASK_REQUEST: "MODIFY_TASK_REQUEST",
  MODIFY_TASK_SUCCESS: "MODIFY_TASK_SUCCESS",
  MODIFY_TASK_FAILURE: "MODIFY_TASK_FAILURE",

  UPDATE_COURSES_ORDER_REQUEST: "UPDATE_COURSES_ORDER_REQUEST",
  UPDATE_COURSES_ORDER_SUCCESS: "UPDATE_COURSES_ORDER_SUCCESS",
  UPDATE_COURSES_ORDER_FAILURE: "UPDATE_COURSES_ORDER_FAILURE",

  MODIFY_ONLY_COURSE_REQUEST: "MODIFY_ONLY_COURSE_REQUEST",

  GENERATE_PDF_TASK_REQUEST: "GENERATE_PDF_TASK_REQUEST",
  GENERATE_PDF_TASK_SUCCESS: "GENERATE_PDF_TASK_SUCCESS",
  GENERATE_PDF_TASK_FAILURE: "GENERATE_PDF_TASK_FAILURE",

  UPDATE_FILTERS_REDUC: "UPDATE_FILTERS_REDUC",
  UPDATE_FILTERS_DEVIS_REDUC: "UPDATE_FILTERS_DEVIS_REDUC",
  UPDATE_FILTERS_TO_TREAT_REDUC: "UPDATE_FILTERS_TO_TREAT_REDUC",

  GET_COURSES_DEVIS_REQUEST: "GET_COURSES_DEVIS_REQUEST",
  GET_COURSES_DEVIS_SUCCESS: "GET_COURSES_DEVIS_SUCCESS",
  GET_COURSES_DEVIS_FAILURE: "GET_COURSES_DEVIS_FAILURE",

  GET_MORE_COURSES_REQUEST: "GET_MORE_COURSES_REQUEST",
  GET_MORE_COURSES_SUCCESS: "GET_MORE_COURSES_SUCCESS",
  GET_MORE_COURSES_FAILURE: "GET_MORE_COURSES_FAILURE",

  GET_STATISTICS_COURSES_REQUEST: "GET_STATISTICS_COURSES_REQUEST",
  GET_STATISTICS_COURSES_SUCCESS: "GET_STATISTICS_COURSES_SUCCESS",
  GET_STATISTICS_COURSES_FAILURE: "GET_STATISTICS_COURSES_FAILURE",

  GET_COURSES_TO_TREAT_REQUEST: "GET_COURSES_TO_TREAT_REQUEST",
  GET_COURSES_TO_TREAT_SUCCESS: "GET_COURSES_TO_TREAT_SUCCESS",
  GET_COURSES_TO_TREAT_FAILURE: "GET_COURSES_TO_TREAT_FAILURE",

  EXPORT_COURSES: "EXPORT_COURSES",

  UPDATE_ACTIVE_LIST: "UPDATE_ACTIVE_LIST"
};

// Actions
export default {
  export: ({filter, activeTab, total}) =>
      createAction(types.EXPORT_COURSES, { filter, activeTab, total}),
  updateActiveList: (data) =>
      createAction(types.UPDATE_ACTIVE_LIST, { data }),  

  updateFiltersDevisReduc: (filterDevis) =>
    createAction(types.UPDATE_FILTERS_DEVIS_REDUC, { filterDevis }),
  updateFiltersToTreatReduc: (filterToTreat) =>
    createAction(types.UPDATE_FILTERS_TO_TREAT_REDUC, { filterToTreat }),
  updateFiltersReduc: (filters) =>
    createAction(types.UPDATE_FILTERS_REDUC, { filters }),

  getStatisticsCoursesRequest: () =>
    createAction(types.GET_STATISTICS_COURSES_REQUEST),
  getStatisticsCoursesSuccess: (data) =>
    createAction(types.GET_STATISTICS_COURSES_SUCCESS, { data }),
  getStatisticsCoursesFailure: () =>
    createAction(types.GET_STATISTICS_COURSES_FAILURE),

  generatePdfTaskRequest: (task, isDymo = false) =>
    createAction(types.GENERATE_PDF_TASK_REQUEST, { task, isDymo }),
  generatePdfTaskSuccess: (data) =>
    createAction(types.GENERATE_PDF_TASK_SUCCESS, { data }),
  generatePdfTaskFailure: () => createAction(types.GENERATE_PDF_TASK_FAILURE),

  getCoursesRequest: (values, dispatch) =>
    createAction(types.GET_COURSES_REQUEST, { values, dispatch }),
  getCoursesSuccess: (data) =>
    createAction(types.GET_COURSES_SUCCESS, { data }),
  getCoursesFailure: () => createAction(types.GET_COURSES_FAILURE),

  getMoreCoursesRequest: (ids) =>
    createAction(types.GET_MORE_COURSES_REQUEST, { ids }),
  getMoreCoursesSuccess: (data) =>
    createAction(types.GET_MORE_COURSES_SUCCESS, { data }),
  getMoreCoursesFailure: () => createAction(types.GET_MORE_COURSES_FAILURE),

  getCoursesDevisRequest: (values, dispatch) =>
    createAction(types.GET_COURSES_DEVIS_REQUEST, { values, dispatch }),
  getCoursesDevisSuccess: (data) =>
    createAction(types.GET_COURSES_DEVIS_SUCCESS, { data }),
  getCoursesDevisFailure: () => createAction(types.GET_COURSES_DEVIS_FAILURE),

  updateOrderCoursesRequest: (values) =>
    createAction(types.UPDATE_COURSES_ORDER_REQUEST, { values }),
  updateOrderCoursesSuccess: (data) =>
    createAction(types.UPDATE_COURSES_ORDER_SUCCESS, { data }),
  updateOrderCoursesFailure: () =>
    createAction(types.UPDATE_COURSES_ORDER_FAILURE),

  getCourseRequest: (values, dispatch) =>
    createAction(types.GET_COURSE_REQUEST, { values, dispatch }),
  getCourseSuccess: (data) => createAction(types.GET_COURSE_SUCCESS, { data }),
  getCourseFailure: () => createAction(types.GET_COURSE_FAILURE),

  getCoursesToTreatRequest: (values, dispatch) =>
    createAction(types.GET_COURSES_TO_TREAT_REQUEST, { values, dispatch }),
  getCoursesToTreatSuccess: (data) =>
    createAction(types.GET_COURSES_TO_TREAT_SUCCESS, { data }),
  getCoursesToTreatFailure: () =>
    createAction(types.GET_COURSES_TO_TREAT_FAILURE),

  deleteTaskRequest: (values, deleteCourseId) =>
    createAction(types.DELETE_TASK_REQUEST, { values, deleteCourseId }),
  deleteTaskSuccess: (data, deleteCourseId) =>
    createAction(types.DELETE_TASK_SUCCESS, { data, deleteCourseId }),
  deleteTaskFailure: () => createAction(types.DELETE_TASK_FAILURE),

  createCourseRequest: (
    values,
    course,
    team,
    isNewTeam = false,
    isImport = false
  ) =>
    createAction(types.CREATE_COURSE_REQUEST, {
      values,
      course,
      team,
      isImport,
      isNewTeam,
    }),
  createCourseSuccess: (data) =>
    createAction(types.CREATE_COURSE_SUCCESS, { data }),
  createCourseFailure: () => createAction(types.CREATE_COURSE_FAILURE),

  modifyCourseRequest: (
    values,
    course,
    team,
    isNewTeam = false,
    deleteByTable = false,
    isImport = false
  ) =>
    createAction(types.MODIFY_COURSE_REQUEST, {
      values,
      course,
      team,
      isImport,
      deleteByTable,
      isNewTeam,
    }),
  modifyCourseSuccess: (data) =>
    createAction(types.MODIFY_COURSE_SUCCESS, { data }),
  modifyCourseFailure: () => createAction(types.MODIFY_COURSE_FAILURE),

  modifyOnlyCourseRequest: (values, isNewTeam = false) =>
    createAction(types.MODIFY_ONLY_COURSE_REQUEST, { values, isNewTeam }),

  modifyTaskRequest: (values) =>
    createAction(types.MODIFY_TASK_REQUEST, { values }),
  modifyTaskSuccess: (data) =>
    createAction(types.MODIFY_TASK_SUCCESS, { data }),
  modifyTaskFailure: () => createAction(types.MODIFY_TASK_FAILURE),

  updateCoursesStateReduc: (courses) =>
    createAction(types.UPDATE_COURSES_STATE_REDUC, { courses }),

  deleteTasksSuccess: (id) => createAction(types.DELETE_ORDER_SUCCESS, { id }),

  deleteCourseSuccess: (course) =>
    createAction(types.DELETE_COURSE_SUCCESS, { course }),

  resetRequestSuccessReduc: () => createAction(types.RESET_TASKS_REDUC),

  resetCurrentCourse: () => createAction(types.RESET_CURRENT_COURSE_REDUC),
};
