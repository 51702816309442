import {authenticatedQuery} from "./middleware";

/**
 * GRAPHQL QUERIES
 */
const queries = {
  groups: `groups {
    id
    name
  }`,
};

export default {
  getGroups: () => authenticatedQuery(queries.groups),
};
