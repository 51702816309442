import { createReducer } from "reduxsauce";
import { types } from "./actions";

const initialState = {
  currentMerchant: null,
  list: [],
  searchMerchants: [],
  moreMerchants: [],
  loading: false,
  requestSuccess: 0,
  emailAvailable: true,
  currentTab: "1",
  statisticsMerchant: null,
};

const getStatisticsMerchantSuccess = (state = initialState, action) => {
  const { data } = action;
  return { ...state, statisticsMerchant: data };
};

const resetCurrentMerchant = (state = initialState) => {
  return { ...state, currentMerchant: {}, loading: false };
};

const failed = (state = initialState) => {
  return { ...state };
};

const createMerchantSuccess = (state = initialState, action) => {
  const { data } = action;
  const { list } = state;
  const values = list;
  values[data.id] = data;
  return { ...state, list: values, loading: false };
};

const updateMerchantSuccess = (state = initialState, action) => {
  const { data } = action;
  const { list, moreMerchants } = state;
  const values = list;
  const valuesMore = moreMerchants;
  values[data.id] = data;
  if (valuesMore[data.id]) {
    valuesMore[data.id] = data;
  }
  return { ...state, list: values, moreMerchants: valuesMore, loading: false };
};

const currentMerchantUpdateSuccess = (state = initialState, action) => {
  const { data } = action;
  return { ...state, currentMerchant: data, loading: false };
};

const deleteMerchantSuccess = (state = initialState, action) => {
  const { data } = action;
  const { list } = state;
  const values = list;
  delete values[data.id];
  return { ...state, list: values, loading: false };
};

const merchantsDeleteSuccess = (state = initialState, action) => {
  const { data } = action;
  const { list } = state;
  const values = list;
  data.forEach((val) => {
    delete values[val.id];
  });
  return { ...state, list: values, loading: false };
};

const getMerchantsSuccess = (state = initialState, action) => {
  const { data } = action;
  const values = [];
  data.forEach((v) => {
    values[v.id] = v;
  });
  return { ...state, list: values, loading: false };
};

const getMoreMerchantsSuccess = (state = initialState, action) => {
  const { data } = action;
  const values = [];
  data.forEach((v) => {
    values[v.id] = v;
  });
  return { ...state, moreMerchants: values, loading: false };
};

const merchantFailed = (state = initialState) => {
  return { ...state, loading: false };
};

const resetMerchantReduc = (state = initialState) => {
  return { ...state, loading: false };
};

const searchMerchantsSuccess = (state = initialState, action) => {
  const { data } = action;
  let tmp = [];
  data.forEach((v) => {
    tmp[v.id] = v;
  });
  return { ...state, searchMerchants: tmp };
};

const getMerchantSuccess = (state = initialState, action) => {
  const { data } = action;
  return { ...state, currentMerchant: data, loading: false };
};

const setEmailAvailable = (state = initialState, { emailAvailable }) => {
  return { ...state, emailAvailable };
};

const setCurrentTabReduc = (state = initialState, action) => {
  const { tabKey } = action;
  return { ...state, currentTab: tabKey, loading: false };
};

export default createReducer(initialState, {
  [types.MERCHANTS_CREATE_FAILURE]: merchantFailed,
  [types.MERCHANTS_CREATE_SUCCESS]: createMerchantSuccess,
  [types.MERCHANTS_UPDATE_FAILURE]: merchantFailed,
  [types.MERCHANTS_UPDATE_SUCCESS]: updateMerchantSuccess,
  [types.MERCHANT_DELETE_FAILURE]: failed,
  [types.MERCHANT_DELETE_SUCCESS]: deleteMerchantSuccess,
  [types.MERCHANTS_DELETE_SUCCESS]: merchantsDeleteSuccess,
  [types.CURRENT_MERCHANT_UPDATE_SUCCESS]: currentMerchantUpdateSuccess,
  [types.GET_MERCHANTS_FAILURE]: failed,
  [types.GET_MERCHANTS_SUCCESS]: getMerchantsSuccess,
  [types.GET_MERCHANT_FAILURE]: merchantFailed,
  [types.GET_MERCHANT_SUCCESS]: getMerchantSuccess,
  [types.CHECK_EMAIL_AVAILABLE_SUCCESS]: setEmailAvailable,
  [types.RESET_MERCHANT_REDUC]: resetMerchantReduc,
  [types.GET_MORE_MERCHANTS_SUCCESS]: getMoreMerchantsSuccess,
  [types.SEARCH_MERCHANTS_FAILURE]: failed,
  [types.SEARCH_MERCHANTS_SUCCESS]: searchMerchantsSuccess,
  [types.SET_CURRENT_TAB_REDUC]: setCurrentTabReduc,
  [types.RESET_CURRENT_MERCHANT]: resetCurrentMerchant,
  [types.GET_STATISTICS_MERCHANT_FAILURE]: failed,
  [types.GET_STATISTICS_MERCHANT_SUCCESS]: getStatisticsMerchantSuccess,
});
