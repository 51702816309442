import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  current_user: {},
  receiverList: [],
  users: {}
};

const getFailed = (state = initialState) => {
  return {...state};
};

const getCurrentUserSuccess = (state = initialState, action) => {
  const {user} = action;
  return {...state, current_user: user.user};
};

const getUsersSuccess = (state = initialState, action) => {
  const {data} = action;
  let tmp = []
  data.forEach((v) => {
    tmp[v.id] = v
  }) 
  return {...state, users: tmp};
};

const searchReceiverSuccess = (state = initialState, action) => {
  const {data} = action;
  let tmp = []
  data.forEach((v) => {
    tmp[v.code] = v
  })
  return {...state, receiverList: tmp};
};

const updateSearchReceiver = (state = initialState, action) => {
  const {data} = action;
  let tmp = state.receiverList
  if (tmp[data.code]) {
    tmp[data.code] = data
  }
  return {...state, receiverList: tmp};
};

// endregion

export default createReducer(initialState, {
  [types.GET_CURRENT_USER_FAILURE]: getFailed,
  [types.GET_USERS_FAILURE]: getFailed,
  [types.GET_CURRENT_USER_SUCCESS]: getCurrentUserSuccess,
  [types.GET_USERS_SUCCESS]: getUsersSuccess,
  [types.SEARCH_RECEIVER_FAILURE]: getFailed,
  [types.SEARCH_RECEIVER_SUCCESS]: searchReceiverSuccess,
  [types.UPDATE_SEARCH_RECEIVER]: updateSearchReceiver
});
