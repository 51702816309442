import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: {},
  current: null
};

const getInvoicesSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = []
  data.forEach(p => {
    tmp[p.id] = p
  })
  return {...state, list: tmp, loading: false};
};

const getInvoiceSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, current: data, loading: false};
};

const createInvoiceSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  tmp[data.id] = data
  return {...state, list: tmp, loading: false};
};

const modifyInvoiceSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  tmp[data.id] = data
  return {...state, list: tmp, loading: false};
};

const updateInvoiceSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  tmp[data.id] = data
  return {...state, list: tmp, loading: false};
};

const deleteInvoiceSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = state.list
  data.map((v) => {
    delete tmp[v.id]
  })
  return {...state, list: tmp, loading: false};
};

const updateCurrentInvoiceReduc = (state = initialState, action) => {
  const {data} = action
  return {...state, current: data, loading: false};
};

const generatePDFSuccess = (state = initialState, action) => {
  const {data, isCurrent} = action
  const tmp = state.list
  let newCurrent = state.current
  if (newCurrent) {
    newCurrent.doc_name = data.name
  }
  tmp[data.invoice_id] = {...tmp[data.invoice_id], doc_name: data.name}
  return {...state, list: tmp[data.invoice_id] ? tmp : state.list, current: isCurrent ? newCurrent : state.current, loading: false};
};

const getFailed = (state = initialState) => {
  return {...state};
};

const resetCurrentInvoice = (state = initialState) => {
  return {...state, current: null}
}

export default createReducer(initialState, {
  [types.GET_INVOICES_FAILURE]: getFailed,
  [types.GET_INVOICES_SUCCESS]: getInvoicesSuccess,
  [types.GET_INVOICE_FAILURE]: getFailed,
  [types.GET_INVOICE_SUCCESS]: getInvoiceSuccess,
  [types.CREATE_INVOICE_FAILURE]: getFailed,
  [types.CREATE_INVOICE_SUCCESS]: createInvoiceSuccess,
  [types.MODIFY_INVOICE_FAILURE]: getFailed,
  [types.MODIFY_INVOICE_SUCCESS]: modifyInvoiceSuccess,
  [types.UPDATE_INVOICE_FAILURE]: getFailed,
  [types.UPDATE_INVOICE_SUCCESS]: updateInvoiceSuccess,
  [types.UPDATE_CURRENT_INVOICE_REDUC]: updateCurrentInvoiceReduc,
  [types.DELETE_INVOICE_FAILURE]: getFailed,
  [types.DELETE_INVOICE_SUCCESS]: deleteInvoiceSuccess,
  [types.GENERATE_PDF_FAILURE]: getFailed,
  [types.GENERATE_PDF_SUCCESS]: generatePDFSuccess,
  [types.RESET_CURRENT_INVOICE]: resetCurrentInvoice,
});
