import { takeLatest, put, call, select } from "redux-saga/effects";
import {
  MerchantsService,
  PackageService,
  ProduceService,
} from "../../services";
import { LoaderActions } from "../loader";
import { types, default as MerchantsActions } from "./actions";
import { default as AuthActions } from "../auth/actions";
import { showSuccess, showError } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";
import { UserActions } from "../user";
import { getNameZoneByAddressRequest } from "../../utils/zone-helper";
import { createOrUpdateDefaultPackage } from "../../utils/function-helper";
import { get } from "lodash";

function* createMerchantRequest({ values, callback }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  if (values && (!values.email || values.email.length === 0)) {
    if ("password" in values) {
      delete values.password;
    }
    if ("email" in values) {
      delete values.email;
    }
  }

  const [error, result] = yield call(MerchantsService.merchantCreate, values);
  if (result) {
    showSuccess("merchant_create_success", strings);
    yield put(
      MerchantsActions.createMerchantSuccess({
        ...result.data,
        users: [{ ...result.data.user }],
      })
    );
    if (callback) {
      callback();
    }
    yield put(LoaderActions.loaded());
  } else {
    showError("merchant_create_error", strings, null, error);
    yield put(MerchantsActions.createMerchantFailure());
    yield put(LoaderActions.loaded());
  }
}

function* deleteMerchantRequest({ values }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(
    MerchantsService.merchantDelete,
    values.key
  );
  if (result) {
    showSuccess("merchant_delete_success", strings);
    yield put(
      MerchantsActions.deleteMerchantSuccess(
        result.update_merchants.returning[0]
      )
    );
  } else {
    showError("merchant_delete_error", strings, null, error);
    yield put(MerchantsActions.deleteMerchantFailure());
  }
  yield put(LoaderActions.loaded());
}

function* deleteMerchantsRequest({ data, callback }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(MerchantsService.merchantsDelete, data);

  if (result) {
    showSuccess("merchants_delete_success", strings);
    yield put(
      MerchantsActions.deleteMerchantsSuccess(result.update_merchants.returning)
    );
    if (callback) {
      callback();
    }
  } else {
    showError("merchants_delete_error", strings, null, error);
    yield put(MerchantsActions.deleteMerchantFailure());
  }
  yield put(LoaderActions.loaded());
}

function* getMerchantRequest({ id, callback }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);

  const [error, result] = yield call(MerchantsService.merchant, id);

  if (result) {
    let tmp = result.merchants[0];
    if (tmp?.users && 0 in tmp.users && tmp.users[0]?.address) {
      const res = yield getNameZoneByAddressRequest({
        value: tmp.users[0].address,
      });
      if (res) {
        tmp.zone = res.name;
      }
    }
    if (callback) {
      callback();
    }
    yield put(
      MerchantsActions.getMerchantSuccess({
        ...tmp,
        user: tmp.users[0],
        user_id: tmp.users[0].id,
      })
    );
  } else {
    showError("get_merchant_error", strings, null, error);
    yield put(MerchantsActions.getMerchantFailure());
  }
  yield put(LoaderActions.loaded());
}

function* updateMerchantRequest({ values, record, callback, isCurrent }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  let tmp = {
    ...values,
    address: values.address || null,
    phone_number: values.phone_number || "",
    name: values.name || "",
    team_id: values.team_id || "",
  };
  if (values.password !== record.password && values.password.length > 0) {
    let data = {
      user: record.users && 0 in record.users ? record.users[0] : null,
      password: values.password,
    };
    yield put(AuthActions.updatePassword(data));
  }

  const [error, result] = yield call(MerchantsService.merchantUpdate, {
    ...record,
    ...tmp,
  });
  if (result) {
    showSuccess("merchant_update_success", strings);
    const data = result.update_merchants.returning[0];
    yield put(MerchantsActions.updateMerchantSuccess(data));
    yield put(
      UserActions.updateSearchReceiver({
        ...(0 in data.users ? data.users[0] : {}),
        phone: 0 in data.users ? data.users[0]?.phone_number ?? "" : "",
        ...data,
        code: `m-${data.id}`,
        user_id: 0 in data.users ? data.users[0]?.id ?? null : null,
      })
    );
    if (callback) {
      callback();
    }
    if (isCurrent) {
      yield put(MerchantsActions.updateCurrentMerchantSuccess(data));
    }
    yield put(LoaderActions.loaded());
  } else {
    showError("merchant_update_error", strings, null, error);
    yield put(MerchantsActions.updateMerchantFailure());
    yield put(LoaderActions.loaded());
  }
}

function* getMerchantsRequest() {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(MerchantsService.merchants);
  if (result) {
    yield put(MerchantsActions.getMerchantsSuccess(result.merchants));
    yield put(LoaderActions.loaded());
  } else {
    if (!error.loginError) {
      showError("get_merchants_error", strings, null, error);
    }
    yield put(MerchantsActions.getMerchantsFailure());
    yield put(LoaderActions.loaded());
  }
}

function* getMoreMerchantsRequest({ ids }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  if (ids.length === 0) {
    yield put(MerchantsActions.getMoreMerchantsSuccess([]));
    yield put(LoaderActions.loaded());
    return;
  }
  const [error, result] = yield call(MerchantsService.moreMerchants, ids);
  if (result) {
    yield put(LoaderActions.loaded());
    yield put(MerchantsActions.getMoreMerchantsSuccess(result.merchants));
  } else {
    yield put(LoaderActions.loaded());
    showError("get_merchants_error", strings, null, error);
    yield put(MerchantsActions.getMerchantsFailure());
  }
}

function* searchMerchantsRequest({ value }) {
  console.log(value)
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  if (!value || value.length === 0) {
    yield put(MerchantsActions.searchMerchantsSuccess([]));
    return;
  }
  const [error, result] = yield call(MerchantsService.searchMerchants, value);
  if (result) {
    yield put(MerchantsActions.searchMerchantsSuccess(result.view_users));
  } else {
    if (!error.loginError) {
      showError("search_user_error", strings, null, error);
    }
    yield put(MerchantsActions.searchMerchantsFailure());
  }
}

function* checkEmailAvailableRequest({ email }) {
  const [_, response = { merchants: [] }] = yield call(
    MerchantsService.getMerchantByEmail,
    email
  );
  yield put(
    MerchantsActions.checkEmailAvailableSuccess(
      response?.merchants?.length === 0
    )
  );
}

function* getStatisticsMerchantRequest({ id, date }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const tmp = {
    id: id,
    gte: date[0],
    lte: date[1],
  };
  const [error, result] = yield call(MerchantsService.statisticsMerchant, tmp);
  if (result) {
    yield put(MerchantsActions.getStatisticsMerchantSuccess(result.result));
  } else {
    if (!error.loginError) {
      showError("get_statistics_merchant_error", strings, null, error);
    }
    yield put(MerchantsActions.getStatisticsMerchantFailure());
  }
}

function* updateMerchantsRequest({
  values,
  isNewMerchant,
  isCurrentMerchant,
  callback,
}) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  if (values.password && values.password.length > 0) {
    let data = {
      user: values.users && 0 in values.users ? values.users[0] : null,
      password: values.password,
    };
    yield put(AuthActions.updatePassword(data));
  }

  const [error, result] = yield call(MerchantsService.merchantUpdate, values);
  if (result) {
    if (isNewMerchant) {
      let mailsToSend = [];
      if (values.email && values.email.length > 0) {
        mailsToSend.push({
          type: "create-merchant-to-merchant-mail",
          toSend: {
            subject: "Nouveau compte commerçant",
            name: values.name,
            email: values.email,
            link: "https://shop.deki.pro/",
          },
        });
        if (process.env.REACT_APP_EMAIL_ADMIN) {
          mailsToSend.push({
            type: "create-merchant-to-admin-mail",
            toSend: {
              subject: "Nouveau compte commerçant",
              email_merchant: values.email,
              email: process.env.REACT_APP_EMAIL_ADMIN,
            },
          });
        }
        yield call(ProduceService.sendEmails, {emails: mailsToSend});
      }
    }
    let tmp = result.update_merchants.returning[0];
    if (values?.package_type_receivers_admin) {
      const [errorPackageTypes, resultPackageTypes] = yield call(
        PackageService.updatePackageTypesAdmin,
        {
          packages: values.package_type_receivers_admin.map((v) => ({
            package_type_id: v,
            receiver_merchant_id: tmp.id,
          })),
          merchantId: tmp.id,
        }
      );
      tmp.package_type_receivers = get(
        resultPackageTypes,
        ["insert_package_type_receiver", "returning"],
        []
      );
    }
    if (values?.defaultPackage) {
      const resPackage = yield createOrUpdateDefaultPackage(values, tmp);
      if (!resPackage) return;
      tmp.default_packages = resPackage;
    }
    if (tmp.users[0] && tmp.users[0].address && tmp.users[0].address.data) {
      const res = yield getNameZoneByAddressRequest({
        value: tmp.users[0].address,
      });
      if (res) {
        tmp.zone = res.name;
      }
    }
    if (isCurrentMerchant) {
      yield put(MerchantsActions.updateCurrentMerchantSuccess(tmp));
    } else {
      yield put(MerchantsActions.merchantsUpdateSuccess(tmp));
    }
    if (callback) {
      callback(`m-${tmp.id}`, tmp);
    }
    yield put(
      UserActions.updateSearchReceiver({
        ...(0 in tmp.users ? tmp.users[0] : {}),
        phone: 0 in tmp.users ? tmp.users[0]?.phone_number ?? "" : "",
        ...tmp,
        code: `m-${tmp.id}`,
        user_id: 0 in tmp.users ? tmp.users[0]?.id ?? null : null,
      })
    );
    showSuccess("merchant_update_success", strings);
    yield put(LoaderActions.loaded());
  } else if (error) {
    showError("merchant_update_error", strings, null, error);
    yield put(MerchantsActions.merchantsUpdateFailure());
    yield put(LoaderActions.loaded());
  }
}

export default [
  takeLatest(
    types.GET_STATISTICS_MERCHANT_REQUEST,
    getStatisticsMerchantRequest
  ),
  takeLatest(types.MERCHANT_UPDATE_REQUEST, updateMerchantsRequest),
  takeLatest(types.MERCHANTS_CREATE_REQUEST, createMerchantRequest),
  takeLatest(types.MERCHANT_DELETE_REQUEST, deleteMerchantRequest),
  takeLatest(types.MERCHANTS_DELETE_REQUEST, deleteMerchantsRequest),
  takeLatest(types.MERCHANTS_UPDATE_REQUEST, updateMerchantRequest),
  takeLatest(types.GET_MERCHANTS_REQUEST, getMerchantsRequest),
  takeLatest(types.GET_MORE_MERCHANTS_REQUEST, getMoreMerchantsRequest),
  takeLatest(types.SEARCH_MERCHANTS_REQUEST, searchMerchantsRequest),
  takeLatest(types.CHECK_EMAIL_AVAILABLE_REQUEST, checkEmailAvailableRequest),
  takeLatest(types.GET_MERCHANTS_REQUEST, getMerchantsRequest),
  takeLatest(types.GET_MERCHANT_REQUEST, getMerchantRequest),
];
