import { createSelector } from "reselect";
import moment from "moment-timezone";

export const selectMerchants = createSelector(
  (state) => Object.values(state.merchants.list),
  (merchants) => {
    return merchants
      .map((v) => {
        return {
          key: v.id,
          user_id: 0 in v.users ? v.users[0]?.id ?? "" : "",
          code: `m-${v.id}`,
          ...v,
          ...(0 in v.users ? v.users[0] : {}),
          phone: 0 in v.users ? v.users[0]?.phone_number ?? "" : "",
          id: v.id,
        };
      })
      .sort(
        (a, b) =>
          moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
      );
  }
);

export const selectCurrentMerchant = createSelector(
  (state) => state.merchants.currentMerchant,
  (merchant) => {
    if (merchant && merchant.id) {
      return {
        key: merchant.id,
        id: merchant.id,
        package_type_receivers_admin: merchant?.package_type_receivers
          ?.filter((p) => p?.package_type?.is_admin)
          .map((v) => v.package_type_id),
        ...merchant,
        user_id: 0 in merchant.users ? merchant.users[0]?.id ?? "" : "",
        user: 0 in merchant.users ? merchant.users[0] : null,
        code: `m-${merchant.id}`,
        phone: 0 in merchant.users ? merchant.users[0]?.phone_number ?? "" : "",
      };
    } else {
      return merchant;
    }
  }
);

export const selectStatisticsMerchant = createSelector(
  (state) => state.merchants.statisticsMerchant,
  (statistics) => {
    return statistics;
  }
);

export const selectSearchMerchants = createSelector(
  (state) => Object.values(state.merchants.searchMerchants),
  (merchants) => {
    return merchants
      .map((v) => {
        return {
          key: v.merchant_id,
          user_id: v.id,
          code: `m-${v.merchant_id}`,
          ...v,
          phone: v.phone_number || null,
          id: v.merchant_id,
          users: [{ id: v.id }],
        };
      })
      .sort(
        (a, b) =>
          moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
      );
  }
);

export const selectMoreMerchants = createSelector(
  (state) => Object.values(state.merchants.moreMerchants),
  (merchants) => {
    return merchants
      .map((v) => {
        return {
          key: v.id,
          user_id: 0 in v.users ? v.users[0]?.id ?? "" : "",
          code: `m-${v.id}`,
          ...v,
          ...(0 in v.users ? v.users[0] : {}),
          phone: 0 in v.users ? v.users[0]?.phone_number ?? "" : "",
          id: v.id,
        };
      })
      .sort(
        (a, b) =>
          moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
      );
  }
);
