import { takeLatest, put, call, select } from 'redux-saga/effects';
import { TeamsService } from '../../services';
import { LoaderActions } from '../loader';
import { types, default as TeamsAction } from './actions';
import { showSuccess, showError} from  '../../utils/notifications-helper'
import { getTranslate } from 'react-localize-redux';

function* createTeamRequest({values}) {
  yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)

  const [error, result] = yield call(TeamsService.createTeams, values)
  if (result) {
    showSuccess("teams_create_success", strings)
    yield put(TeamsAction.createTeamSuccess(result.insert_teams.returning[0]))
  } else {
    showError("teams_create_error", strings, null, error)
    yield put(TeamsAction.createTeamFailure())
  }
  yield put(LoaderActions.loaded())
}

function* deleteTeamRequest({data}) {
  yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  const [error, result] = yield call(TeamsService.deleteTeams, data.id)
  if (result) {
    showSuccess("teams_delete_success", strings)
    yield put(TeamsAction.deleteTeamSuccess(result.delete_teams.returning[0]))
    yield put(LoaderActions.loaded())
  } else {
    showError("teams_delete_error", strings, null, error)
    yield put(TeamsAction.deleteTeamFailure())
    yield put(LoaderActions.loaded())
  }
}

function* updateTeamRequest({data, id, record}) {
  yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  let values = {...record, id: id, ...data}
  const [error, result] = yield call(TeamsService.updateTeams, values)

  if (result) {
    showSuccess("teams_update_success", strings)
    yield put(TeamsAction.updateTeamSuccess(result.update_teams.returning[0]))
    yield put(LoaderActions.loaded())
  } else {
    showError("teams_update_error", strings, null, error)
    yield put(TeamsAction.updateTeamFailure())
    yield put(LoaderActions.loaded())
  }
}

function* getTeamsRequest() {
  //yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  const [error, result] = yield call(TeamsService.teams)
  if (result) {
    //yield put(LoaderActions.loaded())
    yield put(TeamsAction.getTeamsSuccess(result.teams))
  } else {
    //yield put(LoaderActions.loaded())
    if (!error.loginError) {
      showError("get_teams_error", strings, null, error)
    }
    yield put(TeamsAction.getTeamsFailure())
  }
}

export default [
  takeLatest(types.TEAM_CREATE_REQUEST, createTeamRequest),
  takeLatest(types.TEAM_DELETE_REQUEST, deleteTeamRequest),
  takeLatest(types.TEAM_UPDATE_REQUEST, updateTeamRequest),
  takeLatest(types.GET_TEAMS_REQUEST, getTeamsRequest)
];
