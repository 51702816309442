import { createSelector } from 'reselect'
import moment from "moment-timezone";

export const selectRecipients = createSelector(
    (state) => Object.values(state.recipients.list),
    (recipients) => {
        return recipients.map((v) => ({key: v.id, code: `r-${v.id}`, ...v, phone: v.phone_number}))
            .sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
    }
)

export const selectMoreRecipients = createSelector(
    (state) => Object.values(state.recipients.moreRecipients),
    (recipients) => {
        return recipients.map((v) => ({key: v.id, code: `r-${v.id}`, ...v, phone: v.phone_number}))
            .sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
    }
)
