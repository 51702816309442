import { createAction } from '../../utils';

// Types
export const types = {
    GET_COURSIERS_REQUEST: 'GET_COURSIERS_REQUEST',
    GET_COURSIERS_SUCCESS: 'GET_COURSIERS_SUCCESS',
    GET_COURSIERS_FAILURE: 'GET_COURSIERS_FAILURE',
    
};

// Actions
export default {
    getCoursiersRequest: () =>
      createAction(types.GET_COURSIERS_REQUEST),
    getCoursiersSuccess: (data) =>
      createAction(types.GET_COURSIERS_SUCCESS, {data}),
    getCoursiersFailure: () =>
      createAction(types.GET_COURSIERS_FAILURE),

};
