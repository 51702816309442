import { createAction } from '../../utils';

// Types
export const types = {
    GET_CITIES_REQUEST: 'GET_CITIES_REQUEST',
    GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
    GET_CITIES_FAILURE: 'GET_CITIES_FAILURE',
    
    UPDATE_CITY_REQUEST: 'UPDATE_CITY_REQUEST',
    UPDATE_CITY_SUCCESS: 'UPDATE_CITY_SUCCESS',
    UPDATE_CITY_FAILURE: 'UPDATE_CITY_FAILURE',
};

// Actions
export default {
    getCitiesRequest: () =>
      createAction(types.GET_CITIES_REQUEST),
    getCitiesSuccess: (data) =>
      createAction(types.GET_CITIES_SUCCESS, {data}),
    getCitiesFailure: () =>
      createAction(types.GET_CITIES_FAILURE),

    updateAutoAssignCityRequest: (cityId, autoAssign) =>
      createAction(types.UPDATE_CITY_REQUEST, {cityId, autoAssign}),
    updateAutoAssignCitySuccess: (data) =>
      createAction(types.UPDATE_CITY_SUCCESS, {data}),
    updateAutoAssignCityFailure: () =>
      createAction(types.UPDATE_CITY_FAILURE),
};
