import { createAction } from '../../utils';

// Types
export const types = {
  LOGIN_REFRESH: 'LOGIN_REFRESH',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',

  SUBSCRIBE_REQUEST: 'SUBSCRIBE_REQUEST',
  SUBSCRIBE_FAILURE: 'SUBSCRIBE_FAILURE',
  SUBSCRIBE_SUCCESS: 'SUBSCRIBE_SUCCESS',

  LOGOUT: 'LOGOUT',

  UPDATE_PASSWORD: 'UPDATE_PASSWORD',

  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GRAPHQL_RETRY: "GRAPHQL_RETRY"
};

// Actions
export default {
  graphqlRetry: (services) => createAction(types.GRAPHQL_RETRY, {services}),
  getUserSuccess: ({role, team_id}) =>
      createAction(types.GET_USER_SUCCESS, {role, team_id}),

  loginRefresh: () =>
      createAction(types.LOGIN_REFRESH),

  loginRequest: (username, password) =>
      createAction(types.LOGIN_REQUEST, { username, password }),

  loginSuccess: ({token_type, token, refresh_token, authorization, username}) =>
      createAction(types.LOGIN_SUCCESS, { token_type, token, refresh_token, authorization, username}),

  loginFailure: () =>
      createAction(types.LOGIN_FAILURE),

  subscribeRequest: (values) =>
      createAction(types.SUBSCRIBE_REQUEST, { values }),

  subscribeSuccess: () =>
      createAction(types.SUBSCRIBE_SUCCESS),

  subscribeFailure: () =>
      createAction(types.SUBSCRIBE_FAILURE),

  logout: () =>
      createAction(types.LOGOUT),

    updatePassword: ({user, password}) =>
        createAction(types.UPDATE_PASSWORD, {user, password})
};
