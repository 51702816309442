import { createAction } from "../../utils";

// Types
export const types = {
  GET_MERCHANTS_REQUEST: "GET_MERCHANTS_REQUEST",
  GET_MERCHANTS_SUCCESS: "GET_MERCHANTS_SUCCESS",
  GET_MERCHANTS_FAILURE: "GET_MERCHANTS_FAILURE",

  GET_MERCHANT_REQUEST: "GET_MERCHANT_REQUEST",
  GET_MERCHANT_SUCCESS: "GET_MERCHANT_SUCCESS",
  GET_MERCHANT_FAILURE: "GET_MERCHANT_FAILURE",

  MERCHANTS_CREATE_REQUEST: "MERCHANTS_CREATE_REQUEST",
  MERCHANTS_CREATE_SUCCESS: "MERCHANTS_CREATE_SUCCESS",
  MERCHANTS_CREATE_FAILURE: "MERCHANTS_CREATE_FAILURE",

  MERCHANTS_UPDATE_REQUEST: "MERCHANTS_UPDATE_REQUEST",
  MERCHANTS_UPDATE_SUCCESS: "MERCHANTS_UPDATE_SUCCESS",
  MERCHANTS_UPDATE_FAILURE: "MERCHANTS_UPDATE_FAILURE",

  CURRENT_MERCHANT_UPDATE_SUCCESS: "CURRENT_MERCHANT_UPDATE_SUCCESS",

  MERCHANT_DELETE_REQUEST: "MERCHANT_DELETE_REQUEST",
  MERCHANT_DELETE_SUCCESS: "MERCHANT_DELETE_SUCCESS",
  MERCHANT_DELETE_FAILURE: "MERCHANT_DELETE_FAILURE",

  CHECK_EMAIL_AVAILABLE_REQUEST: "CHECK_EMAIL_AVAILABLE_REQUEST",
  CHECK_EMAIL_AVAILABLE_SUCCESS: "CHECK_EMAIL_AVAILABLE_SUCCESS",

  RESET_MERCHANT_REDUC: "RESET_MERCHANT_REDUC",

  GET_MORE_MERCHANTS_REQUEST: "GET_MORE_MERCHANTS_REQUEST",
  GET_MORE_MERCHANTS_SUCCESS: "GET_MORE_MERCHANTS_SUCCESS",

  SEARCH_MERCHANTS_REQUEST: "SEARCH_MERCHANTS_REQUEST",
  SEARCH_MERCHANTS_SUCCESS: "SEARCH_MERCHANTS_SUCCESS",
  SEARCH_MERCHANTS_FAILURE: "SEARCH_MERCHANTS_FAILURE",

  MERCHANTS_DELETE_REQUEST: "MERCHANTS_DELETE_REQUEST",
  MERCHANTS_DELETE_SUCCESS: "MERCHANTS_DELETE_SUCCESS",

  GET_STATISTICS_MERCHANT_REQUEST: "GET_STATISTICS_MERCHANT_REQUEST",
  GET_STATISTICS_MERCHANT_SUCCESS: "GET_STATISTICS_MERCHANT_SUCCESS",
  GET_STATISTICS_MERCHANT_FAILURE: "GET_STATISTICS_MERCHANT_FAILURE",

  SET_CURRENT_TAB_REDUC: "SET_CURRENT_TAB_REDUC",
  RESET_CURRENT_MERCHANT: "RESET_CURRENT_MERCHANT",

  MERCHANT_UPDATE_REQUEST: "MERCHANT_UPDATE_REQUEST",
  MERCHANT_UPDATE_SUCCESS: "MERCHANT_UPDATE_SUCCESS",
  MERCHANT_UPDATE_FAILURE: "MERCHANT_UPDATE_FAILURE",
};

// Actions
export default {
  resetRequestSuccessReduc: () => createAction(types.RESET_MERCHANT_REDUC),
  resetCurrentMerchant: () => createAction(types.RESET_CURRENT_MERCHANT),

  setCurrentTabReduc: (tabKey) =>
    createAction(types.SET_CURRENT_TAB_REDUC, { tabKey }),

  merchantsUpdateRequest: (
    values,
    isNewMerchant,
    isCurrentMerchant = false,
    callback = null
  ) =>
    createAction(types.MERCHANT_UPDATE_REQUEST, {
      values,
      isNewMerchant,
      isCurrentMerchant,
      callback,
    }),
  merchantsUpdateSuccess: (data) =>
    createAction(types.MERCHANT_UPDATE_SUCCESS, { data }),
  merchantsUpdateFailure: () => createAction(types.MERCHANT_UPDATE_FAILURE),

  getMoreMerchantsRequest: (ids) =>
    createAction(types.GET_MORE_MERCHANTS_REQUEST, { ids }),
  getMoreMerchantsSuccess: (data) =>
    createAction(types.GET_MORE_MERCHANTS_SUCCESS, { data }),
  getMerchantsRequest: () => createAction(types.GET_MERCHANTS_REQUEST),
  getMerchantsSuccess: (data) =>
    createAction(types.GET_MERCHANTS_SUCCESS, { data }),
  getMerchantsFailure: () => createAction(types.GET_MERCHANTS_FAILURE),

  getMerchantRequest: (id) => createAction(types.GET_MERCHANT_REQUEST, { id }),
  getMerchantSuccess: (data) =>
    createAction(types.GET_MERCHANT_SUCCESS, { data }),
  getMerchantFailure: () => createAction(types.GET_MERCHANT_FAILURE),

  createMerchantRequest: (values, callback = null) =>
    createAction(types.MERCHANTS_CREATE_REQUEST, { values, callback }),
  createMerchantSuccess: (data) =>
    createAction(types.MERCHANTS_CREATE_SUCCESS, { data }),
  createMerchantFailure: () => createAction(types.MERCHANTS_CREATE_FAILURE),

  updateMerchantRequest: (values, record, callback = null, isCurrent = false) =>
    createAction(types.MERCHANTS_UPDATE_REQUEST, {
      values,
      record,
      callback,
      isCurrent,
    }),
  updateMerchantSuccess: (data) =>
    createAction(types.MERCHANTS_UPDATE_SUCCESS, { data }),
  updateCurrentMerchantSuccess: (data) =>
    createAction(types.CURRENT_MERCHANT_UPDATE_SUCCESS, { data }),
  updateMerchantFailure: () => createAction(types.MERCHANTS_UPDATE_FAILURE),

  deleteMerchantsRequest: (data, callback = null) =>
    createAction(types.MERCHANTS_DELETE_REQUEST, { data, callback }),
  deleteMerchantsSuccess: (data) =>
    createAction(types.MERCHANTS_DELETE_SUCCESS, { data }),
  deleteMerchantRequest: (values) =>
    createAction(types.MERCHANT_DELETE_REQUEST, { values }),
  deleteMerchantSuccess: (data) =>
    createAction(types.MERCHANT_DELETE_SUCCESS, { data }),
  deleteMerchantFailure: () => createAction(types.MERCHANT_DELETE_FAILURE),

  searchMerchantsRequest: (value) =>
    createAction(types.SEARCH_MERCHANTS_REQUEST, { value }),
  searchMerchantsSuccess: (data) =>
    createAction(types.SEARCH_MERCHANTS_SUCCESS, { data }),
  searchMerchantsFailure: () => createAction(types.SEARCH_MERCHANTS_FAILURE),

  checkEmailAvailableRequest: (email) =>
    createAction(types.CHECK_EMAIL_AVAILABLE_REQUEST, { email }),
  checkEmailAvailableSuccess: (emailAvailable) =>
    createAction(types.CHECK_EMAIL_AVAILABLE_SUCCESS, { emailAvailable }),

  getStatisticsMerchantRequest: ({ id, date }) =>
    createAction(types.GET_STATISTICS_MERCHANT_REQUEST, { id, date }),
  getStatisticsMerchantSuccess: (data) =>
    createAction(types.GET_STATISTICS_MERCHANT_SUCCESS, { data }),
  getStatisticsMerchantFailure: () =>
    createAction(types.GET_STATISTICS_MERCHANT_FAILURE),
};
