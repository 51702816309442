import {gql} from "apollo-boost";
import {authenticatedMutation, authenticatedQuery} from "./middleware";

const RETURNING_PRICING = `
  id
  team_id
  isAdmin
  base
  overcost
  discount
`

const RETURNING_ZONE_PRICING = `
  id
  zone_id
  pricing
  area_team {
    name
    team_id
  }
`

/**
 * GRAPHQL QUERIES
 */
const queries = {
  zonePricing: ({isAdmin}) => `zone_pricing(where: {area_team: {is_admin: {_eq: ${isAdmin}}}}) {
    ${RETURNING_ZONE_PRICING}
  }`,
  pricing: `pricing {
    ${RETURNING_PRICING}
  }`,
  pricingAdmin: `pricing(where:  {isAdmin: {_eq: true}}) {
    ${RETURNING_PRICING}
  }`,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  updateZonePricing: gql`mutation update_zone_pricing(
    $id: Int!
    $pricing: zone_pricing_set_input!
  ) {
    update_zone_pricing(where: {id: {_eq: $id}},
    _set: $pricing
    )
      {
        returning {
          ${RETURNING_ZONE_PRICING}
        }
      }
    }`,
  updatePricing: gql`mutation update_pricing(
    $id: Int!
    $pricing: pricing_set_input!
  ) {
    update_pricing(where: {id: {_eq: $id}},
    _set: $pricing
    )
      {
        returning {
          ${RETURNING_PRICING}
        }
      }
    }`,
};

export default {
  getPricingAdmin: () => authenticatedQuery(queries.pricingAdmin),
  getZonePricing: ({isAdmin}) => authenticatedQuery(queries.zonePricing({isAdmin})),
  updatePricing: (pricing) => authenticatedMutation(mutations.updatePricing, {id: pricing.id, pricing}),
  updateZonePricing: (pricing, id) => authenticatedMutation(mutations.updateZonePricing, {id: id, pricing: pricing}),
};
