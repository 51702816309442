import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { history } from "./history";
import store from "./store";
import { Suspense } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Spin } from "antd";
const ErrorBoundary = React.lazy(() => import("../ui/screens/error/index"));
const NotFound = React.lazy(() => import("../ui/screens/notFound/index"));
const Teams = React.lazy(() => import("../ui/screens/teams/index"));
const Authentication = React.lazy(() =>
  import("../ui/screens/authentication/index")
);
const PackageType = React.lazy(() => import("../ui/screens/packageType/index"));
const Home = React.lazy(() => import("../ui/screens/home/index"));
const Dispatcher = React.lazy(() => import("../ui/screens/dispatcher/index"));
const Merchants = React.lazy(() => import("../ui/screens/merchants/index"));
const Course = React.lazy(() => import("../ui/screens/course/index"));
const Hubs = React.lazy(() => import("../ui/screens/hubs/index"));
const Hub = React.lazy(() => import("../ui/screens/hub/index"));
const Merchant = React.lazy(() => import("../ui/screens/merchant/index"));
const Recipients = React.lazy(() => import("../ui/screens/recipients/index"));
const Invoice = React.lazy(() => import("../ui/screens/invoice/index"));
const InvoiceSelect = React.lazy(() =>
  import("../ui/screens/invoiceSelect/index")
);
const InvoiceDetail = React.lazy(() =>
  import("../ui/screens/invoiceDetail/index")
);
const InvoiceMail = React.lazy(() => import("../ui/screens/invoiceMail/index"));
const PricingGrid = React.lazy(() => import("../ui/screens/pricingGrid/index"));
const Cities = React.lazy(() => import("../ui/screens/cities/index"));
const Dashboard = React.lazy(() => import("../ui/screens/dashboard/index"));

// HOC that redirects if an unauthenticated user try to reach a protected route
const AuthRoute = ({ component: Component, ...rest }) => {
  // fixme: Add your own validation logic for auth pages (ex. a valid token)
  const { token } = store.getState().auth;
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <ErrorBoundary>
            <Component {...props} {...rest} />
          </ErrorBoundary>
        ) : (
          <Redirect to={{ pathname: "/login", state: { ...rest.location } }} />
        )
      }
    />
  );
};

// Main router which handle user navigation
const Router = () => {
  return (
    <ConnectedRouter history={history}>
      <Suspense
        fallback={
          <div
            style={{
              height: window.innerHeight,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spin style={{ justifySelf: "center", alignSelf: "center" }} />
          </div>
        }
      >
        <Switch>
          <Route exact path={"/login"} component={Authentication} />
          <AuthRoute exact path={"/"} component={Home} />
          <AuthRoute exact path={"/teams"} component={Teams} />
          <AuthRoute exact path={"/course"} component={Course} />
          <AuthRoute exact path={"/course/:id"} component={Course} />
          <AuthRoute exact path={"/dispatcher"} component={Dispatcher} />
          <AuthRoute exact path={"/merchants"} component={Merchants} />
          <AuthRoute exact path={"/recipients"} component={Recipients} />
          <AuthRoute exact path={"/hubs"} component={Hubs} />
          <AuthRoute exact path={"/hub/:id"} component={Hub} />
          <AuthRoute exact path={"/invoice"} component={Invoice} />
          <AuthRoute exact path={"/dashboard"} component={Dashboard} />
          <AuthRoute exact path={"/pricing_grid"} component={PricingGrid} />
          <AuthRoute exact path={"/packageType"} component={PackageType} />
          <AuthRoute exact path={"/merchant/:id"} component={Merchant} />
          <AuthRoute
            exact
            path={"/invoice/select/:id"}
            component={InvoiceSelect}
          />
          <AuthRoute
            exact
            path={"/invoice/info/:id"}
            component={InvoiceDetail}
          />
          <AuthRoute exact path={"/invoice/mail/:id"} component={InvoiceMail} />
          <AuthRoute exact path={"/cities"} component={Cities} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  );
};

export default Router;
