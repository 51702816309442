import {methods} from '../utils';
import {authenticatedMutation, authenticatedQuery, authenticatedService} from './middleware';

const { POST } = methods;

const queries = {
  dispatchers: `dispatchers {
    id
    user_id
    user {
      id
      name
      email
      address
      password
      phone_number
      team_id
      updated_at
      created_at
    }
  }`,
  dispatchersByTeamID: (team_id) => `dispatchers(where: {user: {team_id: {_eq: ${team_id}}}}) {
    user {
      email
    }
  }`
}

const mutations = {
  dispatchersUpdate: (values, record) => `mutation {
    update_users(where: {id: {_eq: "${record.user_id}"}},
     _set: {
      name: ${values.name ? `"${values.name}"`: `null`}, 
      email: ${values.email ? `"${values.email}"`: `null`}, 
      address: ${values.address ? JSON.stringify(values.address).replace(/"(\w+)"\s*:/g, '$1:') : 'null'}, 
      phone_number: ${values.phone_number ? `"${values.phone_number}"`: `null`}, 
      team_id: ${values.team_id ? `${values.team_id}`: `null`}, 
    }) 
      {
        returning {
          id
          name
          address
          phone_number
          team_id
          email
          password
          dispatchers {
            id
          }
          updated_at
          created_at
        }
      }
}`,
  dispatchersDelete: dispatcher => `mutation {
      delete_dispatchers(where: {id: {_eq: ${dispatcher.id}}}) {
        returning {
          id
        }
      }
      delete_users(where: {id: {_eq: ${dispatcher.user_id}}}) {
        returning {
          id
        }
      }
    }`
}

const routes = {
  dispatchersCreate: process.env.REACT_APP_BASE_API_URL + '/users/dispatcher'
}

export default {
  dispatchers: () => authenticatedQuery(queries.dispatchers),
  dispatchersByTeamID: (team_id) => authenticatedQuery(queries.dispatchersByTeamID(team_id)),
  dispatchersCreate: (body) => authenticatedService(POST, routes.dispatchersCreate, body,{} ,{}),
  dispatchersUpdate: ({values, record}) => { return authenticatedMutation(mutations.dispatchersUpdate(values, record)) },
  dispatchersDelete: dispatcher => { return authenticatedMutation(mutations.dispatchersDelete(dispatcher)) },
}