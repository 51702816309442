import { createReducer } from "reduxsauce";
import { types } from "./actions";

const initialState = {
  list: [],
};

const failed = (state = initialState) => {
  return { ...state };
};

const getGroupsSuccess = (state = initialState, action) => {
  const { data } = action;
  const values = [];
  data.forEach((v) => {
    values[v.id] = v;
  });
  return { ...state, list: values, loading: false };
};

export default createReducer(initialState, {
  [types.GET_GROUPS_FAILURE]: failed,
  [types.GET_GROUPS_SUCCESS]: getGroupsSuccess,
});
