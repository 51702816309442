import moment from "moment-timezone"
import {gql} from "apollo-boost";
import {pick} from "lodash"
import {authenticatedMutation, authenticatedQuery} from "./middleware";

/**
 * GRAPHQL QUERIES
 */
const queries = {
  recipient: (id) => `recipients(where: {id: {_eq: ${id}}}) {
    id
    created_at
    created_by
    updated_at
    company
    email
    notes
    name
    phone_number
    address
    team_id
    type
    merchant_id
    merchant {
      company
      users(where: {role: {_eq: "merchant_manager"}}) {
        id
        name
        phone_number
        address
        team_id
        created_at
        updated_at
        role
      }
    }
  }`,
  moreRecipients: (ids) => `recipients(where: {id: {_in: [${ids.join(',')}]}}) {
    id
    created_at
    created_by
    updated_at
    company
    name
    email
    phone_number
    address
    team_id
    notes
    type
    merchant_id
    merchant {
      company
      users(where: {role: {_eq: "merchant_manager"}}) {
        id
        name
        phone_number
        address
        team_id
        created_at
        updated_at
        role
      }
    }
  }`,
  recipients: (created_by) => `recipients(where: {created_by: {_eq: ${created_by}}}) {
      id
      created_at
      created_by
      updated_at
      company
      name
      email
      phone_number
      address
      team_id
      notes
      type
      merchant_id
      merchant {
        company
        users(where: {role: {_eq: "merchant_manager"}}) {
          id
          name
          phone_number
          address
          team_id
          created_at
          updated_at
          role
        }
      }
    }`,
  recipientsByMerchantID: (merchant_id) => `recipients(where: {merchant_id: {_eq: ${merchant_id}}}) {
      id
      created_at
      created_by
      updated_at
      company
      notes
      email
      name
      phone_number
      address
      team_id
      type
      merchant_id
      merchant {
        company
        users(where: {role: {_eq: "merchant_manager"}}) {
          id
          name
          phone_number
          address
          team_id
          created_at
          updated_at
          role
        }
      }
    }`,
  recipientsByCreatedBy: (created_by) => `recipients(where: {created_by: {_eq: ${created_by}}}) {
      id
      created_at
      created_by
      updated_at
      company
      notes
      name
      phone_number
      address
      email
      team_id
      type
      merchant_id
      merchant {
        company
        users(where: {role: {_eq: "merchant_manager"}}) {
          id
          name
          phone_number
          address
          team_id
          created_at
          updated_at
          role
        }
      }
    }`
}
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  recipientCreate: gql`mutation create_recipient(
      $recipient: recipients_insert_input!
    ) {
    insert_recipients_one(object: $recipient)
    {
      id
      created_at
      created_by
      updated_at
      company
      email
      notes
      type
      name
      phone_number
      address
      team_id
      merchant_id
      merchant {
        company
        users(where: {role: {_eq: "merchant_manager"}}) {
          id
          name
          phone_number
          address
          team_id
          created_at
          updated_at
          role
        }
      }
    }
  }`,
  recipientUpdate: (recipients) => `mutation {
    update_recipients(where: {id: {_eq: ${recipients.id}}},
     _set: {
      name: ${recipients.name ? `"${recipients.name}"` : `null`},
      phone_number: ${recipients.phone_number ? `"${recipients.phone_number}"` : `null`},
      address: ${recipients.address ? `${JSON.stringify(recipients.address).replace(/"(\w+)"\s*:/g, '$1:')}` : `null`},
      team_id: ${recipients.team_id ? `${recipients.team_id}` : `null`},
      email: ${recipients.email ? `"${recipients.email}"` : `null`},
      notes: ${recipients.notes ? `"${recipients.notes.replace(/\n/g, "\\n")}"` : 'null'},
      company: ${recipients.company ? `"${recipients.company}"` : `null`},
      type: ${recipients.type ? `"${recipients.type}"` : `null`},
      merchant_id: ${recipients.merchant_id ? `${recipients.merchant_id}` : `null`},
    }) 
      {
        returning {
          id
          created_at
          created_by
          updated_at
          type
          name
          email
          phone_number
          address
          team_id
          company
          notes
          merchant_id
          merchant {
            company
            users(where: {role: {_eq: "merchant_manager"}}) {
              id
              name
              phone_number
              address
              team_id
              created_at
              updated_at
              role
            }
          }
        }
      }
  }`,
  recipientDelete: gql`mutation update_recipients(
    $id: Int!
    $deleted_at: timestamptz!
   ) {
    update_recipients(where: {id: {_eq: $id}},
     _set: {
      deleted_at: $deleted_at
    }) 
      {
        returning {
          id
        }
      }
  }`,
  recipientsDelete: gql`mutation update_recipients(
    $ids: [Int!]
    $deleted_at: timestamptz!
   ) {
    update_recipients(where: {id: {_in: $ids}},
     _set: {
      deleted_at: $deleted_at
    }) 
      {
        returning {
          id
        }
      }
  }`,
}

export default {
  recipient: (recipient) => authenticatedQuery(queries.recipient(recipient.id)),
  recipients: (recipient) => authenticatedQuery(queries.recipients(recipient.created_by)),
  moreRecipients: (ids) => authenticatedQuery(queries.moreRecipients(ids)),
  recipientsByMerchantID: (merchant_id) => authenticatedQuery(queries.recipientsByMerchantID(merchant_id)),
  recipientsByCreatedBy: (created_by) => authenticatedQuery(queries.recipientsByCreatedBy(created_by)),
  recipientCreate: recipient => authenticatedMutation(mutations.recipientCreate, {recipient: pick(recipient, [
      "name", "phone_number", "address", "team_id", "email", "notes", "company", "type", "merchant_id", "created_by"
    ])}),
  recipientUpdate: recipient => authenticatedMutation(mutations.recipientUpdate(recipient)),
  recipientDelete: (data) => authenticatedMutation(mutations.recipientDelete, {id: data, deleted_at: moment().format()}),
  recipientsDelete: (data) => authenticatedMutation(mutations.recipientsDelete, {ids: data, deleted_at: moment().format()}),
}
