import { createAction } from '../../utils';

// Types
export const types = {
  GET_HUB_REQUEST: 'GET_HUB_REQUEST',
  GET_HUB_SUCCESS: 'GET_HUB_SUCCESS',
  GET_HUB_FAILURE: 'GET_HUB_FAILURE',

  GET_HUBS_REQUEST: 'GET_HUBS_REQUEST',
  GET_HUBS_SUCCESS: 'GET_HUBS_SUCCESS',
  GET_HUBS_FAILURE: 'GET_HUBS_FAILURE',

  CREATE_HUB_REQUEST: 'CREATE_HUB_REQUEST',
  CREATE_HUB_SUCCESS: 'CREATE_HUB_SUCCESS',
  CREATE_HUB_FAILURE: 'CREATE_HUB_FAILURE',

  UPDATE_HUB_REQUEST: 'UPDATE_HUB_REQUEST',
  UPDATE_HUB_SUCCESS: 'UPDATE_HUB_SUCCESS',
  UPDATE_HUB_FAILURE: 'UPDATE_HUB_FAILURE',

  DELETE_HUB_REQUEST: 'DELETE_HUB_REQUEST',
  DELETE_HUB_SUCCESS: 'DELETE_HUB_SUCCESS',
  DELETE_HUB_FAILURE: 'DELETE_HUB_FAILURE',

  RESET_HUB_REDUC: 'RESET_HUB_REDUC',
  RESET_CURRENT_HUB: 'RESET_CURRENT_HUB',
  SET_CURRENT_TAB_REDUC: 'SET_CURRENT_TAB_REDUC',

  CREATE_HUB_USER_REQUEST: 'CREATE_HUB_USER_REQUEST',
  CREATE_HUB_USER_FAILURE: 'CREATE_HUB_USER_FAILURE',
  CREATE_HUB_USER_SUCCESS: 'CREATE_HUB_USER_SUCCESS',

  UPDATE_HUB_USER_REQUEST: 'UPDATE_HUB_USER_REQUEST',
  UPDATE_HUB_USER_FAILURE: 'UPDATE_HUB_USER_FAILURE',
  UPDATE_HUB_USER_SUCCESS: 'UPDATE_HUB_USER_SUCCESS',

  DELETE_HUB_USER_REQUEST: 'DELETE_HUB_USER_REQUEST',
  DELETE_HUB_USER_FAILURE: 'DELETE_HUB_USER_FAILURE',
  DELETE_HUB_USER_SUCCESS: 'DELETE_HUB_USER_SUCCESS',

  GET_PACKAGES_KINDS_REQUEST: 'GET_PACKAGES_KINDS_REQUEST',
  GET_PACKAGES_KINDS_SUCCESS: 'GET_PACKAGES_KINDS_SUCCESS',
  GET_PACKAGES_KINDS_FAILURE: 'GET_PACKAGES_KINDS_FAILURE'
};

// Actions
export default {
  setCurrentTabReduc: (values) =>
      createAction(types.SET_CURRENT_TAB_REDUC, {values}),

  getPackagesKindsRequest: () =>
      createAction(types.GET_PACKAGES_KINDS_REQUEST),
  getPackagesKindsSuccess: (data) =>
      createAction(types.GET_PACKAGES_KINDS_SUCCESS, {data}),
  getPackagesKindsFailure: () =>
      createAction(types.GET_PACKAGES_KINDS_FAILURE),

  resetCurrentHub: () =>
      createAction(types.RESET_CURRENT_HUB),

  resetRequestSuccessReduc: () =>
    createAction(types.RESET_HUB_REDUC),

  getHubsRequest: () =>
    createAction(types.GET_HUBS_REQUEST),
  getHubsSuccess: (data) =>
    createAction(types.GET_HUBS_SUCCESS, {data}),
  getHubsFailure: () =>
    createAction(types.GET_HUBS_FAILURE),

  getHubRequest: (id) =>
      createAction(types.GET_HUB_REQUEST, {id}),
  getHubSuccess: (data) =>
      createAction(types.GET_HUB_SUCCESS, {data}),
  getHubFailure: () =>
      createAction(types.GET_HUB_FAILURE),

  createHubRequest: (values, callback = null) =>
    createAction(types.CREATE_HUB_REQUEST, { values, callback }),
  createHubSuccess: (data) =>
    createAction(types.CREATE_HUB_SUCCESS, {data}),
  createHubFailure: () =>
    createAction(types.CREATE_HUB_FAILURE),

  updateHubRequest: (values) =>
    createAction(types.UPDATE_HUB_REQUEST, {values}),
  updateHubSuccess: (data) =>
    createAction(types.UPDATE_HUB_SUCCESS, {data}),
  updateHubFailure: () =>
    createAction(types.UPDATE_HUB_FAILURE),

  deleteHubRequest: (values) =>
    createAction(types.DELETE_HUB_REQUEST, {values}),
  deleteHubSuccess: (data) =>
    createAction(types.DELETE_HUB_SUCCESS, {data}),
  deleteHubFailure: () =>
    createAction(types.DELETE_HUB_FAILURE),

  createHubUserRequest: (values) =>
      createAction(types.CREATE_HUB_USER_REQUEST, { values }),
  createHubUserSuccess: (data) =>
      createAction(types.CREATE_HUB_USER_SUCCESS, {data}),
  createHubUserFailure: () =>
      createAction(types.CREATE_HUB_USER_FAILURE),

  updateHubUserRequest: (values) =>
      createAction(types.UPDATE_HUB_USER_REQUEST, {values}),
  updateHubUserSuccess: (data) =>
      createAction(types.UPDATE_HUB_USER_SUCCESS, {data}),
  updateHubUserFailure: () =>
      createAction(types.UPDATE_HUB_USER_FAILURE),

  deleteHubUserRequest: (id) =>
      createAction(types.DELETE_HUB_USER_REQUEST, {id}),
  deleteHubUserSuccess: (data) =>
      createAction(types.DELETE_HUB_USER_SUCCESS, {data}),
  deleteHubUserFailure: () =>
      createAction(types.DELETE_HUB_USER_FAILURE),
};
