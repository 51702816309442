import { createAction } from "../../utils";

// Types
export const types = {
  GET_GROUPS_REQUEST: "GET_GROUPS_REQUEST",
  GET_GROUPS_SUCCESS: "GET_GROUPS_SUCCESS",
  GET_GROUPS_FAILURE: "GET_GROUPS_FAILURE",
};

// Actions
export default {
  getGroupsRequest: () => createAction(types.GET_GROUPS_REQUEST),
  getGroupsSuccess: (data) => createAction(types.GET_GROUPS_SUCCESS, { data }),
  getGroupsFailure: () => createAction(types.GET_GROUPS_FAILURE),
};
