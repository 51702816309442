const state = [
  {
    label: "Non attribué",
    color: "silver",
    hexColor: "#e8e7e3",
    textColor: "#000000",
    number: 0,
  },
  {
    label: "Attribué",
    color: "blue",
    hexColor: "#dce802",
    textColor: "#ffffff",
    number: 1,
  },
  {
    label: "En cours",
    color: "gold",
    hexColor: "#5DC863FF",
    textColor: "#000000",
    number: 2,
  },
  {
    label: "Terminé",
    color: "green",
    hexColor: "#005347",
    textColor: "#ffffff",
    number: 3,
  },
  {
    label: "Echoué",
    color: "red",
    hexColor: "#E85127",
    textColor: "#ffffff",
    number: 4,
  },
];

export default state;