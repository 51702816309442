import { call } from "redux-saga/effects";
import { PricingService, UserService } from "../services";
import { get } from "lodash";

export function* getNameZoneByAddressRequest({ value }) {
  let zone = null;
  if (!value || !value.data) {
    return zone;
  }
  const [newError, newResponse] = yield call(UserService.getArea, {
    point: `POINT(${value.data.geometry.coordinates[0]} ${value.data.geometry.coordinates[1]})`,
  });
  if (newError) {
    return null;
  }
  if (
    newResponse &&
    newResponse.getzonewithpoint[0] &&
    newResponse.getzonewithpoint[0].name
  ) {
    zone = {
      name: newResponse.getzonewithpoint[0].name,
      address: value,
    };
  }
  return zone;
}

export async function getNameZoneByAddress(value, teamId = null) {
  let zone = null;
  if (!value || !value.data) {
    return zone;
  }
  const res = UserService.getArea({
    point: `POINT(${value.data.geometry.coordinates[0]} ${value.data.geometry.coordinates[1]})`,
  });
  await res.then((v) => {
    const [newError, newResponse] = v;
    if (newError) {
      return null;
    }
    if (newResponse && newResponse.getzonewithpoint) {
      if (teamId) {
        const index = newResponse.getzonewithpoint.findIndex(
          (el) => el.team_id === teamId
        );
        if (index > -1) {
          zone = {
            ...newResponse.getzonewithpoint[index],
            address: value,
          };
        }
      } else {
        const index = newResponse.getzonewithpoint.findIndex(
          (el) => el.is_admin === true
        );
        if (index > -1) {
          zone = {
            ...newResponse.getzonewithpoint[index],
            address: value,
          };
        }
      }
      return null;
    }
  });
  return zone;
}

export async function getZonePricingByAddress(value, team_id) {
  const data = await getNameZoneByAddress(value, team_id);
  let result = null;
  if (data && data.id) {
    const res = PricingService.getZonePricing(data.id);
    await res.then((v) => {
      const response = get(v, 1, null);
      if (response && response.zone_pricing) {
        result = response.zone_pricing[0];
      }
    });
    return result;
  }
  return null;
}
