import { methods } from "../utils";
import {
  authenticatedMutation,
  authenticatedQuery,
  authenticatedService,
  authenticatedSubscription,
} from "./middleware";
import { pick } from "lodash"
import { gql } from "apollo-boost";

const { POST } = methods;

const RETURNING_COURSE = `
  id
  identifier
  reference
  devis
  order
  price
  cost
  state
  merchant_id
  coursier_id
  team_id
  created_at
  created_by
  updated_at
  updated_by
  rejected_teams
  nb_bon
  invoice_courses {
    invoice_id
  }
  userByCreatedBy {
    role
  }
  userByUpdatedBy {
    role
  }
  merchant {
    id
    created_at
    updated_at
    company
    is_contractor
    fragile
    food
    package_types_id
    notes
    payment_deadline
    discount
    taxe
    bank_ID
    matriculation
    type
    group_id
    price_bon
    package_types {
      id
      icon_fa
      icon
      desc
      name
      volumn
      weight
    }
    package_type_receivers {
      package_type {
        id
        icon_fa
        icon
        desc
        name
        volumn
        weight
        is_admin
      }
      receiver_recipient_id
      package_type_id
      receiver_merchant_id
    }
    default_packages {
      package {
        id
        fragile
        is_default
        kind_id
        declared_value
        package_type_id
        volumn
        weight
      }
    }
    users(where: {role: {_eq: "merchant_manager"}}) {
      id
      email
      name
      phone_number
      address
      team_id
      new_user
      created_at
      updated_at
      role
    }
  }
  tasks {
    created_at
    course_id
    uid
    address
    company
    hub_id
    merchant_id
    recipient_id
    order
    message
    filename
    sign_filename
    coursier_id
    coursier {
      availability
      user {
        name
      }
    }
    history
    order_coursier
    start_date
    end_date
    email
    state
    id
    name
    nb_bon
    notes
    phone
    type
    packages {
      id
      uid
      delivery_status_id
      weight
      volumn
      kind_id
      package_kind {
        id
      }
      fragile
      task_id
      pickup_id
      delivery_id
      declared_value
      number
    }
    packagesByDeliveryId {
      id
      uid
      delivery_status_id
      weight
      volumn
      kind_id
      package_kind {
        id
        kind
      }
      package_type {
        name
      }
      fragile
      task_id
      pickup_id
      delivery_id
      package_type_id
      declared_value
      number
    }
    packagesByPickupId {
      id
      uid
      delivery_status_id
      weight
      volumn
      kind_id
      package_kind {
        id
        kind
      }
      package_type {
        name
      }
      fragile
      task_id
      pickup_id
      delivery_id
      package_type_id
      declared_value
      number
    }
    packagesByDeliveryId_aggregate {
      aggregate {
        sum {
          weight
          volumn
        }
      }
    }
    packagesByPickupId_aggregate {
      aggregate {
        sum {
          weight
          volumn
        }
      }
    }
  }
`;

const RETURNING_TASK = `
    created_at
    course_id
    uid
    address
    company
    hub_id
    merchant_id
    recipient_id
    order
    message
    filename
    sign_filename
    coursier_id
    coursier {
      availability
      user {
        name
      }
    }
    history
    order_coursier
    start_date
    end_date
    email
    state
    id
    name
    nb_bon
    notes
    phone
    type
    packages {
      id
      uid
      delivery_status_id
      weight
      volumn
      kind_id
      package_kind {
        id
      }
      fragile
      task_id
      pickup_id
      delivery_id
      declared_value
      number
    }
    packagesByDeliveryId {
      id
      uid
      delivery_status_id
      weight
      volumn
      kind_id
      package_kind {
        id
        kind
      }
      package_type {
        name
      }
      fragile
      task_id
      pickup_id
      delivery_id
      package_type_id
      declared_value
      number
    }
    packagesByPickupId {
      id
      uid
      delivery_status_id
      weight
      volumn
      kind_id
      package_kind {
        id
        kind
      }
      package_type {
        name
      }
      fragile
      task_id
      pickup_id
      delivery_id
      package_type_id
      declared_value
      number
    }
    packagesByDeliveryId_aggregate {
      aggregate {
        sum {
          weight
          volumn
        }
      }
    }
    packagesByPickupId_aggregate {
      aggregate {
        sum {
          weight
          volumn
        }
      }
    }
`

/**
 * GRAPHQL QUERIES
 */
const queries = {
  all: gql`
    subscription courses($where: courses_bool_exp!, $limit: Int, $offset: Int) {
      courses(
        where: $where
        limit: $limit
        offset: $offset
        order_by: { created_at: desc }
      ) {
        id
        devis
        identifier
        reference
        team_id
        team {
          name
        }
        created_at
        created_by
        updated_at
        nb_bon
        price
        state
        rejected_teams
        attribution_date
        merchant {
          id
          company
          users(where: { role: { _eq: "merchant_manager" } }) {
            team_id
            name
          }
        }
        orderByOrderId {
          delivery_status {
            status
          }
        }
        tasks {
          id
          course_id
          updated_at
          co2
          order_coursier
          order
          packagesByDeliveryId_aggregate {
            aggregate {
              sum {
                weight
                volumn
              }
            }
          }
          packagesByPickupId_aggregate {
            aggregate {
              sum {
                weight
                volumn
              }
            }
          }
          coursier_id
          coursier {
            availability
            user {
              name
            }
          }
          state
          nb_bon
          type
          uid
          address
          company
          name
          phone
          start_date
          end_date
          notes
          message
          filename
          sign_filename
          merchant_id
          recipient_id
          hub_id
          packagesByDeliveryId {
            id
            uid
            delivery_status_id
            weight
            volumn
            kind_id
            package_kind {
              id
              kind
            }
            package_type {
              name
            }
            fragile
            task_id
            pickup_id
            delivery_id
            package_type_id
            declared_value
            number
          }
          packagesByPickupId {
            id
            uid
            delivery_status_id
            weight
            volumn
            kind_id
            package_kind {
              id
              kind
            }
            package_type {
              name
            }
            fragile
            task_id
            pickup_id
            delivery_id
            package_type_id
            declared_value
            number
          }
        }
      }
    }
  `,
  moreCourses: gql`query courses(
        $ids: [Int!]
  ) {
      courses(where: {id: {_in: $ids}}) {
        ${RETURNING_COURSE}
      }
  }`,
  getDeliveryStatus: `delivery_status {
      id
      status
  }`,
  allCoursesCounts: gql`
    query tasks(
      $whereNotAttributeDay: tasks_bool_exp!
      $whereAttributeDay: tasks_bool_exp!
      $whereInProgressDay: tasks_bool_exp!
      $whereValidateDay: tasks_bool_exp!
      $whereErrorDay: tasks_bool_exp!
      $whereNotAttributeWeek: tasks_bool_exp!
      $whereAttributeWeek: tasks_bool_exp!
      $whereInProgressWeek: tasks_bool_exp!
      $whereValidateWeek: tasks_bool_exp!
      $whereErrorWeek: tasks_bool_exp!
    ) {
      tasks_0_day: tasks_aggregate(where: $whereNotAttributeDay) {
        aggregate {
          count
        }
      }
      tasks_1_day: tasks_aggregate(where: $whereAttributeDay) {
        aggregate {
          count
        }
      }
      tasks_2_day: tasks_aggregate(where: $whereInProgressDay) {
        aggregate {
          count
        }
      }
      tasks_3_day: tasks_aggregate(where: $whereValidateDay) {
        aggregate {
          count
        }
      }
      tasks_4_day: tasks_aggregate(where: $whereErrorDay) {
        aggregate {
          count
        }
      }
      tasks_0_week: tasks_aggregate(where: $whereNotAttributeWeek) {
        aggregate {
          count
        }
      }
      tasks_1_week: tasks_aggregate(where: $whereAttributeWeek) {
        aggregate {
          count
        }
      }
      tasks_2_week: tasks_aggregate(where: $whereInProgressWeek) {
        aggregate {
          count
        }
      }
      tasks_3_week: tasks_aggregate(where: $whereValidateWeek) {
        aggregate {
          count
        }
      }
      tasks_4_week: tasks_aggregate(where: $whereErrorWeek) {
        aggregate {
          count
        }
      }
    }
  `,
  coursesCounts: gql`
    query courses(
      $whereDevis: courses_bool_exp!
      $whereToTreat: courses_bool_exp!
    ) {
      courses_devis: courses_aggregate(where: $whereDevis) {
        aggregate {
          count
        }
      }
      courses_to_treat: courses_aggregate(where: $whereToTreat) {
        aggregate {
          count
        }
      }
    }
  `,
  coursesCount: gql`query courses_count(
    $where: courses_bool_exp!
  ) {
      courses_aggregate(where: $where) {
        aggregate {
          count
        }
      }
  }`,
  getPackageKinds: `package_kinds {
      id
      kind
  }`,
  getCoursesIdentifier: `courses {
      id
      identifier
  }`,
  tasks: (where) => `tasks(where: {${where}}) {
      id
      uid
      type
      company
      order
      name
      state
      address
      phone
      date
      nb_bon
      notes
      sign_filename
      filename
      message
      created_by
      created_at
      updated_at
      updated_by
    }`,
  courses: gql`subscription courses(
      $where: courses_bool_exp!,
      $limit: Int!,
      $offset: Int!
    ) {
      courses(where: $where, limit: $limit, offset: $offset, order_by: {created_at: desc}) {
        ${RETURNING_COURSE}
      }
    }`,
  course: gql`query course(
    $id: Int!
  ) {
    courses(where: {id: {_eq: $id}}) {
      ${RETURNING_COURSE}
    }
  }`,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  createTask: gql`mutation create_task(
    $task: tasks_insert_input!
  ) {
    insert_tasks_one(object: $task) 
     {
        ${RETURNING_TASK}
     }
  }`,
  updateTask: gql`mutation update_task(
    $id: Int!
    $task: tasks_set_input!
  ) {
    update_tasks(where: {id: {_eq: $id}}, _set: $task) {
      returning {
        ${RETURNING_TASK}
      }
    }
  }`,
  deleteCourse: (id) => `mutation {
      delete_receipt_pdfs(where: {course_id: {_eq: ${id}}}) {
        returning {
          id
        }
      }
    delete_courses(where: {id: {_eq: ${id}}})
    {
      returning {
        id
      }
    }
  }`,
  createCourse: gql`mutation insert_course_one(
    $course: courses_insert_input!
  ) {
    insert_courses_one(object: $course)
    {
      ${RETURNING_COURSE}
    }
  }`,
  modifyCourse: gql`mutation update_course(
    $id: Int!
    $course: courses_set_input!
  ) {
    update_courses(where: {id: {_eq: $id}}, _set: $course)
    {
      returning {
        ${RETURNING_COURSE}
      }
    }
  }`,
  modifyOnlyCourse: gql`mutation update_courses(
    $id: Int!
    $course: courses_set_input!
  ) {
    update_courses(where: {id: {_eq: $id}}, _set: $course)
    {
      returning {
        ${RETURNING_COURSE}
      }
    }
  }`,
  modifyTask: (values) => `mutation {
    update_tasks(where: {id: {_eq: ${values.id}}}, _set: {
      state: ${
        values.state !== null && values.state !== undefined
          ? `"${values.state}"`
          : `null`
      }
      coursier_id: ${
        values.coursier_id !== null && values.coursier_id !== undefined
          ? `"${values.coursier_id}"`
          : `null`
      }
    }) {
      returning {
        uid
        address
        company
        email
        hub_id
        merchant_id
        recipient_id
        coursier_id
        start_date
        end_date
        state
        id
        name
        order
        order_coursier
        nb_bon
        notes
        phone
        type
        sign_filename
        filename
        message
        packages {
          id
          uid
          delivery_status_id
          weight
          volumn
          kind_id
          package_kind {
            id
          }
          fragile
          task_id
        }
      }
    }
  }`,
  deleteCourseWithTasks: (id) => `mutation {
    delete_tasks(where: {course_id: {_eq: ${id}}})
    {
      returning {
        id
      }
    }
    delete_courses(where: {id: {_eq: ${id}}})
    {
      returning {
        id
      }
    }
  }`,
  modifyCourseOrder: (mutation) => mutation,
  deleteTask: gql`
    mutation delete_tasks($ids: [Int!]) {
      delete_geolocation(where: { task_id: { _in: $ids } }) {
        returning {
          uid
        }
      }
      delete_pallets(where: { packages: { id: { _in: $ids } } }) {
        returning {
          id
        }
      }
      delete_packages(
        where: {
          _or: [
            { delivery_id: { _in: $ids } }
            { pickup_id: { _in: $ids } }
            { task_id: { _in: $ids } }
          ]
        }
      ) {
        returning {
          id
        }
      }
      delete_tasks(where: { id: { _in: $ids } }) {
        returning {
          id
        }
      }
    }
  `,
};

const routes = {
  generatePdfTask: process.env.REACT_APP_FLYDRIVE_API_URL + "/generatePdfTask",
};

export default {
  getCoursesForExport: ({ where }) => authenticatedQuery(queries.all, {where}),
  getMoreCourses: (ids) => authenticatedQuery(queries.moreCourses, { ids }),
  getDeliveryStatus: () => authenticatedQuery(queries.getDeliveryStatus),
  getAllCoursesCounts: (variables) =>
      authenticatedQuery(queries.allCoursesCounts, variables),
  getCoursesCounts: (variables) => authenticatedQuery(queries.coursesCounts, variables),
  getCoursesCount: (variables) => authenticatedQuery(queries.coursesCount, variables),
  deleteCourseWithTasks: (id) => authenticatedMutation(mutations.deleteCourseWithTasks(id)),
  generatePdfTask: ({ task, isDymo }) =>
    authenticatedService(POST, routes.generatePdfTask, { delivery: task, isDymo }, {}, {}),
  getPackageKinds: () => authenticatedQuery(queries.getPackageKinds),
  createCourse: ({ filter, tasksTab }) =>
    authenticatedMutation(mutations.createCourse, {course: {
      ...pick(filter, ["devis", "price", "cost", "food", "identifier", "fragile", "merchant_id", "team_id", "coursier_id", "created_by"]),
      order_admin: filter.order,
      package_types_id: filter.package_id,
      tasks: {
        data: tasksTab.map((v) => ({
          ...pick(v, ["uid", "hub_id", "merchant_id", "recipient_id", "company", "name", "phone", "email", "order", "order_coursier", "address", "state", "nb_bon", "notes", "type"]),
          start_date: v.start_date ? v.start_date.format() : null,
          end_date: v.end_date ? v.end_date.format() : null
        }))
      }
    }}),
  modifyCourse: (filter) =>
      authenticatedMutation(mutations.modifyCourse, {
        id: filter.id,
        course: pick(filter, ["updated_by", "devis", "price", "package_types_id", "cost", "food", "identifier", "fragile", "merchant_id", "team_id", "coursier_id", "created_by"])
      }),
  deleteCourse: ({ id }) => authenticatedMutation(mutations.deleteCourse(id)),
  getCourses: ({ where, limit, offset, updateQuery, errorQuery }, dispatch) =>
    authenticatedSubscription(
      queries.courses,
      updateQuery,
      errorQuery,
      {where, limit, offset},
      dispatch
    ),
  getCourse: ({ id, updateQuery, errorQuery }, dispatch) =>
    authenticatedSubscription(queries.course, updateQuery, errorQuery, {id}, dispatch),
  getTasks: ({ where }) => authenticatedQuery(queries.tasks(where)),
  updateTask: (values) => authenticatedMutation(mutations.updateTask, {id: values.delivery_id || values.id, task: {
      ...pick(values, ["uid", "course_id", "hub_id", "merchant_id", "recipient_id", "company", "name", "phone", "email", "order", "order_coursier", "address", "state", "nb_bon", "notes", "type"]),
      start_date: values.start_date ? values.start_date.format() : null,
      end_date: values.end_date ? values.end_date.format() : null
  }}),
  createTask: (values) => authenticatedMutation(mutations.createTask, {task: pick(values, ["uid", "hub_id", "course_id", "merchant_id", "recipient_id", "company", "name", "phone", "email", "order", "order_coursier", "address", "state", "nb_bon", "notes", "type"]),}),
  deleteTask: (ids) => authenticatedMutation(mutations.deleteTask, { ids }),
  modifyCourseOrder: ({ mutation }) => authenticatedMutation(mutations.modifyCourseOrder(mutation)),
  modifyOnlyCourse: (values) => authenticatedMutation(mutations.modifyOnlyCourse, values),
  modifyTask: ({ values }) => authenticatedMutation(mutations.modifyTask(values)),
  getCoursesIdentifier: () => authenticatedQuery(queries.getCoursesIdentifier),
};
