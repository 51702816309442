import { createAction } from '../../utils';

// Types
export const types = {
    DISPATCHERS_CREATE_REQUEST: 'DISPATCHERS_CREATE_REQUEST',
    DISPATCHERS_CREATE_FAILURE: 'DISPATCHERS_CREATE_FAILURE',
    DISPATCHERS_CREATE_SUCCESS: 'DISPATCHERS_CREATE_SUCCESS',

    DISPATCHERS_UPDATE_REQUEST: 'DISPATCHERS_UPDATE_REQUEST',
    DISPATCHERS_UPDATE_SUCCESS: 'DISPATCHERS_UPDATE_SUCCESS',
    DISPATCHERS_UPDATE_FAILURE: 'DISPATCHERS_UPDATE_FAILURE',

    DISPATCHERS_DELETE_REQUEST: 'DISPATCHERS_DELETE_REQUEST',
    DISPATCHERS_DELETE_SUCCESS: 'DISPATCHERS_DELETE_SUCCESS',
    DISPATCHERS_DELETE_FAILURE: 'DISPATCHERS_DELETE_FAILURE',
    
    GET_DISPATCHERS_REQUEST: 'GET_DISPATCHERS_REQUEST',
    GET_DISPATCHERS_SUCCESS: 'GET_DISPATCHERS_SUCCESS',
    GET_DISPATCHERS_FAILURE: 'GET_DISPATCHERS_FAILURE',

    RESET_DISPATCHERS_REDUC: 'RESET_DISPATCHERS_REDUC',
};

// Actions
export default {
    resetRequestSuccessReduc: () =>
      createAction(types.RESET_DISPATCHERS_REDUC),

    getDispatchersRequest: () =>
      createAction(types.GET_DISPATCHERS_REQUEST),
    getDispatchersSuccess: (data) =>
      createAction(types.GET_DISPATCHERS_SUCCESS, {data}),
    getDispatchersFailure: () =>
      createAction(types.GET_DISPATCHERS_FAILURE),
 
    createDispatcherRequest: (values, callback = null) =>
      createAction(types.DISPATCHERS_CREATE_REQUEST, { values, callback }),
    createDispatcherSuccess: (data) =>
      createAction(types.DISPATCHERS_CREATE_SUCCESS, {data}),
    createDispatcherFailure: () =>
      createAction(types.DISPATCHERS_CREATE_FAILURE),

    updateDispatcherRequest: (values, record, callback = null) =>
      createAction(types.DISPATCHERS_UPDATE_REQUEST, {values, record, callback}),
    updateDispatcherSuccess: (data, id) =>
      createAction(types.DISPATCHERS_UPDATE_SUCCESS, {data, id}),
    updateDispatcherFailure: () =>
      createAction(types.DISPATCHERS_UPDATE_FAILURE),

    deleteDispatcherRequest: (values) =>
      createAction(types.DISPATCHERS_DELETE_REQUEST, {values}),
    deleteDispatcherSuccess: (data) =>
      createAction(types.DISPATCHERS_DELETE_SUCCESS, {data}),
    deleteDispatcherFailure: () =>
      createAction(types.DISPATCHERS_DELETE_FAILURE),
};
