import { takeLatest, put, call, select } from 'redux-saga/effects';
import { RecipientsService } from '../../services';
import { LoaderActions } from '../loader';
import { types, default as RecipientsAction } from './actions';
import { showSuccess, showError} from  '../../utils/notifications-helper'
import { getTranslate } from 'react-localize-redux';
import {UserActions} from "../user";

function* getRecipientsByMerchantIDRequest({merchant_id}) {
  yield put(LoaderActions.loading())
  const strings = yield select(state => getTranslate(state.locale));
  const [error, result] = yield call(RecipientsService.recipientsByMerchantID, merchant_id)
  if (result) {
    yield put(RecipientsAction.getRecipientsSuccess(result.recipients))
    yield put(LoaderActions.loaded())
  } else {
    showError("get_recipients_error", strings, null, error)
    yield put(RecipientsAction.getRecipientsFailure())
    yield put(LoaderActions.loaded())
  }
}

function* getMoreRecipientsRequest({ids}) {
  yield put(LoaderActions.loading())
  const strings = yield select(state => getTranslate(state.locale));
  if (ids.length === 0) {
    yield put(RecipientsAction.getMoreRecipientsSuccess([]))
    yield put(LoaderActions.loaded())
    return
  }
  const [error, result] = yield call(RecipientsService.moreRecipients, ids)
  if (result) {
    yield put(RecipientsAction.getMoreRecipientsSuccess(result.recipients))
    yield put(LoaderActions.loaded())
  } else {
    showError("get_recipients_error", strings, null, error)
    yield put(RecipientsAction.getRecipientsFailure())
    yield put(LoaderActions.loaded())
  }
}

function* getRecipientsRequest({created_by}) {
  yield put(LoaderActions.loading())
  const strings = yield select(state => getTranslate(state.locale));
  const [error, result] = yield call(RecipientsService.recipients, {created_by})
  if (result) {
    yield put(RecipientsAction.getRecipientsSuccess(result.recipients))
    yield put(LoaderActions.loaded())
  } else {
    showError("get_recipients_error", strings, null, error)
    yield put(RecipientsAction.getRecipientsFailure())
    yield put(LoaderActions.loaded())
  }
}

function* getBeneficiariesRequest({contractor_id}) {
  yield put(LoaderActions.loading())
  const strings = yield select(state => getTranslate(state.locale));
  const [error, result] = yield call(RecipientsService.recipientsByMerchantID, contractor_id)
  if (result) {
    yield put(RecipientsAction.getBeneficiariesSuccess(result.recipients))
    yield put(LoaderActions.loaded())
  } else {
    showError("get_recipients_error", strings, null, error)
    yield put(RecipientsAction.getBeneficiariesFailure())
    yield put(LoaderActions.loaded())
  }
}

function* getRecipientRequest({id}) {
  yield put(LoaderActions.loading())
  const strings = yield select(state => getTranslate(state.locale));
  const [error, result] = yield call(RecipientsService.recipient, {id})
  if (result) {
    yield put(RecipientsAction.getRecipientSuccess(result.recipients[0]))
    yield put(LoaderActions.loaded())
  } else {
    showError("get_recipient_error", strings, null, error)
    yield put(RecipientsAction.getRecipientFailure())
    yield put(LoaderActions.loaded())
  }
}

function* recipientsCreateRequest({values, isCurrent, callback}) {
  yield put(LoaderActions.loading())
  const strings = yield select(state => getTranslate(state.locale));
  const {current_user} = yield select(state => state.user);
  const [error, result] = yield call(RecipientsService.recipientCreate, {...values, created_by: current_user.id})
  if (result) {
    showSuccess("recipient_create_success", strings)
    yield put(RecipientsAction.recipientsCreateSuccess(result.insert_recipients_one))
    if (isCurrent) {
      yield put(RecipientsAction.setRecipientUpdateData(result.insert_recipients_one))
    }
    if (callback) {callback()}
    yield put(LoaderActions.loaded())
    return [false, result.insert_recipients_one]
  } else {
    showError("recipient_create_error", strings, null, error)
    yield put(RecipientsAction.recipientsCreateFailure())
    yield put(LoaderActions.loaded())
    return [true, false]
  }
}

function* updateRecipientsRequest({values, isCurrent, callback}) {
  yield put(LoaderActions.loading())
  const strings = yield select(state => getTranslate(state.locale));

  const [error, result] = yield call(RecipientsService.recipientUpdate, values)
  if (result) {
    showSuccess("recipient_update_success", strings)
    const data = result.update_recipients.returning[0]
    yield put(RecipientsAction.recipientsUpdateSuccess(data))
    yield put(UserActions.updateSearchReceiver({...data, code: `r-${data.id}`}))
    if (isCurrent) {
      yield put(RecipientsAction.setRecipientUpdateData(data))
    }
    if (callback) {callback()}
    yield put(LoaderActions.loaded())
  } else {
    showError("recipient_update_error", strings, null, error)
    yield put(RecipientsAction.recipientsUpdateFailure())
    yield put(LoaderActions.loaded())
  }
}

function* deleteRecipientRequest({data, callback}) {
  yield put(LoaderActions.loading())
  const strings = yield select(state => getTranslate(state.locale));

  const [error, result] = yield call(RecipientsService.recipientDelete, data.key)
  if (result) {
    showSuccess("recipient_delete_success", strings)
    yield put(RecipientsAction.deleteRecipientSuccess(result.update_recipients.returning[0]))
    if (callback) {callback()}
    yield put(LoaderActions.loaded())
  } else {
    showError("recipient_delete_error", strings, null, error)
    yield put(RecipientsAction.deleteRecipientFailure())
    yield put(LoaderActions.loaded())
  }
}

function* deleteRecipientsRequest({data, callback}) {
  yield put(LoaderActions.loading())
  const strings = yield select(state => getTranslate(state.locale));

  const [error, result] = yield call(RecipientsService.recipientsDelete, data)

  if (result) {
    showSuccess("recipients_delete_success", strings)
    yield put(RecipientsAction.deleteRecipientsSuccess(result.update_recipients.returning))
    if (callback) {callback()}
    yield put(LoaderActions.loaded())
  } else {
    showError("recipients_delete_error", strings, null, error)
    yield put(RecipientsAction.deleteRecipientFailure())
    yield put(LoaderActions.loaded())
  }
}

export default [
  takeLatest(types.RECIPIENTS_CREATE_REQUEST, recipientsCreateRequest),
  takeLatest(types.RECIPIENTS_UPDATE_REQUEST, updateRecipientsRequest),
  takeLatest(types.RECIPIENT_DELETE_REQUEST, deleteRecipientRequest),
  takeLatest(types.RECIPIENTS_DELETE_REQUEST, deleteRecipientsRequest),
  takeLatest(types.GET_RECIPIENTS_REQUEST, getRecipientsRequest),
  takeLatest(types.GET_RECIPIENT_REQUEST, getRecipientRequest),
  takeLatest(types.GET_BENEFICIARIES_REQUEST, getBeneficiariesRequest),
  takeLatest(types.GET_RECIPIENTS_BY_MERCHANT_ID_REQUEST, getRecipientsByMerchantIDRequest),
  takeLatest(types.GET_MORE_RECIPIENTS_REQUEST, getMoreRecipientsRequest),
];
