import { takeLatest, put, call, select } from "redux-saga/effects";
import { GroupsService } from "../../services";
import { LoaderActions } from "../loader";
import { types, default as GroupsActions } from "./actions";
import { showSuccess, showError } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";

function* getGroupsRequest() {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(GroupsService.getGroups);
  if (result) {
    yield put(GroupsActions.getGroupsSuccess(result.groups));
    yield put(LoaderActions.loaded());
  } else {
    if (!error.loginError) {
      showError("get_groups_error", strings, null, error);
    }
    yield put(GroupsActions.getGroupsFailure());
    yield put(LoaderActions.loaded());
  }
}

export default [takeLatest(types.GET_GROUPS_REQUEST, getGroupsRequest)];
