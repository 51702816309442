import { createAction } from '../../utils';

// Types
export const types = {
  GET_CURRENT_USER_REQUEST: 'GET_CURRENT_USER_REQUEST',
  GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAILURE: 'GET_CURRENT_USER_FAILURE',
  
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',

    SEARCH_RECEIVER_REQUEST: 'SEARCH_RECEIVER_REQUEST',
    SEARCH_RECEIVER_SUCCESS: 'SEARCH_RECEIVER_SUCCESS',
    SEARCH_RECEIVER_FAILURE: 'SEARCH_RECEIVER_FAILURE',

    UPDATE_SEARCH_RECEIVER: 'UPDATE_SEARCH_RECEIVER',
};

// Actions
export default {
    updateSearchReceiver: (data) =>
        createAction(types.UPDATE_SEARCH_RECEIVER, {data}),

    getCurrentUserSuccess: (user) =>
        createAction(types.GET_CURRENT_USER_SUCCESS, { user }),
    getCurrentUserFailure: () =>
        createAction(types.GET_CURRENT_USER_FAILURE),

    getUsersRequest: () =>
        createAction(types.GET_USERS_REQUEST),
    getUsersSuccess: (data) =>
        createAction(types.GET_USERS_SUCCESS, {data}),
    getUsersFailure: () =>
        createAction(types.GET_USERS_FAILURE),

    searchReceiverRequest: (value) =>
        createAction(types.SEARCH_RECEIVER_REQUEST, {value}),
    searchReceiverSuccess: (data) =>
        createAction(types.SEARCH_RECEIVER_SUCCESS, {data}),
    searchReceiverFailure: () =>
        createAction(types.SEARCH_RECEIVER_FAILURE),
};
