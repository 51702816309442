import { takeLatest, put, call, select } from 'redux-saga/effects';
import { CitiesService } from '../../services';
import { LoaderActions } from '../loader';
import { types, default as CitiesActions } from './actions';
import { showSuccess, showError} from  '../../utils/notifications-helper'
import { getTranslate } from 'react-localize-redux';

function* getCitiesRequest() {
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  const [error, result] = yield call(CitiesService.cities)
  yield put(LoaderActions.loading())
  if (result) {
    yield put(CitiesActions.getCitiesSuccess(result.cities))
    yield put(LoaderActions.loaded())
  } else {
    showError("get_areas_error", strings, null, error)
    yield put(CitiesActions.getCitiesFailure())
    yield put(LoaderActions.loaded())
  }
}

function* updateAutoAssignRequest({cityId, autoAssign}) {
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  const [error, result] = yield call(CitiesService.updateCity, cityId, autoAssign)
  //yield put(LoaderActions.loading())
  if (result) {
    yield put(CitiesActions.getCitiesRequest());
    //yield put(CitiesActions.updateCitySuccess(result.cities))
  } else {
    showError("get_areas_error", strings, null, error)

    yield put(CitiesActions.getCitiesFailure())
  }
}


export default [
  takeLatest(types.GET_CITIES_REQUEST, getCitiesRequest),
  takeLatest(types.UPDATE_CITY_REQUEST, updateAutoAssignRequest)
];
