import { resettableReducer } from "reduxsauce";
import { AuthReducers, AuthSagas } from "./auth";
import { DispatchersReducers, DispatchersSagas } from "./dispatchers";
import { MerchantsReducers, MerchantsSagas } from "./merchants";
import { PackageReducers, PackageSagas } from "./package";
import { AreasReducers, AreasSagas } from "./areas";
import { TeamsReducers, TeamsSagas } from "./teams";
import { UserReducers, UserSagas } from "./user";
import { TasksReducers, TasksSagas } from "./tasks";
import { CommonTypes } from "./common";
import { LoaderReducers } from "./loader";
import { CoursiersReducers, CoursiersSagas } from "./coursiers";
import { HubsReducers, HubsSagas } from "./hubs";
import { OrdersReducers, OrdersSagas } from "./orders";
import { RecipientsReducers, RecipientsSagas } from "./recipients";
import { PricingReducers, PricingSagas } from "./pricing";

import { InvoicesReducers, InvoicesSagas } from "./invoice";
import { CitiesReducers, CitiesSagas } from "./cities";
import { GroupsReducers, GroupsSagas } from "./groups";

// Specify reset action type rule
const resettable = resettableReducer(CommonTypes.RESET_REDUCERS);

// Merge our redux together
const reducers = {
  auth: resettable(AuthReducers),
  recipients: resettable(RecipientsReducers),
  loader: resettable(LoaderReducers),
  dispatchers: resettable(DispatchersReducers),
  merchants: resettable(MerchantsReducers),
  user: resettable(UserReducers),
  groups: resettable(GroupsReducers),
  pricing: resettable(PricingReducers),
  packageType: resettable(PackageReducers),
  areas: resettable(AreasReducers),
  teams: resettable(TeamsReducers),
  tasks: resettable(TasksReducers),
  coursiers: resettable(CoursiersReducers),
  hubs: resettable(HubsReducers),
  orders: resettable(OrdersReducers),
  invoices: resettable(InvoicesReducers),
  cities: resettable(CitiesReducers),
};

export const rootSaga = [
  ...AuthSagas,
  ...GroupsSagas,
  ...RecipientsSagas,
  ...DispatchersSagas,
  ...MerchantsSagas,
  ...UserSagas,
  ...PackageSagas,
  ...PricingSagas,
  ...AreasSagas,
  ...TeamsSagas,
  ...TasksSagas,
  ...CoursiersSagas,
  ...HubsSagas,
  ...OrdersSagas,
  ...InvoicesSagas,
  ...CitiesSagas,
];

export default reducers;
