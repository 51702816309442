import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  loading: false,
  listByID: [],
  requestSuccess: 0
};

const failed = (state = initialState) => {
  return {...state ,requestSuccess: 2};
};

const createTeamsSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  values[data.id] = data
  return {...state, list: values, listByID: values, requestSuccess: 1, loading: false};
};

const updateTeamsSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  values[data.id] = data
  return {...state, list: values, listByID: values, requestSuccess: 1, loading: false};
};


const deleteTeamsSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  delete values[data.id]
  return {...state, list: values, listByID: values, requestSuccess: 1, loading: false};
};

const getTeamsSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = []
  data.forEach((v) => {
    values[v.id] = v
  })
  return {...state, list: values, listByID: values, loading: false};
};

const resetTeamsReduc = (state = initialState) => {
  return {...state, requestSuccess: 0, loading: false}
}

export default createReducer(initialState, {
  [types.TEAM_CREATE_FAILURE]: failed,
  [types.TEAM_CREATE_SUCCESS]: createTeamsSuccess,
  [types.TEAM_UPDATE_FAILURE]: failed,
  [types.TEAM_UPDATE_SUCCESS]: updateTeamsSuccess,
  [types.TEAM_DELETE_FAILURE]: failed,
  [types.TEAM_DELETE_SUCCESS]: deleteTeamsSuccess,
  [types.GET_TEAMS_FAILURE]: failed,
  [types.GET_TEAMS_SUCCESS]: getTeamsSuccess,
  [types.RESET_TEAMS_REDUC]: resetTeamsReduc
});
