import React from 'react';
import './loader.css';
import { Spin } from 'antd'

export const Loader = ({displayed, children, screen}) => {
  return (
    <Spin className="spin-wrapper" style={{height: "100%"}} size="large" spinning={displayed > 0} wrapperClassName={screen === "login" || screen === "subscribe" ? "spin-children" : undefined}>
        {children}
    </Spin>
  );
};
