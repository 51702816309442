import { methods } from '../utils';
import {authenticatedService, service} from './middleware';
import { encode } from "base-64";

const { POST, GET } = methods;
const routes = {
    merchant: process.env.REACT_APP_BASE_API_URL + '/users/merchant',
    user: process.env.REACT_APP_BASE_API_URL + '/users',
    userPassword: process.env.REACT_APP_BASE_API_URL + '/users/renew_pwd',
    login: process.env.REACT_APP_BASE_API_URL + '/auth/authorize',
    getToken: process.env.REACT_APP_BASE_API_URL + '/auth/token',
};

export default {
    subscribeMerchant: (body) =>
        service(POST, routes.merchant, body,{} ,{}),
    login: ({ username, password }) =>
        service(GET, routes.login, {},{
            'prompt': 'none',
            'response_type': 'code',
            'redirect_uri': process.env.REACT_APP_BASE_API_URL,
            'client_id': 'deki'
        } ,{
            'Authorization': 'Basic ' + encode(username + ":" + password),
        }),
    getToken: ({ username, password, code }) =>
        service(POST, routes.getToken, 'grant_type=authorization_code&code='+ code
            , {}, {
                'Authorization': 'Basic ' + encode(username + ":" + password),
            }),
    refreshToken: ({ authorization, refresh_token }) =>
        service(POST, routes.getToken, 'grant_type=refresh_token&refresh_token='+ refresh_token
            , {}, {
                'Authorization': 'Basic ' + authorization,
            }),
    getUser: ({email}) =>
        authenticatedService(GET, routes.user, {}, {email: email} ,{}),
    getMerchant: ({email}) =>
        authenticatedService(GET, routes.merchant, {email},{} ,{}),
    updatePassword: (body) =>
        authenticatedService(POST, routes.userPassword, body,{} ,{}),
        
};
