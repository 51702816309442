import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  token: null,
  token_type: null,
  attempts: 0,
  user_blocked: false,
  email: '',
  role: '',
  team_id: ''
};

// region Reducers
const loginAttempt = (state = initialState) => {
  const {attempts} = state;
  return {...state, attempts: attempts + 1};
};

const loginFailed = (state = initialState) => {
  const {attempts} = state;
  return {...initialState, user_blocked: attempts >= 5};
};

const loginSuccess = (state = initialState, action) => {
  const {token, refresh_token, token_type, authorization, username} = action;
  return {token, refresh_token, token_type, authorization, email: username, attempts: 0};
};

const logout = () => {
  return {...initialState};
};

const getUserSuccess = (state = initialState, action) => {
  const {role, team_id} = action;
  return {...state, role, team_id, attempts: 0};
};

// endregion

export default createReducer(initialState, {
  [types.LOGIN_REQUEST]: loginAttempt,
  [types.LOGIN_REFRESH]: loginAttempt,
  [types.LOGOUT]: logout,
  [types.LOGIN_FAILURE]: loginFailed,
  [types.LOGIN_SUCCESS]: loginSuccess,
  [types.GET_USER_SUCCESS]: getUserSuccess
});
