import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  moreRecipients: [],
  loading: false,
  hasMore: true,
  currentRecipient: {},
  beneficiaries: {},
  currentTab: "1",
  updateData: null
};

const failed = (state = initialState) => {
  return {...state};
};

const getRecipientsSuccess = (state = initialState, action) => {
  const {data} = action
  const values = []
  data.forEach((v) => {
    values[v.id] = v
  })
  return {...state, list: values, loading: false};
};

const getMoreRecipientsSuccess = (state = initialState, action) => {
  const {data} = action
  const values = []
  data.forEach((v) => {
    values[v.id] = v
  })
  return {...state, moreRecipients: values, loading: false};
};

const getBeneficiariesSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, beneficiaries: data, loading: false};
};

const getRecipientSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, currentRecipient: data, loading: false};
};

const recipientsCreateSuccess = (state = initialState, action) => {
  const {data} = action
  const { list, moreRecipients } = state
  const values = list
  const valuesMore = moreRecipients
  values[data.id] = data
  valuesMore[data.id] = data
  return {...state, list: values, moreRecipients: valuesMore, loading: false};
};

const recipientsUpdateSuccess = (state = initialState, action) => {
  const {data} = action
  const { list, moreRecipients } = state
  const values = list
  const valuesMore = moreRecipients
  values[data.id] = data
  if (valuesMore[data.id]) {
    valuesMore[data.id] = data
  }
  return {...state, list: values, moreRecipients: valuesMore, loading: false};
};

const recipientsUpdateCurrentRecipientSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, currentRecipient: data, loading: false};
};

const recipientDeleteSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  delete values[data.id]
  return {...state, list: values, loading: false};
};

const recipientsDeleteSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  data.forEach((val) => {
    delete values[val.id]
  })
  return {...state, list: values, loading: false};
};

const setCurrentTabReduc = (state = initialState, action) => {
  const {tabKey} = action
  return {...state, currentTab: tabKey, loading: false};
};


const resetRecipientsReduc = (state = initialState) => {
  return {...state, loading: false}
}

const resetCurrentRecipient = (state = initialState) => {
  return {...state, currentRecipient: {}, loading: false}
}

const recipientsFailed = (state = initialState) => {
  return {...state, loading: false};
};

const setRecipientUpdateData = (state = initialState, action) => {
  const {data} = action
  return {...state, updateData: data, loading: false};
};

export default createReducer(initialState, {
  [types.GET_RECIPIENTS_FAILURE]: recipientsFailed,
  [types.GET_RECIPIENTS_SUCCESS]: getRecipientsSuccess,
  [types.GET_RECIPIENT_FAILURE]: recipientsFailed,
  [types.GET_RECIPIENT_SUCCESS]: getRecipientSuccess,
  [types.RECIPIENTS_CREATE_FAILURE]: recipientsFailed,
  [types.RECIPIENTS_CREATE_SUCCESS]: recipientsCreateSuccess,
  [types.RECIPIENTS_UPDATE_FAILURE]: recipientsFailed,
  [types.RECIPIENTS_UPDATE_SUCCESS]: recipientsUpdateSuccess,
  [types.RECIPIENT_DELETE_FAILURE]: failed,
  [types.RECIPIENT_DELETE_SUCCESS]: recipientDeleteSuccess,
  [types.RECIPIENTS_DELETE_SUCCESS]: recipientsDeleteSuccess,
  [types.GET_BENEFICIARIES_FAILURE]: failed,
  [types.GET_BENEFICIARIES_SUCCESS]: getBeneficiariesSuccess,
  [types.RESET_RECIPIENTS_REDUC]: resetRecipientsReduc,
  [types.UPDATE_CURRENT_RECIPIENT_SUCCESS]: recipientsUpdateCurrentRecipientSuccess,
  [types.RESET_CURRENT_RECIPIENT]: resetCurrentRecipient,
  [types.SET_CURRENT_TAB_REDUC]: setCurrentTabReduc,
  [types.SET_RECIPIENT_UPDATE_DATA]: setRecipientUpdateData,
  [types.GET_MORE_RECIPIENTS_SUCCESS]: getMoreRecipientsSuccess,
});
