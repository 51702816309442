import { createSelector } from 'reselect'
import moment from "moment-timezone";

export const selectDispatchers = createSelector(
    (state) => Object.values(state.dispatchers.list),
    (dispatchers) => {
        return dispatchers.map((v) => ({key: v.id, ...v.user, ...v}))
            .sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
    }
)
