import React, { Fragment }  from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { Router, store } from './core'
import './assets/fonts/style.css'
import './assets/style.css'
import './assets/text.css'
import { GlobalLoader } from './ui/components'
import moment from "moment-timezone"
import 'moment/locale/fr'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.REACT_APP_NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'Authentication hook unauthorized this request'
        ],
        // Adjust this value in production if there is too much logs
        tracesSampleRate: 1.0,
    });

    Sentry.configureScope(scope => {
        scope.setUser({
            team_id: store.getState()?.auth?.team_id || null,
            email: store.getState()?.auth?.email
        })
    });
}

moment.tz.setDefault("Europe/France")

ReactDOM.render(
  <Provider store={store}>
    <Fragment>
      <GlobalLoader>
        <Router/>
      </GlobalLoader>
    </Fragment>
  </Provider>,
  document.getElementById('root')
);
