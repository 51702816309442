import {authenticatedMutation, authenticatedService} from "./middleware";
import { methods } from "../utils";

const { POST } = methods;

const mutations = {
  produceMail: (template, value) => {
    const t = `
        mutation {
          produce(arg1: {
            topic: "messenger_mail",
            message: "{\\"template\\":\\"${template}\\",\\"value\\": ${JSON.stringify(
      value
    ).replace(new RegExp(`"`, "g"), `\\"`)} }"
          }) {
            message
          }
        }
        `;
    return t;
  },
};

const routes = {
  mails: process.env.REACT_APP_BASE_MESSENGER_URL + "/mails",
};

export default {
  sendEmail: ({ type, toSend }) =>
    authenticatedMutation(mutations.produceMail(type, toSend)),
  sendEmails: (body) => authenticatedService(POST, routes.mails, body, {}, {}),
};
