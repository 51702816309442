import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  loading: false,
  hasMore: true
};

const getCoursiersSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = []
  data.forEach(p => {
      tmp[p.id] = p
  })
  return {...state, list: tmp, loading: false};
};

const getFailed = (state = initialState) => {
  return {...state};
};

export default createReducer(initialState, {
  [types.GET_COURSIERS_FAILURE]: getFailed,
  [types.GET_COURSIERS_SUCCESS]: getCoursiersSuccess,
});
