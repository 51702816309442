import { takeLatest, put, call, select } from 'redux-saga/effects';
import { AreasService, UserService } from '../../services';
import { LoaderActions } from '../loader';
import { types, default as AreasActions } from './actions';
import { showError} from  '../../utils/notifications-helper'
import { getTranslate } from 'react-localize-redux';

function* getAreasRequest() {
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  const [error, result] = yield call(AreasService.getAreas)
  yield put(LoaderActions.loading())
  if (result) {
    yield put(AreasActions.getAreasSuccess(result.area_team))
    yield put(LoaderActions.loaded())
  } else {
    showError("get_areas_error", strings, null, error)
    yield put(AreasActions.getAreasFailure())
    yield put(LoaderActions.loaded())
  }
}

function* checkContainAreasRequest({values}) {
  const locale = yield select(state => state.locale);
  const areas = yield select(state => state.areas);
  const strings = getTranslate(locale)
  let inZone = []
  let error = false
  for (let i = 0, l = Object.values(areas.list).length ; i < l ; i++) {
    let zone = null
    let count_addr = 0
    for (let index = 0, length = values.length ; index < length ; index++) {
      const query = {
        lon: values[index].data.geometry.coordinates[1],
        lat: values[index].data.geometry.coordinates[0],
        area: Object.values(areas.list)[i].map((v) => `ST_MakePoint(${v.coordinates.coordinates[0]},${v.coordinates.coordinates[1]})`).join(',')
      }
      query.area = query.area + `,ST_MakePoint(${Object.values(areas.list)[i][0].coordinates.coordinates[0]},${Object.values(areas.list)[i][0].coordinates.coordinates[1]})`
      const [newError, newResponse] = yield call(UserService.getArea, query)
      if (newResponse.data.rows[0]._st_contains) {
        count_addr++
        zone = Object.values(areas.list)[i][0].team_id
      } else {
        break;
      }
      if (newError)
        error = true
    }
    if (count_addr === values.length && zone) {
      inZone.push(zone)
    }
  }
  if (error) {
    showError("get_areas_error", strings, null, error)
    yield put(AreasActions.checkContainAreasFailure())
  }
  if (inZone.length > 0) {
    yield put(AreasActions.checkContainAreasSuccess(true))
  } else {
    yield put(AreasActions.checkContainAreasSuccess(false))
  }
}

export default [
  takeLatest(types.GET_AREAS_REQUEST, getAreasRequest),
  takeLatest(types.CHECK_AREAS_REQUEST, checkContainAreasRequest),
];
