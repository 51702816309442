import { createSelector } from 'reselect'

export const selectOrders = createSelector(
    (state) => state.orders && state.orders.list ? Object.values(state.orders.list) : [],
    (orders) => {
        let tmpOrders = []
        orders.forEach((valueOrder) => {
            let tmpPallets = {}
            let tmpTasksById = {}
            let tmpOrder = {
                id: valueOrder.id,
                hub_id: valueOrder.hub_id,
                merchant_id: valueOrder.merchant_id,
                merchant: valueOrder.merchant ? {...valueOrder.merchant, user: (0 in valueOrder.merchant.users ? valueOrder.merchant.users[0] : {})} : null,
                start_date: valueOrder.start_date,
                end_date: valueOrder.end_date,
                delivery_status_id: valueOrder.delivery_status_id,
                packages: [],
                pallets: []
            }
            if (valueOrder.courses) {
                valueOrder.courses.forEach((valueCourse) => {
                    if (valueCourse.tasks) {
                        valueCourse.tasks.forEach((valueTask) => {
                            if (valueTask.type === 'depot') {
                                if (valueTask.packages) {
                                    valueTask.packages.forEach((valuePackage) => {
                                        if (valuePackage.pallet) {
                                            if (tmpPallets[valuePackage.pallet.id]) {
                                                tmpPallets[valuePackage.pallet.id] = {
                                                    ...tmpPallets[valuePackage.pallet.id],
                                                    packages: [...tmpPallets[valuePackage.pallet.id].packages, {...valuePackage, course: valueCourse, depot: valuePackage.task, delivery: valuePackage.taskByDeliveryId, pickup: valuePackage.taskByPickupId}]
                                                }
                                            } else {
                                                tmpPallets[valuePackage.pallet.id] = {
                                                    ...valuePackage.pallet,
                                                    packages: [{...valuePackage, course: valueCourse, depot: valuePackage.task, delivery: valuePackage.taskByDeliveryId, pickup: valuePackage.taskByPickupId}]
                                                }
                                            }
                                        } else {
                                            tmpOrder.packages = [...tmpOrder.packages, {...valuePackage, course: valueCourse, depot: valuePackage.task, delivery: valuePackage.taskByDeliveryId, pickup: valuePackage.taskByPickupId}]
                                        }
                                    })
                                }
                            } else if (valueTask.type === 'livraison') {
                                tmpTasksById[valueTask.id] = valueTask
                            }
                        })
                    }
                })
            }
            tmpOrder.pallets = Object.values(tmpPallets)
            tmpOrders.push({key: tmpOrders.id,...tmpOrder})
        })
        return tmpOrders
    }
)