import {authenticatedMutation, authenticatedQuery} from "./middleware";

/**
 * GRAPHQL QUERIES
 */
const queries = {
    cities: `cities {
      id
      zipcodes
      name
      auto_assign
    }`,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
    cityUpdate: (cityId, autoAssign) => `mutation {
      update_cities_by_pk(pk_columns: {id: ${cityId}}, _set: {auto_assign: ${autoAssign ? 'true' : 'false'}}) {
        id
        auto_assign
        name
        zipcodes
      }  
  }`,
}

export default {
    cities: () => authenticatedQuery(queries.cities),
    updateCity: (cityId, autoAssign) => authenticatedMutation(mutations.cityUpdate(cityId, autoAssign)),
};