import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import {authenticatedMutation, authenticatedQuery, authenticatedSubscription} from "./middleware";
import {gql} from "apollo-boost";

/**
 * GRAPHQL QUERIES
 */
const queries = {
  tasksByOrderID: (
    id
  ) => `tasks(where: {_or: [{packages: {order_id: {_eq: ${id}}}}, {packages: {pallet: {order_id: {_eq: ${id}}}}}]}) {
    id
    course_id
  }
  `,
  orders: gql`query orders(
    $hub_id: Int!
  ) {
    orders(where: {hub_id: {_eq: $hub_id}}) {
      id
      hub_id
      merchant_id
      merchant {
        company
        users(where: {role: {_eq: "merchant_manager"}}) {
          id
          name
          phone_number
          address
          team_id
          created_at
          updated_at
          role
        }
      }
      delivery_status_id
      start_date
      end_date
      courses {
        id
        tasks {
          id
          uid
          type
          recipient_id
          merchant_id
          company
          name
          phone
          notes
          nb_bon
          address
          start_date
          end_date
          packages {
            id
            uid
            delivery_status_id
            weight
            volumn
            fragile
            kind_id
            task_id
            pickup_id
            delivery_id
            task {
              id
              uid
              type
              recipient_id
              merchant_id
              company
              name
              phone
              notes
              nb_bon
              address
              start_date
              end_date
            }
            taskByDeliveryId {
              id
              uid
              type
              recipient_id
              merchant_id
              company
              name
              phone
              notes
              nb_bon
              address
              start_date
              end_date
              packagesByDeliveryId {
                id
                uid
                delivery_status_id
                weight
                volumn
                kind_id
                package_kind {
                  id
                }
                fragile
                task_id
              }
            }
            taskByPickupId {
              id
              uid
              type
              recipient_id
              merchant_id
              company
              name
              phone
              notes
              nb_bon
              address
              start_date
              end_date
            }
            pallet {
              id
              uid
              delivery_status_id
            }
          }
        }
      }
    }
  }`,
  getDeliveryStatus: `delivery_status {
      id
      status
    }`,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  deletePackage: (id) => `mutation {
    delete_packages(where: {id: {_eq: ${id}}})
    {
      returning {
        id
      }
    }
  }`,
  deletePallet: (id) => `mutation {
    delete_pallets(where: {id: {_eq: ${id}}})
    {
      returning {
        id
      }
    }
  }`,
  createPallets: (values) => `mutation {
    insert_pallets(objects: [
      ${values
        .map(
          (data) => `{
          delivery_status_id: ${data.delivery_status_id},
          ${
            data.packages.length > 0
              ? `packages: {data: [
            ${data.packages
              .map(
                (pack) => `{
              uid: "${pack.uid}",
              delivery_status_id: ${pack.delivery_status_id},
              weight: ${pack.weight ? `"${pack.weight}"` : null},
              volumn: ${pack.volumn ? `"${pack.volumn}"` : null},
              fragile: ${
                pack.fragile === null || pack.fragile === undefined
                  ? false
                  : pack.fragile
              },
              kind_id: ${pack.kind_id || null},
              task_id: ${pack.task_id},
              pickup_id: ${pack.pickup_id},
              delivery_id: ${pack.delivery_id}
            }`
              )
              .join(",")}
          ]}`
              : ""
          }
        }`
        )
        .join(",")}
    ]) {
      returning {
        uid
        delivery_status_id
        packages {
          id
          uid
          delivery_status_id
          weight
          volumn
          fragile
          task_id
          kind_id
        }
      }
    }
  }`,
  createPackages: (values) => `mutation {
    insert_packages(objects: [
      ${values
        .map(
          (data) => `{
        uid: "${data.uid}",
        delivery_status_id: ${data.delivery_status_id},
        weight: ${data.weight ? `"${data.weight}"` : null},
        volumn: ${data.volumn ? `"${data.volumn}"` : null},
        fragile: ${
          data.fragile === null || data.fragile === undefined
            ? false
            : data.fragile
        },
        kind_id: ${data.kind_id || null},
        task_id: ${data.task_id},
        pickup_id: ${data.pickup_id},
        delivery_id: ${data.delivery_id}
        }`
        )
        .join(",")}
    ]) {
      returning {
        id
        uid
        delivery_status_id
        weight
        volumn
        fragile
        task_id
        kind_id
      }
    }
  }`,
  createPallet: (data) => `mutation {
    insert_pallets(objects: {
      uid: "${data.uid}",
      delivery_status_id: ${data.delivery_status_id},
      packages: {data: [
        ${data.packages
          .map(
            (value) => `{
          uid: "${value.uid}",
          delivery_status_id: ${value.delivery_status_id},
          weight: ${value.weight ? `"${value.weight}"` : null},
          volumn: ${value.volumn ? `"${value.volumn}"` : null},
          fragile: ${
            value.fragile === null || value.fragile === undefined
              ? false
              : value.fragile
          },
          kind_id: ${value.kind_id || null},
          task_id: ${value.task_id},
          pickup_id: ${value.pickup_id},
          delivery_id: ${value.delivery_id}
        }`
          )
          .join(",")}
      ]}
    }]) {
      returning {
        id
        uid
        delivery_status_id
        packages {
          id
          uid
          delivery_status_id
          weight
          volumn
          fragile
          kind_id
          task_id
          pickup_id
          delivery_id
        }
      }
    }
  }`,
  createPackage: (data) => `mutation {
    insert_packages(objects: {
      uid: "${data.uid}",
      delivery_status_id: ${data.delivery_status_id},
      ${data.pallet_id ? `pallet_id: ${data.pallet_id},` : ""}
      weight: ${data.weight ? `"${data.weight}"` : null},
      volumn: ${data.volumn ? `"${data.volumn}"` : null},
      fragile: ${
        data.fragile === null || data.fragile === undefined
          ? false
          : data.fragile
      },
      kind_id: ${data.kind_id || null},
      task_id: ${data.task_id},
      pickup_id: ${data.pickup_id},
      delivery_id: ${data.delivery_id}
    }) {
      returning {
        id
        uid
        delivery_status_id
        weight
        volumn
        fragile
        kind_id
        task_id
        pickup_id
        delivery_id
      }
    }
  }`,
  createCourse: ({
    identifier,
    order_id,
    merchant_id,
    created_by,
    values,
    hub,
    delivery,
  }) => `mutation {
    insert_courses(objects: {
      identifier: "${identifier}",
      team_id: null,
      order_id: ${order_id},
      merchant_id: ${merchant_id},
      created_by: ${created_by},
      tasks: {data: [
      {
        uid: "${uuidv4()}",
        type: "depot",
        state: 0,
        start_date: ${
          values.date && values.start
            ? `"${moment(values.date)
                .set("hour", values.start.get("hour"))
                .set("minute", values.start.get("minute"))
                .format()}"`
            : null
        }
        end_date: ${
          values.date && values.end
            ? `"${moment(values.date)
                .set("hour", values.start.get("hour"))
                .set("minute", values.start.get("minute"))
                .format()}"`
            : null
        }
      },{
        uid: "${uuidv4()}",
        type: "collecte",
        company: null,
        hub_id: ${hub.id || null},
        name: ${hub.name ? `"${hub.name}"` : null},
        phone: ${hub.phone ? `"${hub.phone}"` : null},
        email: ${hub.email ? `"${hub.email}"` : null},
        state: 0,
        address: ${
          hub.address
            ? JSON.stringify(hub.address).replace(/"(\w+)"\s*:/g, "$1:")
            : "null"
        },
        start_date: ${
          values.date
            ? `"${moment(values.date).format()}"`
            : null
        },
        end_date: ${
          values.date
            ? `"${moment(values.date).format()}"`
            : null
        },
      },{
        uid: "${uuidv4()}",
        recipient_id: ${delivery.recipient_id || null},
        merchant_id: ${delivery.merchant_id || null},
        company: ${delivery.company ? `"${delivery.company}"` : null},
        type: "livraison",
        name: ${delivery.name ? `"${delivery.name}"` : null},
        phone: ${delivery.phone ? `"${delivery.phone}"` : null},
        weight: ${delivery.weight ? `"${delivery.weight}"` : null},
        volumn: ${delivery.volumn ? `"${delivery.volumn}"` : null},
        order: ${delivery.order !== undefined ? delivery.order : null},
        order_coursier: ${
          delivery.order_coursier !== undefined
            ? `${delivery.order_coursier}`
            : `null`
        },
        address: ${
          delivery.address
            ? JSON.stringify(delivery.address).replace(/"(\w+)"\s*:/g, "$1:")
            : "null"
        },
        start_date: ${
          delivery.date && delivery.start
            ? `"${moment(delivery.date)
                .set("hour", delivery.start.get("hour"))
                .set("minute", delivery.start.get("minute"))
                .format()}"`
            : null
        },
        end_date: ${
          delivery.date && delivery.end
            ? `"${moment(delivery.date)
                .set("hour", delivery.end.get("hour"))
                .set("minute", delivery.end.get("minute"))
                .format()}"`
            : null
        },
        state: 0,
        nb_bon: ${
          delivery.nb_bon
            ? `"${delivery.nb_bon.replace(/\n/g, "\\n")}"`
            : "null"
        },
        notes: ${
          delivery.notes ? `"${delivery.notes.replace(/\n/g, "\\n")}"` : "null"
        },
      }]}
    }) {
      returning {
        id
        tasks {
          id
          type
          recipient_id
          merchant_id
          company
          order
          name
          address
          phone
          start_date
          end_date
          state
          nb_bon
          notes
          created_at
          updated_at
        }
      }
    }
  }`,
  createOrder: (order) => `mutation {
    insert_orders(objects: {
      delivery_status_id: ${
        order.delivery_status_id ? `${order.delivery_status_id}` : null
      },
      hub_id: ${order.hub && order.hub.id ? `${order.hub.id}` : null},
      merchant_id: ${order.merchant_id ? `${order.merchant_id}` : null},
      start_date: ${
        order.date && order.start
          ? `"${moment(order.date)
              .set("hour", order.start.get("hour"))
              .set("minute", order.start.get("minute"))
              .format()}"`
          : null
      }
      end_date: ${
        order.date && order.end
          ? `"${moment(order.date)
              .set("hour", order.start.get("hour"))
              .set("minute", order.start.get("minute"))
              .format()}"`
          : null
      }
    }) 
     {
       returning {
        id
        delivery_status_id
        hub_id
        merchant_id
        start_date
        end_date
       }
     }
  }`,
  updatePackage: (values) => `mutation {
    update_packages(where: {id: {_eq: ${values.id}}}, _set: {
      weight: ${values.weight ? `${values.weight}` : null},
      volumn: ${values.volumn ? `${values.volumn}` : null},
      package_type_id: ${
        values.package_type_id ? `${values.package_type_id}` : null
      },
      fragile: ${
        values.fragile === null || values.fragile === undefined
          ? false
          : values.fragile
      },
      kind_id: ${values.kind_id || null}
    }) {
      returning {
        id
      }
    }
  }`,
  updateOrder: (values) => `mutation {
      update_orders(where: {id: {_eq: ${values.id}}}, _set: {
        hub_id: ${values.hub_id},
        start_date: "${values.start_date}",
        end_date: "${values.end_date}"
      }) {
        returning {
          id
          hub_id
          merchant_id
          delivery_status_id
          start_date
          end_date
          courses {
            tasks {
              id
              uid
              recipient_id
              type
              company
              name
              phone
              notes
              nb_bon
              address
              start_date
              end_date
              packages {
                id
                uid
                delivery_status_id
                weight
                volumn
                fragile
                kind_id
                task_id
                pickup_id
                delivery_id
                task {
                  id
                  uid
                  type
                  recipient_id
                  company
                  name
                  phone
                  notes
                  nb_bon
                  address
                  start_date
                  end_date
                }
                taskByDeliveryId {
                  id
                  uid
                  type
                  recipient_id
                  company
                  name
                  phone
                  notes
                  nb_bon
                  address
                  start_date
                  end_date
                }
                taskByPickupId {
                  id
                  uid
                  type
                  recipient_id
                  company
                  name
                  phone
                  notes
                  nb_bon
                  address
                  start_date
                  end_date
                }
                pallet {
                  id
                  uid
                  delivery_status_id
                }
              }
            }
          }
        }
      }
    }`,

  deleteOrder: ({ order_id, idsPallets }) => `mutation {
    delete_packages(where: {task: {course: {order_id: {_eq: ${order_id} }}}}) {
      returning {
        id
      }
    }
    delete_pallets(where: {order_id: {_in: [${idsPallets.join(",")}]}}) {
      returning {
        id
      }
    }
    delete_tasks(where: {course: {order_id: {_eq: ${order_id}}}}) {
      returning {
        id
      }
    }
    delete_courses(where: {order_id: {_eq: ${order_id}}}) {
      returning {
        id
      }
    }
    delete_orders(where: {id: {_eq: ${order_id}}}) {
      returning {
        id
      }
    }
  }`,
};

export default {
  getOrders: ({ hub_id, updateQuery, errorQuery }, dispatch) =>
    authenticatedSubscription(queries.orders, updateQuery, errorQuery, {hub_id}, dispatch),
  getDeliveryStatus: () => authenticatedQuery(queries.getDeliveryStatus),
  createOrder: (order) => {
    return authenticatedMutation(mutations.createOrder(order));
  },
  createPallet: (values) => {
    return authenticatedMutation(mutations.createPallet(values));
  },
  createCourse: (values) => {
    return authenticatedMutation(mutations.createCourse(values));
  },
  createPallets: (values) => {
    return authenticatedMutation(mutations.createPallets(values));
  },
  createPackages: (values) => {
    return authenticatedMutation(mutations.createPackages(values));
  },
  createPackage: (values) => {
    return authenticatedMutation(mutations.createPackage(values));
  },
  deletePackage: (id) => {
    return authenticatedMutation(mutations.deletePackage(id));
  },
  deletePallet: (id) => {
    return authenticatedMutation(mutations.deletePallet(id));
  },
  updatePackage: (values) => {
    return authenticatedMutation(mutations.updatePackage(values));
  },
  updateOrder: (values) => {
    return authenticatedMutation(mutations.updateOrder(values));
  },
  deleteOrder: (values) => {
    return authenticatedMutation(mutations.deleteOrder(values));
  },
  tasksByOrderID: (id) => authenticatedQuery(queries.tasksByOrderID(id)),
};
