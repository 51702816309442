import { createAction } from "../../utils";

// Types
export const types = {
  GET_PACKAGE_TYPES_REQUEST: "GET_PACKAGE_TYPES_REQUEST",
  GET_PACKAGE_TYPES_SUCCESS: "GET_PACKAGE_TYPES_SUCCESS",
  GET_PACKAGE_TYPES_FAILURE: "GET_PACKAGE_TYPES_FAILURE",

  GET_PACKAGE_KIND_REQUEST: "GET_PACKAGE_KIND_REQUEST",
  GET_PACKAGE_KIND_SUCCESS: "GET_PACKAGE_KIND_SUCCESS",
  GET_PACKAGE_KIND_FAILURE: "GET_PACKAGE_KIND_FAILURE",

  GET_PACKAGE_TYPES_TEAM_REQUEST: "GET_PACKAGE_TYPES_TEAM_REQUEST",
  GET_PACKAGE_TYPES_TEAM_SUCCESS: "GET_PACKAGE_TYPES_TEAM_SUCCESS",
  GET_PACKAGE_TYPES_TEAM_FAILURE: "GET_PACKAGE_TYPES_TEAM_FAILURE",

  CREATE_PACKAGE_TYPE_REQUEST: "CREATE_PACKAGE_TYPE_REQUEST",
  CREATE_PACKAGE_TYPE_SUCCESS: "CREATE_PACKAGE_TYPE_SUCCESS",
  CREATE_PACKAGE_TYPE_FAILURE: "CREATE_PACKAGE_TYPE_FAILURE",

  DELETE_PACKAGE_TYPE_REQUEST: "DELETE_PACKAGE_TYPE_REQUEST",
  DELETE_PACKAGE_TYPE_SUCCESS: "DELETE_PACKAGE_TYPE_SUCCESS",
  DELETE_PACKAGE_TYPE_FAILURE: "DELETE_PACKAGE_TYPE_FAILURE",

  MODIFY_PACKAGE_TYPES_REQUEST: "MODIFY_PACKAGE_TYPES_REQUEST",
  MODIFY_PACKAGE_TYPES_SUCCESS: "MODIFY_PACKAGE_TYPES_SUCCESS",
  MODIFY_PACKAGE_TYPES_FAILURE: "MODIFY_PACKAGE_TYPES_FAILURE",

  UPDATE_PACKAGE_TYPE_REQUEST: "UPDATE_PACKAGE_TYPE_REQUEST",
  UPDATE_PACKAGE_TYPE_SUCCESS: "UPDATE_PACKAGE_TYPE_SUCCESS",
  UPDATE_PACKAGE_TYPE_FAILURE: "UPDATE_PACKAGE_TYPE_FAILURE",

  GET_PACKAGE_TYPES_BY_MERCHANT_REQUEST:
    "GET_PACKAGE_TYPES_BY_MERCHANT_REQUEST",
  GET_PACKAGE_TYPES_BY_MERCHANT_SUCCESS:
    "GET_PACKAGE_TYPES_BY_MERCHANT_SUCCESS",
  GET_PACKAGE_TYPES_BY_MERCHANT_FAILURE:
    "GET_PACKAGE_TYPES_BY_MERCHANT_FAILURE",
};

// Actions
export default {
  getPackageTypesRequest: () => createAction(types.GET_PACKAGE_TYPES_REQUEST),
  getPackageTypesSuccess: (data) =>
    createAction(types.GET_PACKAGE_TYPES_SUCCESS, { data }),
  getPackageTypesFailure: () => createAction(types.GET_PACKAGE_TYPES_FAILURE),

  getPackageKindRequest: () => createAction(types.GET_PACKAGE_KIND_REQUEST),
  getPackageKindSuccess: (data) =>
    createAction(types.GET_PACKAGE_KIND_SUCCESS, { data }),
  getPackageKindFailure: () => createAction(types.GET_PACKAGE_KIND_FAILURE),

  getPackageTypesTeamRequest: () =>
    createAction(types.GET_PACKAGE_TYPES_TEAM_REQUEST),
  getPackageTypesTeamSuccess: (data) =>
    createAction(types.GET_PACKAGE_TYPES_TEAM_SUCCESS, { data }),
  getPackageTypesTeamFailure: () =>
    createAction(types.GET_PACKAGE_TYPES_TEAM_FAILURE),

  getPackageTypesByMerchantRequest: (merchant_id) =>
    createAction(types.GET_PACKAGE_TYPES_BY_MERCHANT_REQUEST, { merchant_id }),
  getPackageTypesByMerchantSuccess: (data) =>
    createAction(types.GET_PACKAGE_TYPES_BY_MERCHANT_SUCCESS, { data }),
  getPackageTypesByMerchantFailure: () =>
    createAction(types.GET_PACKAGE_TYPES_BY_MERCHANT_FAILURE),

  createPackageTypeRequest: (values, callback) =>
    createAction(types.CREATE_PACKAGE_TYPE_REQUEST, { values, callback }),
  createPackageTypeSuccess: (data) =>
    createAction(types.CREATE_PACKAGE_TYPE_SUCCESS, { data }),
  createPackageTypeFailure: () =>
    createAction(types.CREATE_PACKAGE_TYPE_FAILURE),

  deletePackageTypeRequest: (ids, callback) =>
    createAction(types.DELETE_PACKAGE_TYPE_REQUEST, { ids, callback }),
  deletePackageTypeSuccess: (data) =>
    createAction(types.DELETE_PACKAGE_TYPE_SUCCESS, { data }),
  deletePackageTypeFailure: () =>
    createAction(types.DELETE_PACKAGE_TYPE_FAILURE),

  modifyPackageTypesTeamRequest: (teamID, packageTypeIDs) =>
    createAction(types.MODIFY_PACKAGE_TYPES_REQUEST, {
      teamID,
      packageTypeIDs,
    }),
  modifyPackageTypesSuccess: () =>
    createAction(types.MODIFY_PACKAGE_TYPES_SUCCESS),
  modifyPackageTypesFailure: () =>
    createAction(types.MODIFY_PACKAGE_TYPES_FAILURE),

  updatePackageTypeRequest: (values, callback) =>
    createAction(types.UPDATE_PACKAGE_TYPE_REQUEST, { values, callback }),
  updatePackageTypeSuccess: (data) =>
    createAction(types.UPDATE_PACKAGE_TYPE_SUCCESS, { data }),
  updatePackageTypeFailure: () =>
    createAction(types.UPDATE_PACKAGE_TYPE_FAILURE),
};
