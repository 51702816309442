import { authenticatedQuery } from './middleware'

const queries = {
  getAreas: `area_team {
    id
    created_at
    updated_at
    coordinates
    name
    team_id
}`,
}

export default {
  getAreas: () => authenticatedQuery(queries.getAreas),
}