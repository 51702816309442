import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  currentOrder: null,
  requestSuccess: 0,
  deliveryStatus: {}
};

const failed = (state = initialState) => {
  return {...state};
};

const createOrderSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  values[data.id] = data
  return {...state, list: values, requestSuccess: 1, loading: false};
};

const updateOrderSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list

  values[data.id] = data
  return {...state, list: values, currentOrder: data, requestSuccess: 1, loading: false};
};

const deleteOrderSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  delete list[data.id]
  return {...state, list: list, loading: false};
};

const getOrdersSuccess = (state = initialState, action) => {
  const {data} = action
  const values = []
  data.forEach((v) => {
    values[v.id] = v
  })
  return {...state, list: values, loading: false};
};

const getDeliveryStatusSuccess = (state = initialState, action) => {
  const {data} = action
  const values = []
  data.forEach((v) => {
    values[v.id] = v
  })
  return {...state, deliveryStatus: values, loading: false};
};

const ordersFailed = (state = initialState) => {
  return {...state, requestSuccess: 2, loading: false};
};

const resetOrdersReduc = (state = initialState) => {
  return {...state, requestSuccess: 0, loading: false}
}

const resetCurrentOrder = (state = initialState) => {
  return {...state, currentHub: null, loading: false}
}

const setCurrentOrderReduc = (state = initialState, action) => {
  const {values} = action
  return {...state, currentOrder: values, loading: false}
}

export default createReducer(initialState, {
  [types.CREATE_ORDER_FAILURE]: ordersFailed,
  [types.CREATE_ORDER_SUCCESS]: createOrderSuccess,
  [types.UPDATE_ORDER_FAILURE]: ordersFailed,
  [types.UPDATE_ORDER_SUCCESS]: updateOrderSuccess,
  [types.DELETE_ORDER_O_FAILURE]: failed,
  [types.DELETE_ORDER_O_SUCCESS]: deleteOrderSuccess,
  [types.GET_ORDERS_FAILURE]: failed,
  [types.GET_ORDERS_SUCCESS]: getOrdersSuccess,
  [types.GET_DELIVERY_STATUS_FAILURE]: failed,
  [types.GET_DELIVERY_STATUS_SUCCESS]: getDeliveryStatusSuccess,
  [types.RESET_ORDERS_REDUC]: resetOrdersReduc,
  [types.RESET_CURRENT_ORDER]: resetCurrentOrder,
  [types.SET_CURRENT_ORDER_REDUC]: setCurrentOrderReduc
});
