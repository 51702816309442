import { createAction } from '../../utils';

// Types
export const types = {
    TEAM_CREATE_REQUEST: 'TEAM_CREATE_REQUEST',
    TEAM_CREATE_FAILURE: 'TEAM_CREATE_FAILURE',
    TEAM_CREATE_SUCCESS: 'TEAM_CREATE_SUCCESS',

    TEAM_UPDATE_REQUEST: 'TEAM_UPDATE_REQUEST',
    TEAM_UPDATE_SUCCESS: 'TEAM_UPDATE_SUCCESS',
    TEAM_UPDATE_FAILURE: 'TEAM_UPDATE_FAILURE',

    TEAM_DELETE_REQUEST: 'TEAM_DELETE_REQUEST',
    TEAM_DELETE_SUCCESS: 'TEAM_DELETE_SUCCESS',
    TEAM_DELETE_FAILURE: 'TEAM_DELETE_FAILURE',
    
    GET_TEAMS_REQUEST: 'GET_TEAMS_REQUEST',
    GET_TEAMS_SUCCESS: 'GET_TEAMS_SUCCESS',
    GET_TEAMS_FAILURE: 'GET_TEAMS_FAILURE',

    RESET_TEAMS_REDUC: 'RESET_TEAMS_REDUC'
};

// Actions
export default {
    resetTeamsReduc: () =>
        createAction(types.RESET_TEAMS_REDUC),

    getTeamsRequest: () =>
      createAction(types.GET_TEAMS_REQUEST),
    getTeamsSuccess: (data) =>
      createAction(types.GET_TEAMS_SUCCESS, {data}),
    getTeamsFailure: () =>
      createAction(types.GET_TEAMS_FAILURE),
 
    createTeamRequest: (values) =>
      createAction(types.TEAM_CREATE_REQUEST, { values }),
    createTeamSuccess: (data) =>
      createAction(types.TEAM_CREATE_SUCCESS, {data}),
    createTeamFailure: () =>
      createAction(types.TEAM_CREATE_FAILURE),

    updateTeamRequest: (data, id, record) =>
      createAction(types.TEAM_UPDATE_REQUEST, {data, id, record}),
    updateTeamSuccess: (data) =>
      createAction(types.TEAM_UPDATE_SUCCESS, {data}),
    updateTeamFailure: () =>
      createAction(types.TEAM_UPDATE_FAILURE),

    deleteTeamRequest: (data) =>
      createAction(types.TEAM_DELETE_REQUEST, {data}),
    deleteTeamSuccess: (data) =>
      createAction(types.TEAM_DELETE_SUCCESS, {data}),
    deleteTeamFailure: () =>
      createAction(types.TEAM_DELETE_FAILURE),
};
