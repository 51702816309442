import { createReducer } from "reduxsauce";
import { types } from "./actions";

const initialState = {
  packageTypes: [],
  packageKinds: [],
  packageTypesTeam: [],
  packageTypesMerchant: [],
};

const getPackageTypesTeamSuccess = (state = initialState, action) => {
  const { data } = action;
  const tmp = [];
  data.forEach((p) => {
    tmp[p.id] = p;
  });
  return { ...state, packageTypesTeam: tmp };
};

const getPackageTypesSuccess = (state = initialState, action) => {
  const { data } = action;
  const tmp = [];
  data.forEach((p) => {
    tmp[p.id] = p;
  });
  return { ...state, packageTypes: tmp };
};

const getPackageTypesByMerchantSuccess = (state = initialState, action) => {
  const { data } = action;
  const tmp = [];
  data.forEach((p) => {
    tmp[p.id] = p;
  });
  return { ...state, packageTypesMerchant: tmp };
};

const createPackageTypeSuccess = (state = initialState, action) => {
  const { data } = action;
  const { packageTypes } = state;
  const tmp = packageTypes;
  tmp[data.id] = data;
  return { ...state, packageTypes: tmp };
};

const updatePackageTypeSuccess = (state = initialState, action) => {
  const { data } = action;
  const tmp = state.packageTypes;
  tmp[data.id] = data;
  return { ...state, packageTypes: tmp, loading: false };
};

const modifyPackageTypesSuccess = (state = initialState, action) => {
  return { ...state };
};

const deletePackageTypesSuccess = (state = initialState, action) => {
  const { data } = action;
  const packageTypes = state.packageTypes;
  const tmp = packageTypes.filter((v) => v.id !== data.id);
  return { ...state, packageTypes: tmp, loading: false };
};

const getPackageKindSuccess = (state = initialState, action) => {
  const { data } = action;
  const tmp = [];
  data.forEach((p) => {
    tmp[p.id] = p;
  });
  return { ...state, packageKinds: tmp, loading: false };
};

const getFailed = (state = initialState) => {
  return { ...state };
};

export default createReducer(initialState, {
  [types.GET_PACKAGE_TYPES_FAILURE]: getFailed,
  [types.GET_PACKAGE_TYPES_SUCCESS]: getPackageTypesSuccess,
  [types.GET_PACKAGE_KIND_FAILURE]: getFailed,
  [types.GET_PACKAGE_KIND_SUCCESS]: getPackageKindSuccess,
  [types.GET_PACKAGE_TYPES_TEAM_FAILURE]: getFailed,
  [types.GET_PACKAGE_TYPES_TEAM_SUCCESS]: getPackageTypesTeamSuccess,
  [types.CREATE_PACKAGE_TYPE_FAILURE]: getFailed,
  [types.CREATE_PACKAGE_TYPE_SUCCESS]: createPackageTypeSuccess,
  [types.UPDATE_PACKAGE_TYPE_FAILURE]: getFailed,
  [types.UPDATE_PACKAGE_TYPE_SUCCESS]: updatePackageTypeSuccess,
  [types.MODIFY_PACKAGE_TYPES_FAILURE]: getFailed,
  [types.MODIFY_PACKAGE_TYPES_SUCCESS]: modifyPackageTypesSuccess,
  [types.DELETE_PACKAGE_TYPE_FAILURE]: getFailed,
  [types.DELETE_PACKAGE_TYPE_SUCCESS]: deletePackageTypesSuccess,
  [types.GET_PACKAGE_TYPES_BY_MERCHANT_FAILURE]: getFailed,
  [types.GET_PACKAGE_TYPES_BY_MERCHANT_SUCCESS]:
    getPackageTypesByMerchantSuccess,
});
