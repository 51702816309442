import { takeLatest, put, call, select } from "redux-saga/effects";
import { PricingService } from "../../services";
import { LoaderActions } from "../loader";
import { types, default as PricingActions } from "./actions";
import { showSuccess, showError } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";
import _ from 'lodash'

function* getPricingRequest({}) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());
  let [error, result] = yield call(PricingService.getPricingAdmin);

  if (result && result.pricing.length >= 1) {
    yield put(PricingActions.getPricingSuccess(result.pricing[0]));
    yield put(LoaderActions.loaded());
  } else {
    yield put(PricingActions.getPricingFailure());
    yield put(LoaderActions.loaded());
    showError("get_pricing_error", strings, null, error);
  }
}

function* updatePricingRequest({ newPricingValuesForm }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  const [error, _result] = yield call(
    PricingService.updatePricing,
      _.omit(newPricingValuesForm, '__typename')
  );

  if (error) {
    showError("modify_pricing_error", strings, null, error);
    yield put(PricingActions.updatePricingFailure());
  } else {
    showSuccess("modify_pricing_success", strings);
    yield put(PricingActions.updatePricingSuccess(newPricingValuesForm));
  }
  yield put(LoaderActions.loaded());
}

function* getZonePricingRequest() {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());
  let [error, result] = yield call(PricingService.getZonePricing, {isAdmin: true});

  if (result && result.zone_pricing) {
    yield put(PricingActions.getZonePricingSuccess(result.zone_pricing));
  } else {
    yield put(PricingActions.getZonePricingFailure());
    showError("get_zone_pricing_error", strings, null, error);
  }
  yield put(LoaderActions.loaded());
}

function* updateZonePricingRequest({ newZonePricing, id, pricing }) {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  yield put(LoaderActions.loading());

  const [error, result] = yield call(PricingService.updateZonePricing, {pricing: _.omit(newZonePricing, '__typename')}, id);

  const [errorPricing, resultPricing] = yield call(PricingService.updatePricing, pricing);

  if (error || errorPricing) {
    showError("modify_zone_pricing_error", strings, null, error || errorPricing);
    yield put(PricingActions.updateZonePricingFailure());
  } else {
    showSuccess("modify_zone_pricing_success", strings);
    yield put(PricingActions.updatePricingSuccess(resultPricing.update_pricing.returning[0]));
    yield put(PricingActions.updateZonePricingSuccess(result.update_zone_pricing.returning[0]));
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_PRICING_REQUEST, getPricingRequest),
  takeLatest(types.UPDATE_PRICING_REQUEST, updatePricingRequest),
  takeLatest(types.UPDATE_ZONE_PRICING_REQUEST, updateZonePricingRequest),
  takeLatest(types.GET_ZONE_PRICING_REQUEST, getZonePricingRequest),
];
