import {authenticatedQuery} from "./middleware";

const queries = {
  coursiers: `coursier {
        id
        created_at
        updated_at
        user_id
        user {
          email
          name
          team_id
        }
    }`,
}

export default {
  getCoursiers: () => authenticatedQuery(queries.coursiers),
}