import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  loading: false,
};

const getCitiesSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, list: data, loading: false};
};

const getFailed = (state = initialState) => {
  return {...state, loading: false, list : []};
};

export default createReducer(initialState, {
  [types.GET_CITIES_FAILURE]: getFailed,
  [types.GET_CITIES_SUCCESS]: getCitiesSuccess,
});
