import {authenticatedQuery, authenticatedMutation, authenticatedService} from './middleware'
import {methods} from "../utils";

const { POST } = methods;

const queries = {
  getInvoices: (created_by_admin) => `invoice(where: {created_by_admin: {_eq: ${created_by_admin}}}) {
      id
      created_at
      updated_at
      discount
      team_id
      number
      merchant_id
      payment_deadline
      payment_date
      sent_date
      validate_by
      validate_date
      price
      state
      notes
      invoice_courses {
        course_id
        course {
          id
          identifier
          devis
          order
          price
          cost
          merchant_id
          coursier_id
          team_id
          state
          food
          fragile
          merchant {
            id
            created_at
            updated_at
            company
            is_contractor
            fragile
            food
            package_types_id
            notes
            payment_deadline
            discount
            taxe
            bank_ID
            matriculation
            type
            users(where: {role: {_eq: "merchant_manager"}}) {
              id
              email
              name
              phone_number
              address
              team_id
              new_user
              created_at
              updated_at
              role
            }
          }
          package_types_id
          tasks {
            address
            company
            order
            coursier_id
            order_coursier
            start_date
            end_date
            email
            state
            id
            name
            nb_bon
            notes
            phone
            type
          }
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    created_by_admin_user {
      address
      email
      id
      name
      phone_number
      role
      team_id
    }
    validate_user {
      id
      name
      address
      email
    }
    merchant {
      id
      company
      matriculation
      taxe
      users(where: {role: {_eq: "merchant_manager"}}) {
        id
        name
        address
        email
        phone_number
      }
    }
  }`,
  getInvoice: (id) => `invoice(where: {id: {_eq: ${id}}}) {
      id
      created_at
      updated_at
      discount
      team_id
      number
      merchant_id
      payment_deadline
      payment_date
      sent_date
      validate_by
      validate_date
      price
      state
      notes
      invoice_courses {
        course_id
        course {
          id
          identifier
          devis
          order
          price
          cost
          merchant_id
          coursier_id
          team_id
          state
          food
          fragile
          merchant {
            id
            created_at
            updated_at
            company
            is_contractor
            fragile
            food
            package_types_id
            notes
            payment_deadline
            discount
            taxe
            bank_ID
            matriculation
            type
            users(where: {role: {_eq: "merchant_manager"}}) {
              id
              email
              name
              phone_number
              address
              team_id
              new_user
              created_at
              updated_at
              role
            }
          }
          package_types_id
          tasks {
            address
            company
            order
            coursier_id
            order_coursier
            start_date
            end_date
            email
            state
            id
            name
            nb_bon
            notes
            phone
            type
          }
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    created_by_admin_user {
      address
      email
      id
      name
      phone_number
      role
      team_id
    }
    validate_user {
      id
      name
      address
    }
    merchant {
      id
      company
      matriculation
      taxe
      users(where: {role: {_eq: "merchant_manager"}}) {
        id
        name
        address
        email
        phone_number
      }
    }
  }`,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  updateInvoice: (filter, id) => `mutation {
    update_invoice(where: {id: {_eq: ${id}}}, _set: ${filter})
    {
      returning {
        id
        created_at
        updated_at
        discount
        team_id
        number
        merchant_id
        payment_deadline
        payment_date
        sent_date
        validate_by
        validate_date
        price
        state
        notes
        invoice_courses {
          course_id
          course {
            id
            identifier
            devis
            order
            price
            cost
            merchant_id
            coursier_id
            team_id
            state
            food
            fragile
            merchant {
              id
              created_at
              updated_at
              company
              is_contractor
              fragile
              food
              package_types_id
              notes
              payment_deadline
              discount
              taxe
              bank_ID
              matriculation
              type
              users(where: {role: {_eq: "merchant_manager"}}) {
                id
                email
                name
                phone_number
                address
                team_id
                new_user
                created_at
                updated_at
                role
              }
            }
            package_types_id
            tasks {
              address
              company
              order
              coursier_id
              order_coursier
              start_date
              end_date
              email
              state
              id
              name
              nb_bon
              notes
              phone
              type
            }
            created_at
            created_by
            updated_at
            updated_by
          }
        }
         created_by_admin_user {
          address
          email
          id
          name
          phone_number
          role
          team_id
        }
        validate_user {
          id
          name
          address
        }
        merchant {
          id
          company
          matriculation
          taxe
          users(where: {role: {_eq: "merchant_manager"}}) {
            id
            name
            address
            email
            phone_number
          }
        }
      }
    }
  }`,
  createInvoice: (values, courses) => `mutation {
    insert_invoice(objects: {
      number: ${values.number},
      merchant_id: ${values.merchant_id},
      created_by_admin: ${values.created_by},
      price: ${values.price ? `${values.price}` : `null`},
      discount: ${
        values.discount !== null && values.discount !== undefined
          ? `${values.discount}`
          : `0`
      },
      state: 0,
      notes: ${
        values.notes ? `"${values.notes.replace(/\n/g, "\\n")}"` : "null"
      },
      team_id: ${values.team_id ? `${values.team_id}` : `null`},
      payment_deadline: ${values.payment_deadline},
      invoice_courses: {data: [${courses
        .map((v) => `{course_id: ${v.id}}`)
        .join(",")}]}
    }) {
      returning {
        id
        created_at
        updated_at
        discount
        team_id
        number
        merchant_id
        payment_deadline
        payment_date
        sent_date
        validate_by
        validate_date
        price
        state
        notes
        invoice_courses {
          course_id
          course {
            id
            identifier
            devis
            order
            price
            cost
            merchant_id
            coursier_id
            team_id
            state
            food
            fragile
            merchant {
              id
              created_at
              updated_at
              company
              is_contractor
              fragile
              food
              package_types_id
              notes
              payment_deadline
              discount
              taxe
              bank_ID
              matriculation
              type
              users(where: {role: {_eq: "merchant_manager"}}) {
                id
                email
                name
                phone_number
                address
                team_id
                new_user
                created_at
                updated_at
                role
              }
            }
            package_types_id
            tasks {
              address
              company
              order
              coursier_id
              order_coursier
              start_date
              end_date
              email
              state
              id
              name
              nb_bon
              notes
              phone
              type
            }
            created_at
            created_by
            updated_at
            updated_by
          }
        }
        created_by_admin_user {
          address
          email
          id
          name
          phone_number
          role
          team_id
        }
        validate_user {
          id
          name
          address
          email
        }
        merchant {
          id
          company
          matriculation
          taxe
          users(where: {role: {_eq: "merchant_manager"}}) {
            id
            name
            address
            email
            phone_number
          }
        }
      }
    }
    update_courses(where: {id: {_in: [${courses
      .map((v) => v.id)
      .join(",")}]}}, _set: {state: 1})
    {
      returning {
        id
        identifier
        devis
        order
        price
        cost
        state
        merchant_id
        coursier_id
        team_id
        fragile
        food
        invoice_courses {
          invoice_id
        }
        package_types_id
        tasks {
          address
          company
          coursier_id
          order
          order_coursier
          email
          start_date
          end_date
          state
          id
          name
          nb_bon
          notes
          phone
          type
        }
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }`,
  modifyInvoice: (values, courses) => `mutation {
    update_courses_1: update_courses(where: {invoice_courses: {invoice_id: {_eq: ${
      values.id
    }}}}, _set: {state: 0})
    {
      returning {
        id
        identifier
        devis
        order
        price
        cost
        state
        merchant_id
        coursier_id
        team_id
        fragile
        food
        invoice_courses {
          invoice_id
        }
        package_types_id
        tasks {
          address
          company
          coursier_id
          order
          order_coursier
          email
          start_date
          end_date
          state
          id
          name
          nb_bon
          notes
          phone
          type
        }
        created_at
        created_by
        updated_at
        updated_by
      }
    }
    delete_invoice_courses(where: {invoice_id: {_eq : ${values.id}}})
    {
      returning {
        id
      }
    }
    ${courses.map((v, i) => {
      return `add_invoice_courses_${i}: insert_invoice_courses(objects: {course_id: ${v.id}, invoice_id: ${values.id}})
        {
          returning {
            id
          }
        }`;
    })}
    update_invoice(where: {id: {_eq: ${values.id}}}, _set: {
      number: ${values.number},
      merchant_id: ${values.merchant_id},
      price: ${values.price ? `${values.price}` : `null`},
      discount: ${
        values.discount !== null && values.discount !== undefined
          ? `${values.discount}`
          : `0`
      },
      notes: ${
        values.notes ? `"${values.notes.replace(/\n/g, "\\n")}"` : "null"
      },
      team_id: ${values.team_id ? `${values.team_id}` : `null`},
      payment_deadline: ${values.payment_deadline},
    }) {
      returning {
        id
        created_at
        updated_at
        discount
        team_id
        number
        merchant_id
        payment_deadline
        payment_date
        sent_date
        validate_by
        validate_date
        price
        state
        notes
        invoice_courses {
          course_id
          course {
            id
            identifier
            devis
            order
            price
            cost
            merchant_id
            coursier_id
            team_id
            state
            food
            fragile
            merchant {
              id
              created_at
              updated_at
              company
              is_contractor
              fragile
              food
              package_types_id
              notes
              payment_deadline
              discount
              taxe
              bank_ID
              matriculation
              type
              users(where: {role: {_eq: "merchant_manager"}}) {
                id
                email
                name
                phone_number
                address
                team_id
                new_user
                created_at
                updated_at
                role
              }
            }
            package_types_id
            tasks {
              address
              company
              order
              coursier_id
              order_coursier
              start_date
              end_date
              email
              state
              id
              name
              nb_bon
              notes
              phone
              type
            }
            created_at
            created_by
            updated_at
            updated_by
          }
        }
       created_by_admin_user {
          address
          email
          id
          name
          phone_number
          role
          team_id
        }
        validate_user {
          id
          name
          address
          email
        }
        merchant {
          id
          company
          matriculation
          taxe
          users(where: {role: {_eq: "merchant_manager"}}) {
            id
            name
            address
            email
            phone_number
          }
        }
      }
    }
    update_courses_2: update_courses(where: {id: {_in: [${courses
      .map((v) => v.id)
      .join(",")}]}}, _set: {state: 1})
    {
      returning {
        id
        identifier
        devis
        order
        price
        cost
        state
        merchant_id
        coursier_id
        team_id
        fragile
        food
        invoice_courses {
          invoice_id
        }
        package_types_id
        tasks {
          address
          company
          coursier_id
          order
          order_coursier
          email
          start_date
          end_date
          state
          id
          name
          nb_bon
          notes
          phone
          type
        }
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }`,
  deleteInvoice: (values) => `mutation {
    update_courses(where: {invoice_courses: {invoice_id: {_in: [${values
      .map((v) => v)
      .join(",")}]}}}, _set: {state: 0})
    {
      returning {
        id
        identifier
        devis
        order
        price
        cost
        state
        merchant_id
        coursier_id
        team_id
        fragile
        food
        invoice_courses {
          invoice_id
        }
        package_types_id
        tasks {
          address
          company
          coursier_id
          order
          order_coursier
          email
          start_date
          end_date
          state
          id
          name
          nb_bon
          notes
          phone
          type
        }
        created_at
        created_by
        updated_at
        updated_by
      }
    }
    delete_invoice_courses(where: {invoice_id: {_in : [${values
      .map((v) => v)
      .join(",")}]}})
    {
      returning {
        id
      }
    }
    delete_invoice_pdfs(where: {invoice_id: {_in : [${values
      .map((v) => v)
      .join(",")}]}})
    {
      returning {
        id
      }
    }
    delete_invoice(where: {id: {_in: [${values.map((v) => v).join(",")}]}}) {
      returning {
        id
      }
    }
  }`,
};

const routes = {
  generatePDF: process.env.REACT_APP_FILE_API_URL + '/generate',
  sendInvoiceMail: process.env.REACT_APP_BASE_API_URL + '/mail/sendInvoice',
}

export default {
  generatePDF: (body) => authenticatedService(POST, routes.generatePDF, body,{} ,{}),
  sendInvoiceMail: (body) => authenticatedService(POST, routes.sendInvoiceMail, body,{} ,{}),
  getInvoices: ({created_by_admin}) => authenticatedQuery(queries.getInvoices(created_by_admin)),
  getInvoice: ({id}) => authenticatedQuery(queries.getInvoice(id)),
  createInvoice: ({invoice, courses}) => authenticatedMutation(mutations.createInvoice(invoice, courses)),
  modifyInvoice: ({invoice, courses}) => authenticatedMutation(mutations.modifyInvoice(invoice, courses)),
  deleteInvoice: (values) => authenticatedMutation(mutations.deleteInvoice(values)),
  updateInvoice: ({filter, id}) => authenticatedMutation(mutations.updateInvoice(filter, id)),
}