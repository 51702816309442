import { UNAUTHORIZED } from 'http-status-codes';
import { call, put, select } from 'redux-saga/effects';
import { default as AuthActions } from '../redux/auth/actions';
import { fetch, query, mutation, subscription } from '../utils/index';
import { extractGraphQLCode } from '../utils'
import {AuthenticationService} from "./index";
import {changeSubscriptionToken} from "../utils/apolloClient";
import { get } from "lodash"
import * as Sentry from "@sentry/react";

/**
 * use it to fire the refresh service with the current auth.authorization and auth.refresh_token
 * @returns {Generator<*, string[]|*[], ?>}
 */
function* refresh() {
  const {refresh_token, authorization, role, email, team_id} = yield select(state => state.auth);
  const [tokenError, tokenResponse] = yield call(AuthenticationService.refreshToken, {authorization, refresh_token});
  if (tokenResponse && tokenResponse.data) {
    const { access_token: token, token_type, refresh_token } = tokenResponse.data;
    yield put(AuthActions.loginSuccess({token_type, token, refresh_token, authorization, role, username: email, team_id}));
    return [null, `${token_type} ${token}`]
  } else return [tokenError, null]
}

/**
 * Retry a GraphQL service, can be both query or mutation. The refresh method is used before trying to re-call the service.
 * @param service the current service to retry
 * @returns {Generator<*, *[], ?>}
 */
function* graphQLRetry(service) {
  let { error, queryString, headers, isMutation, isSubscription, updateQuery, errorQuery, variables } = service
  const {attempts} = yield select(state => state.auth);
  if (attempts === 1) {
    yield put(AuthActions.logout());
    return [error];
  }

  const [tokenError, token] = yield call(refresh);
  if (tokenError) {
    yield put(AuthActions.logout());
    return [tokenError]
  }

  headers = {...headers, Authorization: `${token}`};
  if (isSubscription) {
    changeSubscriptionToken(token)
    yield call(subscription, queryString, updateQuery, errorQuery, variables, headers)
  } else {
    const [finalError, finalResponse] = yield call(isMutation ? mutation : query, queryString, variables, headers)
    return [finalError, finalResponse];
  }
}

/**
 * GraphQL error manager. fire other middleware if necessary.
 * exemple: if a GraphQLError with "access-denied" is catch, then a Retry is fired.
 */
export function* graphQLErrorHandling(service) {
  let { error, response } = service
  const code = extractGraphQLCode(error)
  switch (code) {
    case "access-denied":
      return yield call(graphQLRetry, service)
    case "start-failed":
      return yield call(graphQLRetry, service)
    default: return [error, response];

  }
}

/**
 *  Start a graphQL mutation with the Authorization Header
 * @param queryString
 * @param variables
 * @param headers
 * @returns {Generator<*, *[]|*, Generator<*|CallEffect, *|[*, *], ?>>}
 */
export function* authenticatedMutation(
    queryString,
    variables = null,
    headers = {}
) {
  const {token} = yield select(state => state.auth);
  headers = {...headers, Authorization: `Bearer ${token}`};

  let [error, response] = yield call(mutation, queryString, variables, headers)
  const isMutation = true
  if (error)  return yield call(graphQLErrorHandling, { error, response, queryString, variables, headers, isMutation})
  else return [error, response]
}

/**
 *  Start a graphQL query with the Authorization Header
 * @param queryString
 * @param variables
 * @param headers
 * @returns {Generator<*, *[]|*, Generator<*|CallEffect, *|[*, *], ?>>}
 */
export function* authenticatedQuery(
    queryString,
    variables = null,
    headers = {}
) {
  const token = yield select(state => get(state, ["auth", "token"], null));
  const {current_user} = yield select(state => state.user);
  if (current_user.role !== "supervisor") {
    yield put(AuthActions.logout());
    return [{loginError: true}, null];
  }
  headers = {...headers, Authorization: `Bearer ${token}`};

  let [error, response] = yield call(query, queryString, variables, headers)
  const isMutation = false
  if (error)  return yield call(graphQLErrorHandling, { error, response, queryString, variables, headers, isMutation})
  else return [error, response]
}

/**
 *  Start a graphQL subscription with the Authorization Header
 * @param queryString
 * @param updateQuery
 * @param errorQuery
 * @param variables
 * @param dispatch
 * @returns {Generator<*, *[]|*, Generator<*|CallEffect, *|[*, *], ?>>}
 */
export function* authenticatedSubscription(
    queryString,
    updateQuery = null,
    errorQuery = null,
    variables = null,
    dispatch = null
) {
  const {token} = yield select(state => state.auth);
  const headers = {Authorization: `Bearer ${token}`};
  const errorFunction = (errorSub) => {
    if (dispatch) dispatch(AuthActions.graphqlRetry({ error: errorSub, queryString, updateQuery, errorQuery, variables, isSubscription: true, headers}))
  }
  yield call(subscription, queryString, updateQuery, errorFunction, variables, headers)
}

/**
 * Middleware for REST API
 */

function* authorize(service) {
  const {token_type, token} = yield select(state => state.auth);
  service.headers = {...service.headers, Authorization: `${token_type} ${token}`};
  let [error, response] = yield call(fetch, service);
  if (error && process.env.REACT_APP_NODE_ENV === "production") {
    Sentry.captureException(error)
  }
  if (error && error.response.status === UNAUTHORIZED) {
    yield put(AuthActions.logout());
    return [error];
  }

  return [error, response];
}


export function* authenticatedService(
    method,
    url,
    data = {},
    params = {},
    headers = {},
    responseType = '' ,
    uploadCallBack = () => {}
) {
  return yield call(authorize, {
    method,
    url,
    data,
    params,
    headers,
    responseType,
    uploadCallBack
  });
}

export function* service(
    method,
    url,
    data = {},
    params = {},
    headers = {},
    responseType = ''
) {
  return yield call(fetch, {
    method,
    url,
    data,
    params,
    headers,
    responseType
  });
}
