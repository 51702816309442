import { createAction } from '../../utils';

// Types
export const types = {
    GET_INVOICES_REQUEST: 'GET_INVOICES_REQUEST',
    GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
    GET_INVOICES_FAILURE: 'GET_INVOICES_FAILURE',

    GET_INVOICE_REQUEST: 'GET_INVOICE_REQUEST',
    GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
    GET_INVOICE_FAILURE: 'GET_INVOICE_FAILURE',

    CREATE_INVOICE_REQUEST: 'CREATE_INVOICE_REQUEST',
    CREATE_INVOICE_SUCCESS: 'CREATE_INVOICE_SUCCESS',
    CREATE_INVOICE_FAILURE: 'CREATE_INVOICE_FAILURE',

    MODIFY_INVOICE_REQUEST: 'MODIFY_INVOICE_REQUEST',
    MODIFY_INVOICE_SUCCESS: 'MODIFY_INVOICE_SUCCESS',
    MODIFY_INVOICE_FAILURE: 'MODIFY_INVOICE_FAILURE',

    GENERATE_PDF_REQUEST: 'GENERATE_PDF_REQUEST',
    GENERATE_PDF_SUCCESS: 'GENERATE_PDF_SUCCESS',
    GENERATE_PDF_FAILURE: 'GENERATE_PDF_FAILURE',

    UPDATE_INVOICE_REQUEST: 'UPDATE_INVOICE_REQUEST',
    UPDATE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',
    UPDATE_INVOICE_FAILURE: 'UPDATE_INVOICE_FAILURE',

    UPDATE_CURRENT_INVOICE_REDUC: 'UPDATE_CURRENT_INVOICE_REDUC',

    DELETE_INVOICE_REQUEST: 'DELETE_INVOICE_REQUEST',
    DELETE_INVOICE_SUCCESS: 'DELETE_INVOICE_SUCCESS',
    DELETE_INVOICE_FAILURE: 'DELETE_INVOICE_FAILURE',

    SEND_INVOICE_MAIL_REQUEST: 'SEND_INVOICE_MAIL_REQUEST',
    SEND_INVOICE_MAIL_SUCCESS: 'SEND_INVOICE_MAIL_SUCCESS',
    SEND_INVOICE_MAIL_FAILURE: 'SEND_INVOICE_MAIL_FAILURE',

    RESET_CURRENT_INVOICE: 'RESET_CURRENT_INVOICE'
};

// Actions
export default {
    resetCurrentInvoice: () =>
        createAction(types.RESET_CURRENT_INVOICE),

    updateCurrentInvoiceReduc: (data) =>
        createAction(types.UPDATE_CURRENT_INVOICE_REDUC, {data}),

    getInvoicesRequest: (created_by_admin) =>
        createAction(types.GET_INVOICES_REQUEST, {created_by_admin}),
    getInvoicesSuccess: (data) =>
        createAction(types.GET_INVOICES_SUCCESS, {data}),
    getInvoicesFailure: () =>
        createAction(types.GET_INVOICES_FAILURE),

    sendInvoiceMailRequest: (values) =>
        createAction(types.SEND_INVOICE_MAIL_REQUEST, {values}),
    sendInvoiceMailSuccess: (data) =>
        createAction(types.SEND_INVOICE_MAIL_SUCCESS, {data}),
    sendInvoiceMailFailure: () =>
        createAction(types.SEND_INVOICE_MAIL_FAILURE),

    getInvoiceRequest: (id) =>
        createAction(types.GET_INVOICE_REQUEST, {id}),
    getInvoiceSuccess: (data) =>
        createAction(types.GET_INVOICE_SUCCESS, {data}),
    getInvoiceFailure: () =>
        createAction(types.GET_INVOICE_FAILURE),

    createInvoiceRequest: (invoice, courses, path) =>
        createAction(types.CREATE_INVOICE_REQUEST, {invoice, courses, path}),
    createInvoiceSuccess: (data) =>
        createAction(types.CREATE_INVOICE_SUCCESS, {data}),
    createInvoiceFailure: () =>
        createAction(types.CREATE_INVOICE_FAILURE),

    modifyInvoiceRequest: (invoice, courses, path) =>
        createAction(types.MODIFY_INVOICE_REQUEST, {invoice, courses, path}),
    modifyInvoiceSuccess: (data) =>
        createAction(types.MODIFY_INVOICE_SUCCESS, {data}),
    modifyInvoiceFailure: () =>
        createAction(types.MODIFY_INVOICE_FAILURE),

    updateInvoiceRequest: (values, isCurrent = false) =>
        createAction(types.UPDATE_INVOICE_REQUEST, {values, isCurrent}),
    updateInvoiceSuccess: (data) =>
        createAction(types.UPDATE_INVOICE_SUCCESS, {data}),
    updateInvoiceFailure: () =>
        createAction(types.UPDATE_INVOICE_FAILURE),

    deleteInvoiceRequest: (values, isCurrent = false) =>
        createAction(types.DELETE_INVOICE_REQUEST, {values, isCurrent}),
    deleteInvoiceSuccess: (data) =>
        createAction(types.DELETE_INVOICE_SUCCESS, {data}),
    deleteInvoiceFailure: () =>
        createAction(types.DELETE_INVOICE_FAILURE),

    generatePDFRequest: (values, isCurrent = false, download = false) =>
        createAction(types.GENERATE_PDF_REQUEST, {values, isCurrent, download}),
    generatePDFSuccess: (data, isCurrent) =>
        createAction(types.GENERATE_PDF_SUCCESS, {data, isCurrent}),
    generatePDFFailure: () =>
        createAction(types.GENERATE_PDF_FAILURE),
};
