import { Button, Popover, Tag, Tooltip } from "antd";
import foodDico from "./dico";
import { all, call, put } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import { LoaderActions } from "../redux/loader";
import { showError } from "./notifications-helper";
import { PackageService, TasksService } from "../services";
import typeColis from "./typeColis";
import dico from "./dico";
import "./style.css";
import { formatTypePackage } from "./file-tools";
import { InfoOutlined } from "@ant-design/icons";
import { get, pick, omit } from "lodash";
import { TasksActions } from "../redux/tasks";

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export function firstCharUpper(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export const printCompleteName = (value, strings) => {
  let res = "";
  if (value.merchant) {
    if (value.merchant.company && value.merchant.company.length > 0) {
      res += `[${value.merchant.company}] `;
    }
    if (value.merchant?.user?.name && value.merchant.user.name.length > 0) {
      res += value.merchant.user.name;
    }
    return res;
  } else {
    return strings("any_contractor");
  }
};

export const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3, borderColor: "#0A5447" }}
    >
      <div className={"tag-render-type"}>{label}</div>
    </Tag>
  );
};

export function* createOrUpdateDefaultPackage(values, data) {
  const tmpKindByID = {};
  const [errorKinds, responseKinds] = yield call(
    PackageService.getPackageKinds
  );

  if (errorKinds) {
    yield put(LoaderActions.loaded());
    return null;
  }
  responseKinds.package_kinds.forEach((v) => {
    tmpKindByID[v.kind] = v.id;
  });
  let kind_id = null;
  if (values?.defaultPackage?.food) {
    kind_id = tmpKindByID[values.defaultPackage.food];
  }
  if (values?.defaultPackage?.id) {
    const [error, result] = yield call(PackageService.updatePackage, {
      package: {
        ...pick(values.defaultPackage, [
          "weight",
          "fragile",
          "kind_id",
          "package_type_id",
          "declared_value",
        ]),
        kind_id,
      },
      id: values.defaultPackage.id,
    });
    if (error) {
      yield put(LoaderActions.loaded());
      return null;
    }
    return [
      {
        ...data.default_packages[0],
        package: result.update_packages_by_pk,
      },
    ];
  } else {
    const [error, result] = yield call(PackageService.createDefaultPackage, {
      defaultPackage: {
        merchant_id: values.id,
        package: {
          data: {
            ...pick(values.defaultPackage, [
              "weight",
              "fragile",
              "kind_id",
              "package_type_id",
              "declared_value",
            ]),
            delivery_status_id: 1,
            is_default: true,
            kind_id,
          },
        },
      },
    });
    if (error) {
      yield put(LoaderActions.loaded());
      return null;
    }
    return [result.insert_default_package_one];
  }
}

export function displayPackage(
  item,
  course_id = null,
  packageTypes,
  packageKinds,
  strings,
  merchantGroupId = null,
  hideInfo = false
) {
  let kind = get(packageKinds, [item.kind_id, "kind"], null);
  let package_type = formatTypePackage(
    item.package_type_id,
    packageTypes,
    false
  );
  if (!kind) {
    kind = get(item, ["package_kind", "kind"], null);
  }
  if (!package_type) {
    package_type = get(item, ["package_type", "name"], null);
  }
  if (course_id) {
    return (
      <div className="div-display-package">
        <Tag className={"number-package"}>{item.number || item.key}</Tag>
        {!merchantGroupId && (
          <>
            {item.fragile && (
              <Tag className={"fragile-container-tag"}>
                <span className={"fragile-text-tag"}>
                  {strings("fragile").toUpperCase()}
                </span>
              </Tag>
            )}
            {kind && <Tag className={"text-package"}>{dico[kind]}</Tag>}
            {package_type && (
              <Tag className={"text-package"}>{package_type}</Tag>
            )}
            {item.weight && (
              <Tooltip title={strings("weight")}>
                <Tag className={"text-package"}>{item.weight} Kg</Tag>
              </Tooltip>
            )}
            {item.volumn && (
              <Tooltip title={strings("volumn")}>
                <Tag className={"text-package"}>{item.volumn} cm3</Tag>
              </Tooltip>
            )}
            {/*item.declared_value && (
              <Tooltip title={strings("market_value")}>
                <Tag className={"text-package"}>
                  {item.declared_value.toFixed(2)} {strings("money")}
                </Tag>
              </Tooltip>
            )*/}
            {item.uid && !hideInfo && (
              <Popover
                title={strings("uid_package")}
                content={
                  <>
                    <Tag>{item.uid}</Tag>
                  </>
                }
              >
                <Button size="small" shape={"circle"} icon={<InfoOutlined />} />
              </Popover>
            )}
          </>
        )}
        {merchantGroupId && (
          <>
            {item.fragile && (
              <Tag className={"fragile-container-tag"}>
                <span className={"fragile-text-tag"}>
                  {strings("fragile").toUpperCase()}
                </span>
              </Tag>
            )}
            {item.food && (
              <Tag className={"text-package"}>{foodDico[item.food]}</Tag>
            )}
            {item.package_type_id && (
              <Tag className={"text-package"}>
                {formatTypePackage(item.package_type_id, packageTypes, false)}
              </Tag>
            )}
            {item.kind_id && packageKinds[item.kind_id] && (
              <Tag className={"text-package"}>
                {dico[packageKinds[item.kind_id].kind]}
              </Tag>
            )}
            {item.weight && (
              <Tooltip title={strings("weight")}>
                <Tag className={"text-package"}>
                  {
                    typeColis[packageTypes[item.package_type_id]?.icon]
                      ?.weightTDP
                  }{" "}
                  Kg
                </Tag>
              </Tooltip>
            )}
            {item.volumn && (
              <Tooltip title={strings("volumn")}>
                <Tag className={"text-package"}>
                  {
                    typeColis[packageTypes[item.package_type_id]?.icon]
                      ?.volumeTDP
                  }{" "}
                  cm3
                </Tag>
              </Tooltip>
            )}
            {/*item.declared_value && (
              <Tooltip title={strings("market_value")}>
                <Tag className={"text-package"}>
                  {item.declared_value.toFixed(2)} {strings("money")}
                </Tag>
              </Tooltip>
            )*/}
            {item.uid && !hideInfo && (
              <Popover
                title={strings("uid_package")}
                content={
                  <>
                    <Tag>{item.uid}</Tag>
                  </>
                }
              >
                <Button size="small" shape={"circle"} icon={<InfoOutlined />} />
              </Popover>
            )}
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="div-display-package">
        <Tag className={"number-package"}>{item.key}</Tag>
        {item.fragile && (
          <Tag className={"fragile-container-tag align-self-center"}>
            <span className={"fragile-text-tag"}>
              {strings("fragile").toUpperCase()}
            </span>
          </Tag>
        )}
        {item.food && (
          <Tag className={"text-package"}>{foodDico[item.food]}</Tag>
        )}
        {item.package_type_id && (
          <Tag className={"text-package"}>
            {formatTypePackage(item.package_type_id, packageTypes, false)}
          </Tag>
        )}
        {item.package_kind_id && packageKinds[item.package_kind_id] && (
          <Tag className={"text-package"}>
            {dico[packageKinds[item.package_kind_id].kind]}
          </Tag>
        )}
        {item.weight && (
          <Tooltip title={strings("weight")}>
            <Tag className={"text-package"}>{item.weight} Kg</Tag>
          </Tooltip>
        )}
        {item.volumn && (
          <Tooltip title={strings("volumn")}>
            <Tag className={"text-package"}>{item.volumn} cm3</Tag>
          </Tooltip>
        )}
        {/*item.declared_value && (
          <Tooltip title={strings("market_value")}>
            <Tag className={"text-package"}>
              {item.declared_value.toFixed(2)} {strings("money")}
            </Tag>
          </Tooltip>
        )*/}
      </div>
    );
  }
}

export function* createPackagesCourse(tasksPackages, course, strings) {
  let tmpTaskByUID = {};
  let tmpDSByState = {};
  let tmpPackages = [];
  let tmpKindByID = {};
  const [errorDS, responseDS] = yield call(TasksService.getDeliveryStatus);
  const [errorKinds, responseKinds] = yield call(
    PackageService.getPackageKinds
  );
  if (errorDS || errorKinds) {
    showError("create_task_error", strings, null, errorDS || errorKinds);
    yield put(TasksActions.createFailure());
    yield put(LoaderActions.loaded());
    return false;
  }
  course.tasks.forEach((v) => (tmpTaskByUID[v.uid] = v));
  responseDS.delivery_status.forEach((v) => {
    tmpDSByState[v.status] = v.id;
  });
  responseKinds.package_kinds.forEach((v) => {
    tmpKindByID[v.kind] = v.id;
  });
  tasksPackages.forEach((v) => {
    if (v.packagesByPickupId && v.packagesByPickupId.length) {
      v.packagesByPickupId.forEach((a) => {
        tmpPackages[a.key] = {
          ...omit(a, ["food", "key"]),
          ...(tmpPackages[a.key] || {}),
          uid: uuidv4(),
          pickup_id: tmpTaskByUID[v.uid].id,
          delivery_status_id: tmpDSByState["in_delivery"],
          kind_id: tmpKindByID[a.food] || a.kind_id,
          package_type_id: a.package_type_id,
          number: a.number,
          market_value: a.declared_value,
          weight: a.weight,
          volumn: a.volumn,
        };
      });
    }
    if (v.packagesByDeliveryId && v.packagesByDeliveryId.length) {
      v.packagesByDeliveryId.forEach((a) => {
        tmpPackages[a.key] = {
          ...omit(a, ["food", "key"]),
          ...(tmpPackages[a.key] || {}),
          uid: uuidv4(),
          delivery_id: tmpTaskByUID[v.uid].id,
          delivery_status_id: tmpDSByState["in_delivery"],
          kind_id: tmpKindByID[a.food] || a.kind_id,
          package_type_id: a.package_type_id,
          number: a.number,
          market_value: a.declared_value,
          weight: a.weight,
          volumn: a.volumn,
        };
      });
    }
  });
  if (tmpPackages.length > 0) {
    var [errorPackage, responsePackage] = yield call(
      PackageService.createPackage,
      { packages: tmpPackages.filter(v => v).map((v) => pick(v, ["weight", "volumn", "fragile", "kind_id", "task_id", "delivery_id", "pickup_id", "delivery_status_id", "order_id", "pallet_id", "uid", "package_type_id", "number", "declared_value", "identifier", "is_default", "externalData"])) }
    );
    if (responsePackage && !errorPackage)
      return responsePackage.insert_packages.returning;
    else return false;
  } else return tmpPackages;
}

export function* updatePackagesCourse(tasksPackages, course, strings) {
  let tmpTaskByUID = {};
  let tmpDSByState = {};
  let tmpKindByID = {};
  let tmpPTByID = {};
  let tmpPackages = [];
  const [errorKinds, responseKinds] = yield call(
    PackageService.getPackageKinds
  );
  const [errorDS, responseDS] = yield call(TasksService.getDeliveryStatus);
  const [errorPT, responsePT] = yield call(PackageService.packageTypes);
  if (errorPT || errorDS || errorKinds) {
    showError("update_package_error", strings, null, errorPT || errorDS ||errorKinds);
    yield put(LoaderActions.loaded());
    return false;
  }

  course.tasks.forEach((v) => {
    tmpTaskByUID[v.uid] = v;
  });
  responseKinds.package_kinds.forEach((v) => {
    tmpKindByID[v.kind] = v.id;
  });
  responseDS.delivery_status.forEach((v) => {
    tmpDSByState[v.status] = v.id;
  });
  responsePT.package_types.forEach((v) => {
    tmpPTByID[v.id] = v.icon;
  });

  for (let i = 0, l = tasksPackages.length; i < l; i++) {
    let tmpTask = tmpTaskByUID[tasksPackages[i].uid];
    if (tmpTask.packagesByPickupId && tmpTask.packagesByPickupId.length) {
      for (let a = 0, le = tmpTask.packagesByPickupId.length; a < le; a++) {
        const pdata = omit(tmpTask.packagesByPickupId[a], ["food", "key"]);
        const taskWithUid = tasksPackages[i].packagesByPickupId.filter(
          (d) => d.uid === pdata.uid
        )[0];
        if (!taskWithUid) {
          tmpPackages[pdata.id] = {
            ...(tmpPackages[pdata.id] || pdata),
            pickup_id: null,
          };
        }
      }
    }
    if (tmpTask.packagesByDeliveryId && tmpTask.packagesByDeliveryId.length) {
      for (let a = 0, le = tmpTask.packagesByDeliveryId.length; a < le; a++) {
        const pdata = omit(tmpTask.packagesByDeliveryId[a], ["food", "key"])
        const taskWithUid = tasksPackages[i].packagesByDeliveryId.filter(
          (d) => d.uid === pdata.uid
        )[0];
        if (!taskWithUid) {
          tmpPackages[pdata.id] = {
            ...(tmpPackages[pdata.id] || pdata),
            delivery_id: null,
          };
        }
      }
    }
    if (
      tasksPackages[i].packagesByPickupId &&
      tasksPackages[i].packagesByPickupId.length
    ) {
      for (
        let a = 0, le = tasksPackages[i].packagesByPickupId.length;
        a < le;
        a++
      ) {
        const adata = omit(tasksPackages[i].packagesByPickupId[a], ["food", "key"])
        let tmpP = {
          ...(tmpPackages[adata.key] || {}),
          ...(adata.id ? { id: adata.id } : {}),
          uid: adata.id ? adata.uid : uuidv4(),
          pickup_id: tmpTaskByUID[tasksPackages[i].uid].id,
          fragile: adata.fragile,
          kind_id: tmpKindByID[adata.food] || adata.package_kind_id,
          package_type_id: adata.package_type_id,
          declared_value: adata.declared_value,
          number: adata.id ? adata.number : adata.key,
          weight: adata.weight,
          volumn: adata.volumn,
        };
        if (!adata.id) {
          tmpP = {
            ...tmpP,
            delivery_status_id: tmpDSByState["in_delivery"],
          };
        }
        tmpPackages[adata.key] = tmpP;
      }
    }
    if (
      tasksPackages[i].packagesByDeliveryId &&
      tasksPackages[i].packagesByDeliveryId.length
    ) {
      for (
        let a = 0, le = tasksPackages[i].packagesByDeliveryId.length;
        a < le;
        a++
      ) {
        const adata = omit(tasksPackages[i].packagesByDeliveryId[a], ["food", "key"]);
        let tmpP = {
          ...(tmpPackages[adata.key] || {}),
          ...(adata.id ? { id: adata.id } : {}),
          uid: adata.id ? adata.uid : uuidv4(),
          delivery_id: tmpTaskByUID[tasksPackages[i].uid].id,
          fragile: adata.fragile,
          kind_id: tmpKindByID[adata.food] || adata.package_kind_id,
          package_type_id: adata.package_type_id,
          declared_value: adata.declared_value,
          number: adata.id ? adata.number : adata.key,
          weight: adata.weight,
          volumn: adata.volumn,
        };
        if (!adata.id) {
          tmpP = {
            ...tmpP,
            delivery_status_id: tmpDSByState["in_delivery"],
          };
        }
        tmpPackages[adata.key] = tmpP;
      }
    }
  }
  let deletePackage = tmpPackages.filter((v) => !v.pickup_id && !v.delivery_id);
  tmpPackages = tmpPackages.filter((v) => v.pickup_id || v.delivery_id);

  if (deletePackage && deletePackage.length) {
    var [errorPackageDelete] = yield call(
      PackageService.deletePackages,
      deletePackage.map((v) => v.id)
    );
    if (errorPackageDelete) {
      showError("update_package_error", strings);
      yield put(LoaderActions.loaded());
      return false;
    }
  }
  const newPackages = tmpPackages.filter((v) => !v.id);
  const updatePackages = tmpPackages.filter((v) => v.id);
  if (newPackages && newPackages.length > 0) {
    const [errorCreatePackage] = yield call(
      PackageService.createPackage,
      { packages: newPackages }
    );
    if (errorCreatePackage) {
      showError("update_package_error", strings);
      return false;
    }
  }
  if (updatePackages && updatePackages.length > 0) {
    const responses = yield all(
      updatePackages.map((data) =>
        call(PackageService.updatePackages, { id: data.id, data })
      )
    );
    if (responses.filter((v) => v[0]).length !== 0) {
      showError("update_package_error", strings);
      return false;
    } else {
      return responses;
    }
  }
  return [];
}

export const getColorType = (type) => {
  switch (type) {
    case "livraison/collecte":
      return "#750303";
    case "livraison":
      return "#57ab27";
    case "collecte":
      return "#63226d";
    default:
      return "green";
  }
};
