import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import {HubsService, OrdersService, TasksService} from '../../services';
import { LoaderActions } from '../loader';
import { types, default as OrdersActions } from './actions';
import { showSuccess, showError} from  '../../utils/notifications-helper'
import { getTranslate } from 'react-localize-redux';
import {generateIdentifier} from "../../utils/generate-identifier-helper";
import moment from "moment-timezone";
import { v4 as uuidv4 } from 'uuid';

function* getDeliveryStatusRequest() {
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  const [error, result] = yield call(OrdersService.getDeliveryStatus)
  if (result) {
    yield put(OrdersActions.getDeliveryStatusSuccess(result.delivery_status))
    //yield put(LoaderActions.loaded())
  } else {
    if (!error.loginError) {
      showError("get_delivery_status_error", strings, null, error)
    }
    yield put(OrdersActions.getDeliveryStatusFailure())
    //yield put(LoaderActions.loaded())
  }
}

function* getOrdersRequest({hub_id, dispatch}) {
  yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  yield call(OrdersService.getOrders, {hub_id,
    updateQuery: (data) => {
      dispatch(OrdersActions.getOrdersSuccess(data.orders))
    },
    errorQuery: (err) => {
      if (!err.loginError) {
        showError("get_orders_error", strings, null, err)
      }
      dispatch(OrdersActions.getOrdersFailure())
    }}, dispatch)
  yield put(LoaderActions.loaded())
}

function addIdWithCode(value) {
  let tmp = value
  if (value.receiver_code) {
    const splt = value.receiver_code.split('-')
    if (splt[0] === 'r') {
      tmp.recipient_id = splt[1]
      tmp.merchant_id = null
    } else if (splt[0] === 'm') {
      tmp.merchant_id = splt[1]
      tmp.recipient_id = null
    }
  }
  return tmp
}

function* createOrderRequest({values, hub, callback}) {
  yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const { current_user } = yield select(state => state.user);
  const strings = getTranslate(locale)
  const [error, result] = yield call(OrdersService.getDeliveryStatus)
  const [errorHubbers, resultHubbers] = yield call(HubsService.getHubbersByHubId, hub.id)
  if (errorHubbers || error) {
    showError("order_create_error", strings, null, error || errorHubbers)
    yield put(OrdersActions.createOrderFailure())
    yield put(LoaderActions.loaded())
    return;
  }
  if (resultHubbers.users && resultHubbers.users[0]) {
    hub = {...resultHubbers.users[0], ...hub}
  }
  let status_in_delivery = result.delivery_status.filter(v => v.status === "in_delivery")[0]

  if (result) {
    let palletsData = []
    let tasksCreated = []

    const [errorOrder, resultOrder] = yield call(OrdersService.createOrder, {...values, hub, delivery_status_id: status_in_delivery.id, merchant_id: values.merchant_id})
    if (errorOrder) {
      showError("order_create_error", strings, null, errorOrder)
      yield put(OrdersActions.createOrderFailure())
      yield put(LoaderActions.loaded())
      return;
    }
    if (values.pallets.length > 0) {
      for (let i = 0, l = values.pallets.length; i < l ; i++) {
        let packagesData = []
        for (let a = 0, le = values.pallets[i].packages.length; a < le ; a++) {
          let sameTaskArray = getSameTask(values.pallets[i].packages[a], tasksCreated)
          if (sameTaskArray.length === 0) {
            const res = yield generateIdentifier()
            const [errorCourse, resultCourse] = yield call(OrdersService.createCourse, {
              identifier: res || null,
              order_id: resultOrder.insert_orders.returning[0].id,
              merchant_id: values.merchant_id || null,
              created_by: current_user.id || null,
              values: values,
              hub: hub,
              delivery: addIdWithCode(values.pallets[i].packages[a])
            })
            if (errorCourse) {
              showError("order_create_error", strings, null, errorCourse)
              yield put(OrdersActions.createOrderFailure())
              yield put(LoaderActions.loaded())
              return;
            }
            const newTasks = resultCourse.insert_courses.returning[0].tasks
            const taskDepot = newTasks.filter((v) => v.type === "depot")[0]
            const taskDelivery = newTasks.filter((v) => v.type === "livraison")[0]
            const taskPickup = newTasks.filter((v) => v.type === "collecte")[0]
            tasksCreated.push({depot: taskDepot, delivery: taskDelivery, pickup: taskPickup})
            packagesData.push({
              ...values.pallets[i].packages[a],
              uid: uuidv4(),
              delivery_status_id: status_in_delivery.id,
              task_id: taskDepot.id,
              pickup_id: taskPickup.id,
              delivery_id: taskDelivery.id
            })
          } else {
            packagesData.push({
              ...values.pallets[i].packages[a],
              uid: uuidv4(),
              delivery_status_id: status_in_delivery.id,
              task_id: sameTaskArray[0].depot.id,
              pickup_id: sameTaskArray[0].pickup.id,
              delivery_id: sameTaskArray[0].delivery.id
            })
          }
        }
        palletsData.push({
          uid: uuidv4(),
          delivery_status_id: status_in_delivery.id,
          packages: packagesData
        })
      }
    }

    let packagesData = []
    for (let a = 0, le = values.packages.length; a < le ; a++) {
      let sameTaskArray = getSameTask(values.packages[a], tasksCreated)
      if (sameTaskArray.length === 0) {
        const res = yield generateIdentifier()
        const [errorCourse, resultCourse] = yield call(OrdersService.createCourse, {
          identifier: res || null,
          order_id: resultOrder.insert_orders.returning[0].id,
          merchant_id: values.merchant_id || null,
          created_by: current_user.id || null,
          values: values,
          hub: hub,
          delivery: addIdWithCode(values.packages[a])
        })
        if (errorCourse) {
          showError("order_create_error", strings, null, errorCourse)
          yield put(OrdersActions.createOrderFailure())
          yield put(LoaderActions.loaded())
          return;
        }
        const newTasks = resultCourse.insert_courses.returning[0].tasks
        const taskDepot = newTasks.filter((v) => v.type === "depot")[0]
        const taskDelivery = newTasks.filter((v) => v.type === "livraison")[0]
        const taskPickup = newTasks.filter((v) => v.type === "collecte")[0]
        tasksCreated.push({depot: taskDepot, delivery: taskDelivery, pickup: taskPickup})
        packagesData.push({
          ...values.packages[a],
          uid: uuidv4(),
          delivery_status_id: status_in_delivery.id,
          task_id: taskDepot.id,
          pickup_id: taskPickup.id,
          delivery_id: taskDelivery.id
        })
      } else {
        packagesData.push({
          ...values.packages[a],
          uid: uuidv4(),
          delivery_status_id: status_in_delivery.id,
          task_id: sameTaskArray[0].depot.id,
          pickup_id: sameTaskArray[0].pickup.id,
          delivery_id: sameTaskArray[0].delivery.id
        })
      }
    }

    if (palletsData && palletsData.length) {
      const [errorPallets, resultPallets] = yield call(OrdersService.createPallets, palletsData)
      if (errorPallets) {
        showError("order_create_error", strings, null, errorPallets)
        yield put(OrdersActions.createOrderFailure())
        yield put(LoaderActions.loaded())
        return
      }
    }
    if (packagesData && packagesData.length) {
      const [errorPackage, resultPackage] = yield call(OrdersService.createPackages, packagesData)
      if (errorPackage) {
        showError("order_create_error", strings, null, errorPackage)
        yield put(OrdersActions.createOrderFailure())
        yield put(LoaderActions.loaded())
        return
      }
    }
  }
  if (callback) {callback()}
  showSuccess("order_create_success", strings)
  yield put(LoaderActions.loaded())
}

function* deleteOrderRequest({values}) {
  yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  let idsPallets = []
  if (values.pallets.length) {
    values.pallets.forEach((v) => {
      idsPallets.push(v.id)
    })
  }
  const [error, result] = yield call(OrdersService.deleteOrder, {order_id: values.id, idsPallets})
  if (result) {
    showSuccess("order_delete_success", strings)
    yield put(OrdersActions.deleteOrderSuccess(result.delete_orders.returning[0]))
    yield put(LoaderActions.loaded())
  } else {
    showError("order_delete_error", strings, null, error)
    yield put(OrdersActions.deleteOrderFailure())
    yield put(LoaderActions.loaded())
  }
}

function getSameTask(task, array) {
  let res = array.filter(d => {
    const v = d.delivery
    if (v.address.value === task.address.value &&
        ((!task.company || task.company.length === 0) || v.company === task.company) &&
        ((!task.name || task.name.length === 0) || v.name === task.name) &&
        ((!task.phone || task.phone.length === 0) || v.phone === task.phone) &&
        (!task.end || moment(v.end_date).format() === moment(task.date).set("hour", task.end.get("hour")).set("minute", task.end.get("minute")).format()) &&
        (!task.start || moment(v.start_date).format() === moment(task.date).set("hour", task.start.get("hour")).set("minute", task.start.get("minute")).format()) &&
        ((!task.notes || task.notes.length === 0) || v.notes === task.notes) &&
        ((!task.nb_bon || task.nb_bon.length === 0) || v.nb_bon === task.nb_bon)) {
      return true
    } else {
      return false
    }
  })
  return res.length > 0 ? res : []
}

function checkDeleteCourse(values) {
  let countPackageCourse = []
  let result = []
  values.forEach(({task, course, pack}) => {
    course.tasks.forEach((taskData) => {
      if (taskData.type === "depot") {
        taskData.packages.forEach((packData) => {
          if (packData.id === pack.id) {
            if (countPackageCourse[taskData.id]) {
              countPackageCourse[taskData.id] = {...countPackageCourse[taskData.id], package_id_delete: [...countPackageCourse[taskData.id].package_id_delete, pack.id]}
            } else {
              countPackageCourse[taskData.id] = {length_package: taskData.packages.length, package_id_delete: [pack.id], course_id: course.id}
            }
          }
        })
      }
    })
  })
  countPackageCourse.forEach((countData) => {
    if (countData.length_package === countData.package_id_delete.length) {
      result.push({service: TasksService.deleteCourseWithTasks, id: countData.course_id})
    }
  })
  return result
}

function* updateOrderRequest({values, hub, callback}) {
  yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const { current_user } = yield select(state => state.user);
  const strings = getTranslate(locale)
  let allPackages = []
  if (values.pallets.length > 0) {
    values.pallets.filter(b => !b.delete).forEach(async v => {
      v.packages.filter(b => !b.delete && b.id).forEach(a => {
        allPackages.push({depot: a.depot, delivery: a.delivery, pickup: a.pickup})
      })
    })
  }
  allPackages = [...allPackages, ...values.packages.filter(b => !b.delete && b.id).map((a) => ({depot: a.depot, delivery: a.delivery, pickup: a.pickup}))]

  const [error, result] = yield call(OrdersService.getDeliveryStatus)
  let status_in_delivery = result.delivery_status.filter(v => v.status === "in_delivery")[0]
  if (result) {
    const palletsData = []
    const tasksCreated = []
    let deleteGenericData = []
    const mutationGenericData = []
    const taskToCheck = []

    if (values.pallets.length > 0) {
      for (let i = 0, l = values.pallets.length; i < l; i++) {
        let packagesData = []
        // Si on doit supprimer la pallet
        if (values.pallets[i].delete) {
          for (let a = 0, le = values.pallets[i].packages.length; a < le; a++) {
            taskToCheck.push({task: values.pallets[i].packages[a].task, course: values.pallets[i].packages[a].course, pack: values.pallets[i].packages[a]})
            deleteGenericData.push({service: OrdersService.deletePackage, id: values.pallets[i].packages[a].id})
          }
          deleteGenericData.push({service: OrdersService.deletePallet, id: values.pallets[i].id})
        } else {
          for (let a = 0, le = values.pallets[i].packages.length; a < le; a++) {
            if (values.pallets[i].packages[a].delete) {
              taskToCheck.push({task: values.pallets[i].packages[a].task, course: values.pallets[i].packages[a].course, pack: values.pallets[i].packages[a]})
              deleteGenericData.push({service: OrdersService.deletePackage, id: values.pallets[i].packages[a].id})
            } else if (values.pallets[i].packages[a].id) {
              mutationGenericData.push({service: OrdersService.updatePackage, data: values.pallets[i].packages[a]})
              mutationGenericData.push({service: TasksService.updateTask, data: {
                  ...addIdWithCode(values.pallets[i].packages[a]),
                  start_date: values.pallets[i].packages[a].date && values.pallets[i].packages[a].start ? moment(values.pallets[i].packages[a].date).set("hour", values.pallets[i].packages[a].start.get("hour")).set("minute", values.pallets[i].packages[a].start.get("minute")).format() : null,
                  end_date: values.pallets[i].packages[a].date && values.pallets[i].packages[a].end ? moment(values.pallets[i].packages[a].date).set("hour", values.pallets[i].packages[a].end.get("hour")).set("minute", values.pallets[i].packages[a].end.get("minute")).format() : null,
                }})
            } else {
              let sameTaskArray = getSameTask(values.pallets[i].packages[a], [...tasksCreated, ...allPackages])
              if (sameTaskArray.length === 0) {
                const res = yield generateIdentifier()
                const [errorCourse, resultCourse] = yield call(OrdersService.createCourse, {
                  identifier: res || null,
                  order_id: values.id,
                  merchant_id: current_user.merchant_id || null,
                  created_by: current_user.id || null,
                  values: values,
                  hub: hub,
                  delivery: addIdWithCode(values.pallets[i].packages[a])
                })
                if (errorCourse) {
                  showError("order_update_error", strings, null, errorCourse)
                  yield put(OrdersActions.createOrderFailure())
                  yield put(LoaderActions.loaded())
                  return;
                }
                const newTasks = resultCourse.insert_courses.returning[0].tasks
                const taskDepot = newTasks.filter((v) => v.type === "depot")[0]
                const taskDelivery = newTasks.filter((v) => v.type === "livraison")[0]
                const taskPickup = newTasks.filter((v) => v.type === "collecte")[0]
                tasksCreated.push({depot: taskDepot, delivery: taskDelivery, pickup: taskPickup})
                if (values.pallets[i].id) {
                  mutationGenericData.push({service: OrdersService.createPackage, data: {
                      ...values.pallets[i].packages[a],
                      uid: uuidv4(),
                      pallet_id: values.pallets[i].id,
                      delivery_status_id: status_in_delivery.id,
                      task_id: taskDepot.id,
                      pickup_id: taskPickup.id,
                      delivery_id: taskDelivery.id
                    }})
                } else {
                  packagesData.push({
                    ...values.pallets[i].packages[a],
                    uid: uuidv4(),
                    delivery_status_id: status_in_delivery.id,
                    task_id: taskDepot.id,
                    pickup_id: taskPickup.id,
                    delivery_id: taskDelivery.id
                  })
                }
              } else {
                if (values.pallets[i].id) {
                  mutationGenericData.push({service: OrdersService.createPackage, data: {
                      ...values.pallets[i].packages[a],
                      pallet_id: values.pallets[i].id,
                      uid: uuidv4(),
                      delivery_status_id: status_in_delivery.id,
                      task_id: sameTaskArray[0].depot.id,
                      pickup_id: sameTaskArray[0].pickup.id,
                      delivery_id: sameTaskArray[0].delivery.id
                    }})
                } else {
                  packagesData.push({
                    ...values.pallets[i].packages[a],
                    uid: uuidv4(),
                    delivery_status_id: status_in_delivery.id,
                    task_id: sameTaskArray[0].depot.id,
                    pickup_id: sameTaskArray[0].pickup.id,
                    delivery_id: sameTaskArray[0].delivery.id
                  })
                }
              }
              if (packagesData.length > 0) {
                palletsData.push({
                  uid: uuidv4(),
                  delivery_status_id: status_in_delivery.id,
                  packages: packagesData
                })
              }
            }
          }
        }
      }
    }

    if (palletsData.length > 0) {
      mutationGenericData.push({service: OrdersService.createPallets, data: palletsData})
    }

    for (let a = 0, le = values.packages.length; a < le ; a++) {
      if (values.packages[a].delete) {
        taskToCheck.push({task: values.packages[a].task, course: values.packages[a].course, pack: values.packages[a]})
        deleteGenericData.push({service: OrdersService.deletePackage, id: values.packages[a].id})
      } else if (values.packages[a].id) {
        mutationGenericData.push({service: OrdersService.updatePackage, data: values.packages[a]})
        mutationGenericData.push({service: TasksService.updateTask, data: addIdWithCode(values.packages[a])})
      } else {
        let sameTaskArray = getSameTask(values.packages[a], [...tasksCreated, ...allPackages])
        if (sameTaskArray.length === 0) {
          const res = yield generateIdentifier()
          const [errorCourse, resultCourse] = yield call(OrdersService.createCourse, {
            identifier: res || null,
            order_id: values.id,
            merchant_id: current_user.merchant_id || null,
            created_by: current_user.id || null,
            values: values,
            hub: hub,
            delivery: values.packages[a]
          })
          if (errorCourse) {
            showError("order_update_error", strings, null, errorCourse)
            yield put(OrdersActions.createOrderFailure())
            yield put(LoaderActions.loaded())
            return;
          }
          const newTasks = resultCourse.insert_courses.returning[0].tasks
          const taskDepot = newTasks.filter((v) => v.type === "depot")[0]
          const taskDelivery = newTasks.filter((v) => v.type === "livraison")[0]
          const taskPickup = newTasks.filter((v) => v.type === "collecte")[0]
          tasksCreated.push({depot: taskDepot, delivery: taskDelivery, pickup: taskPickup})
          mutationGenericData.push({service: OrdersService.createPackage, data: {
              ...values.packages[a],
              uid: uuidv4(),
              delivery_status_id: status_in_delivery.id,
              task_id: taskDepot.id,
              pickup_id: taskPickup.id,
              delivery_id: taskDelivery.id
            }})
        } else {
          mutationGenericData.push({service: OrdersService.createPackage, data: {
              ...values.packages[a],
              uid: uuidv4(),
              delivery_status_id: status_in_delivery.id,
              task_id: sameTaskArray[0].depot.id,
              pickup_id: sameTaskArray[0].pickup.id,
              delivery_id: sameTaskArray[0].delivery.id
            }})
        }
      }
    }
    mutationGenericData.push({service:  OrdersService.updateOrder, data: {
        id: values.id,
        hub_id: hub.id,
        start_date: values.date && values.start ? moment(values.date).set("hour", values.start.get("hour")).set("minute", values.start.get("minute")).format() : null,
        end_date: values.date && values.end ? moment(values.date).set("hour", values.start.get("hour")).set("minute", values.start.get("minute")).format() : null
      }})
    deleteGenericData = [...deleteGenericData, ...checkDeleteCourse(taskToCheck)]

    const responses = yield all([
      ...mutationGenericData.map(data => call(data.service, data.data)),
      ...deleteGenericData.map(data => call(data.service, data.id)),
    ]);
    if (responses.filter((v) => v[0]).length !== 0) {
      showError("order_update_error", strings, null, error)
      yield put(OrdersActions.updateOrderFailure())
      yield put(LoaderActions.loaded())
    } else {
      if (callback) {callback()}
      showSuccess("order_update_success", strings)
      yield put(OrdersActions.updateOrderSuccess(responses.filter((v) => 'update_orders' in v[1])[0][1].update_orders.returning[0]))
      yield put(LoaderActions.loaded())
    }
  } else {
    showError("order_update_error", strings)
    yield put(OrdersActions.updateOrderFailure())
    yield put(LoaderActions.loaded())
  }
}

export default [
  takeLatest(types.CREATE_ORDER_REQUEST, createOrderRequest),
  takeLatest(types.DELETE_ORDER_O_REQUEST, deleteOrderRequest),
  takeLatest(types.UPDATE_ORDER_REQUEST, updateOrderRequest),
  takeLatest(types.GET_ORDERS_REQUEST, getOrdersRequest),
  takeLatest(types.GET_DELIVERY_STATUS_REQUEST, getDeliveryStatusRequest),
];
