import { createAction } from "../../utils";

// Types
export const types = {
  GET_PRICING_REQUEST: "GET_PRICING_REQUEST",
  GET_PRICING_SUCCESS: "GET_PRICING_SUCCESS",
  GET_PRICING_FAILURE: "GET_PRICING_FAILURE",

  UPDATE_PRICING_REQUEST: "UPDATE_PRICING_REQUEST",
  UPDATE_PRICING_SUCCESS: "UPDATE_PRICING_SUCCESS",
  UPDATE_PRICING_FAILURE: "UPDATE_PRICING_FAILURE",

  GET_ZONE_PRICING_REQUEST: "GET_ZONE_PRICING_REQUEST",
  GET_ZONE_PRICING_SUCCESS: "GET_ZONE_PRICING_SUCCESS",
  GET_ZONE_PRICING_FAILURE: "GET_ZONE_PRICING_FAILURE",

  UPDATE_ZONE_PRICING_REQUEST: "UPDATE_ZONE_PRICING_REQUEST",
  UPDATE_ZONE_PRICING_SUCCESS: "UPDATE_ZONE_PRICING_SUCCESS",
  UPDATE_ZONE_PRICING_FAILURE: "UPDATE_ZONE_PRICING_FAILURE"
};

// Actions
export default {
  getPricingRequest: () => createAction(types.GET_PRICING_REQUEST, {}),
  getPricingSuccess: (data) =>
    createAction(types.GET_PRICING_SUCCESS, { data }),
  getPricingFailure: () => createAction(types.GET_PRICING_FAILURE),

  updatePricingRequest: (newPricingValuesForm) =>
    createAction(types.UPDATE_PRICING_REQUEST, { newPricingValuesForm }),
  updatePricingSuccess: (data) =>
    createAction(types.UPDATE_PRICING_SUCCESS, { data }),
  updatePricingFailure: () => createAction(types.UPDATE_PRICING_FAILURE),

  getZonePricingRequest: () => createAction(types.GET_ZONE_PRICING_REQUEST),
  getZonePricingSuccess: (data) =>
      createAction(types.GET_ZONE_PRICING_SUCCESS, { data }),
  getZonePricingFailure: () => createAction(types.GET_ZONE_PRICING_FAILURE),

  updateZonePricingRequest: (newZonePricing, id, pricing) =>
      createAction(types.UPDATE_ZONE_PRICING_REQUEST, { newZonePricing, id, pricing }),
  updateZonePricingSuccess: (data) =>
      createAction(types.UPDATE_ZONE_PRICING_SUCCESS, { data }),
  updateZonePricingFailure: () => createAction(types.UPDATE_ZONE_PRICING_FAILURE),
};
