import {gql} from "apollo-boost";
import moment from "moment-timezone";
import {authenticatedMutation, authenticatedQuery} from "./middleware";

const RETURNING_PACKAGE_TYPE = `
    id
    created_at
    updated_at
    weight
    volumn
    name
    desc
    icon
    icon_fa
    is_admin
    created_at
    updated_at
    merchant_id
    team_id
`;
/**
 * GRAPHQL QUERIES
 */
const queries = {
    getPackageKinds: `package_kinds {
      id
      kind
    }`,
    packageTypes: `package_types(where: {_or: [{is_admin: {_eq: true}}, {icon: {_eq: "other"}}]}) {
        ${RETURNING_PACKAGE_TYPE}
    }`,
    packageTypesTeam: `package_types_team {
      id
      created_at
      updated_at
      team_id
      package_types_id
  }`,
    packageTypesByMerchantId: gql`
    query package_types($where: package_types_bool_exp!) {
      package_types(where: $where) {
        ${RETURNING_PACKAGE_TYPE}
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
    updatePackage: gql`
    mutation update_package($id: Int!, $package: packages_set_input) {
      update_packages_by_pk(pk_columns: { id: $id }, _set: $package) {
        id
        pickup_id
        delivery_id
        uid
        weight
        declared_value
        number
        volumn
        fragile
        kind_id
        package_type_id
      }
    }
  `,
    createDefaultPackage: gql`
    mutation insert_default_package_one(
      $defaultPackage: default_package_insert_input!
    ) {
      insert_default_package_one(object: $defaultPackage) {
        merchant_id
        recipient_id
        package_id
        package {
          id
          fragile
          is_default
          kind_id
          declared_value
          package_type_id
          volumn
          weight
        }
      }
    }
  `,
    updatePackageTypesAdmin: gql`
    mutation update_package_type_receiver(
      $merchantId: Int!
      $packages: [package_type_receiver_insert_input!]!
    ) {
      delete_package_type_receiver(
        where: { receiver_merchant_id: { _eq: $merchantId }, package_type: {is_admin: {_eq: true}} }
      ) {
        returning {
          package_type_id
        }
      }
      insert_package_type_receiver(objects: $packages) {
        returning {
          package_type {
            ${RETURNING_PACKAGE_TYPE}
          }
          receiver_recipient_id
          package_type_id
          receiver_merchant_id
        }
      }
    }
  `,
    createPackageType: gql`
    mutation insert_package_types_one(
      $packageType: package_types_insert_input!
    ) {
    insert_package_types_one(object: $packageType)
    {
      ${RETURNING_PACKAGE_TYPE}
    }
  }`,
    updatePackageType: gql`
    mutation update_package_types_by_pk(
      $id: Int!
      $packageType: package_types_set_input!
    ) {
      update_package_types_by_pk(pk_columns: { id: $id }, _set: $packageType) {
        ${RETURNING_PACKAGE_TYPE}
      }
    }
  `,
    deletePackageType: gql`
    mutation update_package_types(
      $ids: [Int!]
      $packageType: package_types_set_input!
    ) {
      update_package_types(where: { id: { _in: $ids } }, _set: $packageType) {
        returning {
          id
        }
      }
    }
  `,
    modifyPackageTypesTeam: (data, team_id) => `mutation {
      delete_1: delete_package_types_team(where: {team_id: {_eq: ${team_id}}}) {
        returning {
          package_type {
            id
          }
          id
        }
      }
      delete_2: delete_package_types_team(where: {package_types_id: {_in: [${data
        .map((l) => l.package_types_id)
        .join(",")}]}}) {
        returning {
          package_type {
            id
          }
          id
        }
      }
      insert_package_types_team(objects: [${data
        .map(
            (l) =>
                `{team_id: ${l.team_id}, package_types_id: ${l.package_types_id} }`
        )
        .join(",")}]) {
        returning {
          package_type {
            id
          }
          id
        }
      }
    }
    `,
    createPackage: gql`mutation create_packages($packages: [packages_insert_input!]!) {
    insert_packages(objects: $packages)
    {
      returning {
        id
        pickup_id
        delivery_id
        uid
        weight
        declared_value
        number
        volumn
        fragile
        kind_id
        package_type_id
      }
    }
  }`,
  updatePackages: gql`
    mutation update_packages($id: Int!, $package: packages_set_input!) {
      update_packages(where: { id: { _eq: $id } }, _set: $package) {
        returning {
          id
          pickup_id
          delivery_id
          uid
          weight
          declared_value
          number
          volumn
          fragile
          kind_id
          package_type_id
        }
      }
    }
  `,
    deletePackages: gql`
    mutation delete_packages($packagesIds: [Int!]!) {
      delete_packages(where: { id: { _in: $packagesIds } }) {
        returning {
          id
          pickup_id
          delivery_id
          uid
          weight
          declared_value
          number
          volumn
          fragile
          kind_id
          package_type_id
        }
      }
    }
  `,
};

export default {
    getPackageKinds: () => authenticatedQuery(queries.getPackageKinds),
    updatePackageTypesAdmin: (values) =>
        authenticatedMutation(mutations.updatePackageTypesAdmin, values),
    packageTypes: () => authenticatedQuery(queries.packageTypes),
    packageTypesTeam: () => authenticatedQuery(queries.packageTypesTeam),
    packageTypesByMerchantId: (where) =>
        authenticatedQuery(queries.packageTypesByMerchantId, {where}),
    deletePackages: (packagesIds) =>
        authenticatedMutation(mutations.deletePackages, {packagesIds}),
    updatePackages: ({id, data}) =>
        authenticatedMutation(mutations.updatePackages, {id, package: data}),
    createPackage: ({packages}) => authenticatedMutation(mutations.createPackage, {packages}),
    createPackageType: (values) =>
        authenticatedMutation(mutations.createPackageType, {packageType: values}),
    updatePackageType: ({values, id}) =>
        authenticatedMutation(mutations.updatePackageType, {packageType: values, id}),
    deletePackageType: (ids) =>
        authenticatedMutation(mutations.deletePackageType, {
            ids,
            packageType: {deleted_at: moment().format()},
        }),
    updatePackage: (values) => {
        return authenticatedMutation(mutations.updatePackage, values);
    },
    createDefaultPackage: (values) =>
        authenticatedMutation(mutations.createDefaultPackage, values),
    modifyPackageTypesTeam: ({data, teamID}) =>
        authenticatedMutation(mutations.modifyPackageTypesTeam(data, teamID)),
};
