import { createSelector } from "reselect";

export const selectPackageType = createSelector(
  (state) => state.packageType || {},
  (packageType) => {
    return packageType;
  }
);

export const selectMergePackageTypes = createSelector(
  (state) => ({
    packageTypes: Object.values(state?.packageType?.packageTypes || {}),
    packageTypesTeam: Object.values(state?.packageType?.packageTypesTeam || {}),
  }),
  ({ packageTypes, packageTypesTeam }) => {
    const tmp = {};
    packageTypes?.forEach((p) => {
      tmp[p.id] = p;
    });
    packageTypesTeam?.forEach((p) => {
      tmp[p.id] = p;
    });
    return tmp;
  }
);

export const selectPackageTypeByMerchant = createSelector(
  (state) => ({
    packageTypesMerchant: Object.values(
      state?.packageType?.packageTypesMerchant || {}
    ),
    packageTypes: Object.values(state?.packageType?.packageTypes || {}),
  }),
  ({ packageTypes, packageTypesMerchant }) => {
    return {
      allPackageTypes: [...packageTypes, ...packageTypesMerchant],
      packageTypes,
      packageTypesMerchant,
    };
  }
);

export const selectPackageTypeByMerchantById = createSelector(
  (state) => Object.values(state?.packageType?.packageTypesMerchant || {}),
  (packageType) => {
    const tmp = {};
    packageType.forEach((v) => (tmp[v.id] = v));
    return tmp;
  }
);
