import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  zonePricing: [],
  pricing: {
    "team_id": null,
    "isAdmin": null,
    "base": null,
    "overcost": {
      "enveloppe": null,
      "box": null,
      "big-box": null,
      "other": "devis",
      "fragile": null,
      "no-fragile": null,
      "no-alimentaire": null,
      "sec": null,
      "frais": null,
      "surgele": null,
      "restreint": null,
      "moins-une-heure":null
    },
    "discount": {
      "other-days": null
    }
  }
};

const getPricingSuccess = (state = initialState, action) => {
  const { data } = action
  return {...state, pricing: data};
};

const getPricingFailure = (state = initialState) => {
  return {...state};
};

const getZonePricingSuccess = (state = initialState, action) => {
  const { data } = action
  const tmp = []
  data.forEach((val) => {
    tmp[val.id] = val
  })
  return {...state, zonePricing: tmp};
};

const getZonePricingFailure = (state = initialState) => {
  return {...state};
};

const updatePricingSuccess = (state = initialState, action) => {
  const { data } = action
  return {...state, pricing: data};
};

const updatePricingFailure = (state = initialState) => {
  return {...state};
};

const updateZonePricingSuccess = (state = initialState, action) => {
  const { data } = action
  const { zonePricing } = state
  zonePricing[data.id] = data
  return {...state, zonePricing: zonePricing};
};

const updateZonePricingFailure = (state = initialState) => {
  return {...state};
};

export default createReducer(initialState, {
  [types.GET_PRICING_SUCCESS]: getPricingSuccess,
  [types.GET_PRICING_FAILURE]: getPricingFailure,
  [types.GET_ZONE_PRICING_SUCCESS]: getZonePricingSuccess,
  [types.GET_ZONE_PRICING_FAILURE]: getZonePricingFailure,
  [types.UPDATE_PRICING_SUCCESS]: updatePricingSuccess,
  [types.UPDATE_PRICING_FAILURE]: updatePricingFailure,
  [types.UPDATE_ZONE_PRICING_SUCCESS]: updateZonePricingSuccess,
  [types.UPDATE_ZONE_PRICING_FAILURE]: updateZonePricingFailure,
});
