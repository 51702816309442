import { createReducer } from "reduxsauce";
import { types } from "./actions";

const initialState = {
  tasks: [],
  moreCourses: {},
  filter: {
    withoutDate: true,
    toProcess: false,
    date: null,
    state: null,
    team_id: null,
    merchant_id: null,
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  },
  filterToTreat: {
    withoutDate: true,
    toProcess: false,
    date: null,
    state: null,
    team_id: null,
    merchant_id: null,
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  },
  filterDevis: {
    withoutDate: true,
    toProcess: false,
    date: null,
    state: null,
    team_id: null,
    merchant_id: null,
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  },
  courses: {},
  coursesDevis: {},
  coursesToTreat: {},
  loading: true,
  current_course: {},
  stats: {},
  activeList: "1",
};

const getStatisticsCoursesSuccess = (state = initialState, action) => {
  const { data } = action;
  return { ...state, stats: data };
};

const updateActiveList = (state = initialState, action) => {
  const { data } = action;
  return { ...state, activeList: data };
};

const getCoursestoTreatSuccess = (state = initialState, action) => {
  const { data } = action;
  const tmp = {};
  data.forEach((p) => {
    if (p) tmp[p.id] = p;
  });
  return { ...state, coursesToTreat: tmp, loading: false };
};

const getCoursesSuccess = (state = initialState, action) => {
  const { data } = action;
  const tmp = {};
  data.forEach((p) => {
    if (p) tmp[p.id] = p;
  });
  return { ...state, courses: tmp, loading: false };
};

const getCoursesDevisSuccess = (state = initialState, action) => {
  const { data } = action;
  const tmp = {};
  data.forEach((p) => {
    if (p) tmp[p.id] = p;
  });
  return { ...state, coursesDevis: tmp, loading: false };
};

const getCourseSuccess = (state = initialState, action) => {
  const { data } = action;

  return { ...state, current_course: data, loading: false };
};

const createCourseSuccess = (state = initialState, action) => {
  const { data } = action;
  const tmp = state.courses;
  tmp[data.id] = data;
  return { ...state, courses: tmp, loading: false };
};

const deleteOrderSuccess = (state = initialState, action) => {
  const { ids } = action;
  const { tasks } = state;
  for (let i = 0, l = ids.length; i < l; i++) {
    delete tasks[ids[i]];
  }
  return { ...state, tasks: tasks, loading: false };
};

const deleteCourseSuccess = (state = initialState, action) => {
  const { course } = action;
  const { courses } = state;
  let tmp = courses ? courses.filter((v) => v.id !== course.id) : [];
  let tmpDic = {};
  tmp.forEach((p) => {
    if (p) tmpDic[p.id] = p;
  });
  return { ...state, courses: tmpDic, loading: false };
};

const deleteTaskSuccess = (state = initialState, action) => {
  const { courses } = state;
  const { data, deleteCourseId } = action;
  let tmp = courses;
  //if (deleteCourseId) {
  //  tmp = tmp.filter((p) => p.id !== deleteCourseId)
  //} else {
  //  tmp = tmp.map(p => {
  //    p.tasks = p.tasks.filter((v) => v.id !== data.id)
  //    return p
  //  })
  //}
  return { ...state, courses: tmp, loading: false };
};

const getFailed = (state = initialState) => {
  return { ...state };
};

const resetTasksReduc = (state = initialState) => {
  return { ...state, loading: false };
};

const tasksFailed = (state = initialState) => {
  return { ...state, loading: false };
};

const resetCurrentCourse = (state = initialState) => {
  return { ...state, current_course: {} };
};

const modifyTaskSuccess = (state = initialState, action) => {
  const { data } = action;
  const { courses } = state;
  const tmp = [];
  Object.values(courses).forEach((course) => {
    if (course) {
      const tmpCourse = course;
      tmpCourse.tasks = course.tasks.map((task) => {
        if (data.id === task.id) {
          return {
            ...task,
            task: data,
          };
        }
        return task;
      });
      tmp[course.id] = tmpCourse;
    }
  });
  return { ...state, courses: tmp };
};

const modifyCourseSuccess = (state = initialState, action) => {
  const { data } = action;
  const { courses, moreCourses } = state;
  const tmp = courses;
  tmp[data.id] = data;
  if (moreCourses[data.id]) {
    moreCourses[data.id] = data;
  }
  return {
    ...state,
    courses: tmp,
    moreCourses: moreCourses,
  };
};

const updateCoursesOrderSuccess = (state = initialState, action) => {
  const { data } = action;
  const { courses } = state;
  const tmp = courses;
  data.forEach((v) => {
    tmp[v.id] = {
      ...tmp[v.id],
      order_admin: v.order,
    };
  });
  return { ...state, courses: tmp };
};

const updateCoursesStateReduc = (state = initialState, action) => {
  const { courses } = action;
  const tmp = state.courses;
  courses.forEach((v) => {
    tmp[v.id] = { ...tmp[v.id], ...v };
  });
  return { ...state, courses: tmp };
};

const generatePdfTaskSuccess = (state = initialState) => {
  return { ...state };
};

const updateFiltersReduc = (state = initialState, action) => {
  const { filters } = action;
  return {
    ...state,
    filter: { ...state.filter, ...filters },
    loading: false,
  };
};

const updateFiltersToTreatReduc = (state = initialState, action) => {
  const { filterToTreat } = action;
  return {
    ...state,
    filterToTreat: filterToTreat,
    loading: false,
  };
};

const updateFiltersDevisReduc = (state = initialState, action) => {
  const { filterDevis } = action;
  return {
    ...state,
    filterDevis: filterDevis,
    loading: false,
  };
};

const getMoreCoursesSuccess = (state = initialState, action) => {
  const { data } = action;
  const tmp = {};

  data.forEach((p) => {
    if (p) tmp[p.id] = p;
  });
  return { ...state, moreCourses: tmp, loading: false };
};

export default createReducer(initialState, {
  [types.RESET_CURRENT_COURSE_REDUC]: resetCurrentCourse,
  [types.UPDATE_COURSES_STATE_REDUC]: updateCoursesStateReduc,
  [types.RESET_TASKS_REDUC]: resetTasksReduc,
  [types.GET_COURSES_FAILURE]: getFailed,
  [types.GET_COURSES_SUCCESS]: getCoursesSuccess,
  [types.GET_COURSES_DEVIS_FAILURE]: getFailed,
  [types.GET_COURSES_DEVIS_SUCCESS]: getCoursesDevisSuccess,
  [types.GET_COURSE_FAILURE]: getFailed,
  [types.GET_COURSE_SUCCESS]: getCourseSuccess,
  [types.DELETE_ORDER_SUCCESS]: deleteOrderSuccess,
  [types.CREATE_COURSE_FAILURE]: tasksFailed,
  [types.CREATE_COURSE_SUCCESS]: createCourseSuccess,
  [types.DELETE_COURSE_SUCCESS]: deleteCourseSuccess,
  [types.DELETE_TASK_FAILURE]: getFailed,
  [types.DELETE_TASK_SUCCESS]: deleteTaskSuccess,
  [types.MODIFY_TASK_SUCCESS]: modifyTaskSuccess,
  [types.MODIFY_TASK_FAILURE]: tasksFailed,
  [types.UPDATE_COURSES_ORDER_FAILURE]: tasksFailed,
  [types.UPDATE_COURSES_ORDER_SUCCESS]: updateCoursesOrderSuccess,
  [types.MODIFY_COURSE_FAILURE]: tasksFailed,
  [types.MODIFY_COURSE_SUCCESS]: modifyCourseSuccess,
  [types.GENERATE_PDF_TASK_FAILURE]: tasksFailed,
  [types.GENERATE_PDF_TASK_SUCCESS]: generatePdfTaskSuccess,
  [types.UPDATE_FILTERS_REDUC]: updateFiltersReduc,
  [types.UPDATE_FILTERS_TO_TREAT_REDUC]: updateFiltersToTreatReduc,
  [types.UPDATE_FILTERS_DEVIS_REDUC]: updateFiltersDevisReduc,
  [types.GET_MORE_COURSES_FAILURE]: getFailed,
  [types.GET_MORE_COURSES_SUCCESS]: getMoreCoursesSuccess,
  [types.GET_COURSES_TO_TREAT_FAILURE]: getFailed,
  [types.GET_COURSES_TO_TREAT_SUCCESS]: getCoursestoTreatSuccess,
  [types.GET_STATISTICS_COURSES_FAILURE]: getFailed,
  [types.GET_STATISTICS_COURSES_SUCCESS]: getStatisticsCoursesSuccess,
  [types.UPDATE_ACTIVE_LIST]: updateActiveList,
});
