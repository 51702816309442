import { createAction } from '../../utils';

// Types
export const types = {
  RESET_RECIPIENTS_REDUC: "RESET_RECIPIENTS_REDUC",

  GET_RECIPIENTS_REQUEST: "GET_RECIPIENTS_REQUEST",
  GET_RECIPIENTS_SUCCESS: "GET_RECIPIENTS_SUCCESS",
  GET_RECIPIENTS_FAILURE: "GET_RECIPIENTS_FAILURE",

  GET_RECIPIENT_REQUEST: "GET_RECIPIENT_REQUEST",
  GET_RECIPIENT_SUCCESS: "GET_RECIPIENT_SUCCESS",
  GET_RECIPIENT_FAILURE: "GET_RECIPIENT_FAILURE",

  RECIPIENTS_CREATE_REQUEST: "RECIPIENTS_CREATE_REQUEST",
  RECIPIENTS_CREATE_SUCCESS: "RECIPIENTS_CREATE_SUCCESS",
  RECIPIENTS_CREATE_FAILURE: "RECIPIENTS_CREATE_FAILURE",

  RECIPIENTS_UPDATE_REQUEST: "RECIPIENTS_UPDATE_REQUEST",
  RECIPIENTS_UPDATE_SUCCESS: "RECIPIENTS_UPDATE_SUCCESS",
  RECIPIENTS_UPDATE_FAILURE: "RECIPIENTS_UPDATE_FAILURE",

  RECIPIENT_DELETE_REQUEST: "RECIPIENT_DELETE_REQUEST",
  RECIPIENT_DELETE_SUCCESS: "RECIPIENT_DELETE_SUCCESS",
  RECIPIENT_DELETE_FAILURE: "RECIPIENT_DELETE_FAILURE",

  UPDATE_CURRENT_RECIPIENT_SUCCESS: "UPDATE_CURRENT_RECIPIENT_SUCCESS",

  RESET_CURRENT_RECIPIENT: 'RESET_CURRENT_RECIPIENT',

  SET_CURRENT_TAB_REDUC: 'SET_CURRENT_TAB_REDUC',

  GET_BENEFICIARIES_REQUEST: 'GET_BENEFICIARIES_REQUEST',
  GET_BENEFICIARIES_SUCCESS: 'GET_BENEFICIARIES_SUCCESS',
  GET_BENEFICIARIES_FAILURE: 'GET_BENEFICIARIES_FAILURE',

  GET_RECIPIENTS_BY_MERCHANT_ID_REQUEST: 'GET_RECIPIENTS_BY_MERCHANT_ID_REQUEST',
  GET_RECIPIENTS_BY_MERCHANT_ID_SUCCESS: 'GET_RECIPIENTS_BY_MERCHANT_ID_SUCCESS',
  GET_RECIPIENTS_BY_MERCHANT_ID_FAILURE: 'GET_RECIPIENTS_BY_MERCHANT_ID_FAILURE',

  SET_RECIPIENT_UPDATE_DATA: 'SET_RECIPIENT_UPDATE_DATA',

  GET_MORE_RECIPIENTS_REQUEST: 'GET_MORE_RECIPIENTS_REQUEST',
  GET_MORE_RECIPIENTS_SUCCESS: 'GET_MORE_RECIPIENTS_SUCCESS',

  RECIPIENTS_DELETE_REQUEST: 'RECIPIENTS_DELETE_REQUEST',
  RECIPIENTS_DELETE_SUCCESS: 'RECIPIENTS_DELETE_SUCCESS'
};

// Actions
export default {
  resetCurrentRecipient: () =>
      createAction(types.RESET_CURRENT_RECIPIENT),

  setCurrentTabReduc: (tabKey) =>
      createAction(types.SET_CURRENT_TAB_REDUC, {tabKey}),

  resetRequestSuccessReduc: () =>
    createAction(types.RESET_RECIPIENTS_REDUC),

  setRecipientUpdateData: (data) =>
      createAction(types.SET_RECIPIENT_UPDATE_DATA, {data}),

  getBeneficiariesRequest: (contractor_id) =>
      createAction(types.GET_BENEFICIARIES_REQUEST, {contractor_id}),
  getBeneficiariesSuccess: (data) =>
      createAction(types.GET_BENEFICIARIES_SUCCESS, {data}),
  getBeneficiariesFailure: () =>
      createAction(types.GET_BENEFICIARIES_FAILURE),

  getMoreRecipientsRequest: (ids) =>
      createAction(types.GET_MORE_RECIPIENTS_REQUEST, {ids}),
  getMoreRecipientsSuccess: (data) =>
      createAction(types.GET_MORE_RECIPIENTS_SUCCESS, {data}),
  getRecipientsRequest: (created_by) =>
    createAction(types.GET_RECIPIENTS_REQUEST, {created_by}),
  getRecipientsSuccess: (data) =>
    createAction(types.GET_RECIPIENTS_SUCCESS, {data}),
  getRecipientsFailure: () =>
    createAction(types.GET_RECIPIENTS_FAILURE),

  getRecipientsByMerchantIDRequest: (merchant_id) =>
      createAction(types.GET_RECIPIENTS_BY_MERCHANT_ID_REQUEST, {merchant_id}),
  getRecipientsByMerchantIDSuccess: (data) =>
      createAction(types.GET_RECIPIENTS_BY_MERCHANT_ID_SUCCESS, {data}),
  getRecipientsByMerchantIDFailure: () =>
      createAction(types.GET_RECIPIENTS_BY_MERCHANT_ID_FAILURE),

  getRecipientRequest: (id) =>
      createAction(types.GET_RECIPIENT_REQUEST, {id}),
  getRecipientSuccess: (data) =>
      createAction(types.GET_RECIPIENT_SUCCESS, {data}),
  getRecipientFailure: () =>
      createAction(types.GET_RECIPIENT_FAILURE),

  recipientsCreateRequest: (values, isCurrent = false, callback = null) =>
    createAction(types.RECIPIENTS_CREATE_REQUEST, { values, isCurrent, callback }),
  recipientsCreateSuccess: (data) =>
    createAction(types.RECIPIENTS_CREATE_SUCCESS, {data}),
  recipientsCreateFailure: () =>
    createAction(types.RECIPIENTS_CREATE_FAILURE),

  recipientsUpdateRequest: (values, isCurrent = false, callback = null) =>
      createAction(types.RECIPIENTS_UPDATE_REQUEST, { values, isCurrent, callback }),
  recipientsUpdateSuccess: (data) =>
      createAction(types.RECIPIENTS_UPDATE_SUCCESS, {data}),
  recipientsUpdateFailure: () =>
      createAction(types.RECIPIENTS_UPDATE_FAILURE),

  recipientsUpdateCurrentRecipientSuccess: (data) =>
      createAction(types.UPDATE_CURRENT_RECIPIENT_SUCCESS, {data}),

  deleteRecipientsRequest: (data, callback = null) =>
      createAction(types.RECIPIENTS_DELETE_REQUEST, {data, callback}),
  deleteRecipientsSuccess: (data) =>
      createAction(types.RECIPIENTS_DELETE_SUCCESS, {data}),

  deleteRecipientRequest: (data, callback = null) =>
    createAction(types.RECIPIENT_DELETE_REQUEST, {data, callback}),
  deleteRecipientSuccess: (data) =>
    createAction(types.RECIPIENT_DELETE_SUCCESS, {data}),
  deleteRecipientFailure: () =>
    createAction(types.RECIPIENT_DELETE_FAILURE),
};
