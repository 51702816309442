import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  loading: false,
};

const failed = (state = initialState) => {
  return {...state};
};

const createDispatcherSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  values[data.id] = data
  return {...state, list: values, requestSuccess: 1, loading: false};
};

const updateDispatcherSuccess = (state = initialState, action) => {
  const {data, id} = action
  const { list } = state
  const values = list
  let tmp = {
    id,
    user: data,
    user_id: data.id
  }
  delete tmp.dispatchers
  values[id] = tmp
  return {...state, list: values, requestSuccess: 1, loading: false};
};


const deleteDispatcherSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = list
  delete values[data.id]
  return {...state, list: values, loading: false};
};

const getDispatchersSuccess = (state = initialState, action) => {
  const {data} = action
  const { list } = state
  const values = []
  data.forEach((v) => {
    values[v.id] = v
  })
  return {...state, list: values, loading: false};
};

const dispatchersFailed = (state = initialState) => {
  return {...state, requestSuccess: 2, loading: false};
};

const resetDispatchersReduc = (state = initialState) => {
  return {...state, requestSuccess: 0, loading: false}
}

export default createReducer(initialState, {
  [types.DISPATCHERS_CREATE_FAILURE]: dispatchersFailed,
  [types.DISPATCHERS_CREATE_SUCCESS]: createDispatcherSuccess,
  [types.DISPATCHERS_UPDATE_FAILURE]: dispatchersFailed,
  [types.RESET_DISPATCHERS_REDUC]: resetDispatchersReduc,
  [types.DISPATCHERS_UPDATE_SUCCESS]: updateDispatcherSuccess,
  [types.DISPATCHERS_DELETE_FAILURE]: failed,
  [types.DISPATCHERS_DELETE_SUCCESS]: deleteDispatcherSuccess,
  [types.GET_DISPATCHERS_FAILURE]: failed,
  [types.GET_DISPATCHERS_SUCCESS]: getDispatchersSuccess
});
