import { createSelector } from "reselect";
import moment from "moment-timezone";
import { setColorTask } from "../../utils/file-tools";

export const selectCourses = createSelector(
  (state) => ({
    courses: Object.values(state.tasks.courses),
    filter: state.tasks && state.tasks.filter ? state.tasks.filter : {},
  }),
  ({ courses, filter }) => {
    let noFilter =
      !filter ||
      (!filter.team_id &&
        (!filter.state || filter.state.length === 0) &&
        !filter.date);
    let tmp = courses.filter((v, i) => {
      if (!v || v.tasks.filter((v) => v.type !== "depot").length === 0)
        return false;
      let keep = noFilter;
      let noDate = true;
      let state_exist_in_course = false;
      v.tasks.forEach((vTask) => {
        let state_exist = null;
        let state = vTask.state !== null ? vTask.state : null;
        let start_date = vTask.start_date || null;
        let end_date = vTask.end_date || null;
        let date_exist =
          filter.date &&
          filter.date.length === 2 &&
          start_date &&
          end_date &&
          moment(filter.date[0]).valueOf() <= moment(start_date).valueOf() &&
          moment(filter.date[1]).valueOf() >= moment(end_date).valueOf();
        if (state !== null && state !== undefined) {
          state_exist =
            filter.state &&
            filter.state.length > 0 &&
            filter.state.includes(state);
          if (state_exist) {
            state_exist_in_course = true;
          }
        }
        if (
          (!filter.state || filter.state.length === 0 || state_exist) &&
          (!filter.date || date_exist)
        ) {
          keep = true;
        }
        if (start_date || end_date) {
          noDate = false;
        }
      });
      if (noDate && filter.withoutDate && state_exist_in_course) {
        return true;
      } else if (noDate && !filter.withoutDate) {
        return false;
      }
      return keep;
    });
    tmp = tmp.filter((v) => {
      if (!v) return false;
      if (filter.team_id && !v.team_id) {
        return false;
      }
      if (filter.team_id && v.team_id && v.team_id !== filter.team_id) {
        return false;
      }
      return true;
    });
    tmp = setColorTask(
      tmp
        .map((v) => ({
          ...v,
          key: v.id,
          tasks: v.tasks
            .sort((a, b) => a.order - b.order)
            .filter((v) => v.type !== "depot"),
        }))
        .sort(
          (a, b) => moment(b.created_at).unix() - moment(a.created_at).unix()
        )
        .sort((a, b) =>
          a.order_admin !== null && b.order_admin !== null
            ? b.order_admin - a.order_admin
            : false
        )
    );
    return tmp;
  }
);

export const selectCoursesToTreat = createSelector(
  (state) => ({
    courses: Object.values(state.tasks.coursesToTreat),
    filter: state.tasks && state.tasks.filter ? state.tasks.filter : {},
  }),
  ({ courses, filter }) => {
    let noFilter =
      !filter ||
      (!filter.team_id &&
        (!filter.state || filter.state.length === 0) &&
        !filter.date);
    let tmp = courses.filter((v, i) => {
      if (!v || v.tasks.filter((v) => v.type !== "depot").length === 0) {
        return false
      }
      let keep = noFilter;
      let noDate = true;
      let state_exist_in_course = false;
      v.tasks.forEach((vTask) => {
        let state_exist = null;
        let state = vTask.state !== null ? vTask.state : null;
        let start_date = vTask.start_date || null;
        let end_date = vTask.end_date || null;
        let date_exist =
          filter.date &&
          filter.date.length === 2 &&
          start_date &&
          end_date &&
          moment(filter.date[0]).valueOf() <= moment(start_date).valueOf() &&
          moment(filter.date[1]).valueOf() >= moment(end_date).valueOf();
        if (state !== null && state !== undefined) {
          state_exist =
            filter.state &&
            filter.state.length > 0 &&
            filter.state.includes(state);
          if (state_exist) {
            state_exist_in_course = true;
          }
        }
        if (
          (!filter.state || filter.state.length === 0 || state_exist) &&
          (!filter.date || date_exist)
        ) {
          keep = true;
        }
        if (start_date || end_date) {
          noDate = false;
        }
      });
      if (noDate && filter.withoutDate && state_exist_in_course) {
        return true;
      } else if (noDate && !filter.withoutDate) {
        return false;
      }
      return keep;
    });
    tmp = tmp.filter((v) => {
      if (!v) return false;
      if (filter.team_id && !v.team_id) {
        return false;
      }
      if (filter.team_id && v.team_id && v.team_id !== filter.team_id) {
        return false;
      }
      return true;
    });
    tmp = setColorTask(
      tmp
        .map((v) => ({
          ...v,
          key: v.id,
          tasks: v.tasks
            .sort((a, b) => a.order - b.order)
            .filter((v) => v.type !== "depot"),
        }))
        .sort(
          (a, b) => moment(b.created_at).unix() - moment(a.created_at).unix()
        )
        .sort((a, b) =>
          a.order_admin !== null && b.order_admin !== null
            ? b.order_admin - a.order_admin
            : false
        )
    );
    return tmp;
  }
);

export const selectCoursesDevis = createSelector(
  (state) => ({
    courses:
      state.tasks && state.tasks.coursesDevis
        ? Object.values(state.tasks.coursesDevis)
        : [],
    filter:
      state.tasks && state.tasks.filterDevis ? state.tasks.filterDevis : {},
  }),
  ({ courses, filter }) => {
    let noFilter =
      !filter ||
      (!filter.team_id &&
        (!filter.state || filter.state.length === 0) &&
        !filter.date);
    let tmp = courses.filter((v) => {
      if (!v || v.tasks.length === 0) return false;
      let keep = noFilter;
      let state_exist_in_course = false;
      v.tasks.forEach((vTask) => {
        let state_exist = null;
        let state = vTask.state !== null ? vTask.state : null;
        let start_date = vTask.start_date || null;
        let end_date = vTask.end_date || null;
        let date_exist =
          filter.date &&
          filter.date.length === 2 &&
          start_date &&
          end_date &&
          moment(filter.date[0]).valueOf() <= moment(start_date).valueOf() &&
          moment(filter.date[1]).valueOf() >= moment(end_date).valueOf();
        if (state !== null && state !== undefined) {
          state_exist =
            filter.state &&
            filter.state.length > 0 &&
            filter.state.includes(state);
          if (state_exist) {
            state_exist_in_course = true;
          }
        }
        if (
          (!filter.state || filter.state.length === 0 || state_exist) &&
          (!filter.date || date_exist)
        ) {
          keep = true;
        }
      });
      if (state_exist_in_course) {
        return true;
      }
      return keep;
    });
    tmp = tmp.filter((v) => {
      if (!v) return false;
      if (filter.team_id && !v.team_id) {
        return false;
      }
      if (filter.team_id && v.team_id && v.team_id !== filter.team_id) {
        return false;
      }
      return true;
    });
    tmp = setColorTask(
      tmp
        .map((v) => ({
          ...v,
          key: v.id,
          tasks: v.tasks
            .sort((a, b) => a.order - b.order)
            .filter((v) => v.type !== "depot"),
        }))
        .sort(
          (a, b) => moment(b.created_at).unix() - moment(a.created_at).unix()
        )
        .sort((a, b) =>
          a.order_admin !== null && b.order_admin !== null
            ? b.order_admin - a.order_admin
            : false
        )
    );
    return tmp;
  }
);

export const selectCourse = createSelector(
  (state) => state.tasks.current_course,
  (course) => {
    let tmp = [];
    let tmpPackages = [];
    if (course && course.tasks) {
      course.tasks.forEach((vTask) => {
        if (vTask.type !== "depot") {
          let formatedData = {
            key: vTask.id,
            ...vTask,
            start_date: vTask.start_date
              ? moment(vTask.start_date)
              : null,
            end_date: vTask.end_date
              ? moment(vTask.end_date)
              : null,
            date: vTask.start_date
              ? moment(vTask.start_date)
              : vTask.end_date
              ? moment(vTask.end_date)
              : null,
            start: vTask.start_date
              ? moment(vTask.start_date)
              : null,
            end: vTask.end_date
              ? moment(vTask.end_date)
              : null,
          };
          if (vTask.packagesByPickupId) {
            vTask.packagesByPickupId.forEach((a) => {
              tmpPackages[a.id] = { ...a, key: a.id };
            });
            formatedData.packages_pickup = vTask.packagesByPickupId.map(
              (p) => p.id
            );
            formatedData.packagesByPickupId = vTask.packagesByPickupId.map(
              (p) => ({ ...p, key: p.id })
            );
          }
          if (vTask.packagesByDeliveryId) {
            vTask.packagesByDeliveryId.forEach((a) => {
              tmpPackages[a.id] = { ...a, key: a.id };
            });
            formatedData.packages_delivery = vTask.packagesByDeliveryId.map(
              (p) => p.id
            );
            formatedData.packagesByDeliveryId = vTask.packagesByDeliveryId.map(
              (p) => ({ ...p, key: p.id })
            );
          }
          tmp.push(formatedData);
        }
      });
      return { coursePackages: tmpPackages, courseTasks: tmp, course: course };
    }
    return { coursePackages: [], courseTasks: [], course: {} };
  }
);

export const selectCoursesInvoice = createSelector(
  (state) => ({
    courses: state.tasks.courses || {},
    moreCourses: state.tasks.moreCourses || {},
  }),
  ({ courses, moreCourses }) => {
    for (const [key, value] of Object.entries(courses)) {
      courses[key] = { ...courses[key], key: courses[key].id };
    }
    for (const [key, value] of Object.entries(moreCourses)) {
      moreCourses[key] = { ...moreCourses[key], key: moreCourses[key].id };
    }
    return { ...courses, ...moreCourses };
  }
);

export const selectCoursesByMerchant = createSelector(
  (state) => ({
    courses: state.tasks.courses || {},
  }),
  ({ courses }) => {
    let tmpCourses = [];
    Object.values(courses).forEach((v) => {
      let tmpTasks = [];
      v.tasks.forEach((vTask) => {
        let tmp = vTask;
        tmp.course_id = v.id;
        tmp.devis = v.devis;
        tmp.food = v.food;
        tmp.package_types_id = v.package_types_id;
        tmp.coursier_id = v.coursier_id || null;
        tmp.team_id = v.team_id || null;
        tmp.created_at = v.created_at;
        tmp.key = vTask.id;
        tmp.start_date = vTask.start_date
          ? moment(vTask.start_date, "YYYY/MM/DDTHH:mm")
          : null;
        tmp.end_date = vTask.end_date
          ? moment(vTask.end_date, "YYYY/MM/DDTHH:mm")
          : null;
        tmpTasks.push(tmp);
      });
      tmpCourses = [...tmpCourses, ...tmpTasks];
    });
    return { courses: Object.values(courses), tasks: tmpCourses };
  }
);

export const selectFilter = createSelector(
  ({ tasks }) => tasks.filter,
  (filter) => filter
);

export const selectFilterDevis = createSelector(
  ({ tasks }) => tasks.filterDevis,
  (filter) => filter
);

export const selectFilterToTreat = createSelector(
  ({ tasks }) => tasks.filterToTreat,
  (filter) => filter
);

export const selectStats = createSelector(
  ({ tasks }) => tasks.stats,
  (stats) => stats
);

export const selectActiveList = createSelector(
    ({ tasks }) => tasks.activeList,
    (activeList) => activeList
);
