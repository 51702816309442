import {authenticatedMutation, authenticatedQuery} from "./middleware";
/**
 * GRAPHQL QUERIES
 */
const queries = {
  teams: `teams {
        id
        created_at
        updated_at
        email
        name
        dispatch_rate
        team_ID
    }`,
}
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  createTeams: (teams) => `mutation {
    insert_teams(objects: {
      email: ${teams.email ? `"${teams.email}"`: null}, 
      name: ${teams.name ? `"${teams.name}"`: null}, 
      dispatch_rate: ${teams.dispatch_rate ? teams.dispatch_rate : null}, 
    })
    {
      returning {
        id
        created_at
        updated_at
        email
        name
        dispatch_rate
      }
    }
  }`,
  updateTeams: (teams) => `mutation {
    update_teams(where: {id: {_eq: "${teams.id}"}},
     _set: {
      email: ${teams.email ? `"${teams.email}"`: null}, 
      name: ${teams.name ? `"${teams.name}"`: null},
      dispatch_rate: ${teams.dispatch_rate ? teams.dispatch_rate : null},
    }) 
      {
        returning {
          id
          created_at
          updated_at
          email
          name
          dispatch_rate
          team_ID
        }
      }
}`,
  deleteTeams: id => `mutation {
      delete_package_types_team(where: {team_id: {_eq: ${id}}}) {
        returning {
          package_type {
            id
          }
          id
        }
      }
      delete_teams(where: {id: {_eq: ${id}}}) {
        returning {
          id
        }
      }
    }`
}

export default {
  teams: () => authenticatedQuery(queries.teams),
  createTeams: team => { return authenticatedMutation(mutations.createTeams(team)) },
  updateTeams: team => { return authenticatedMutation(mutations.updateTeams(team)) },
  deleteTeams: id => { return authenticatedMutation(mutations.deleteTeams(id)) },
}