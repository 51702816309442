import { takeLatest, put, call } from 'redux-saga/effects';
import { UserService } from '../../services';
import { types, default as UserAction } from './actions';
import { select } from 'redux-saga/effects';
import { showSuccess, showError} from  '../../utils/notifications-helper'
import {getTranslate} from "react-localize-redux";

function* getUsersRequest() {
    const locale = yield select(state => state.locale);
    const strings = getTranslate(locale)
    //yield put(LoaderActions.loading());
    const [error, result] = yield call(UserService.users)
    if (result) {
        //yield put(LoaderActions.loaded())
        yield put(UserAction.getUsersSuccess(result.users))
    } else {
        if (!error.loginError) {
            showError("get_users_error", strings, null, error)
        }
        //yield put(LoaderActions.loaded())
        yield put(UserAction.getUsersFailure())
    }
}

function* searchReceiverRequest({value}) {
    const locale = yield select(state => state.locale);
    const {id} = yield select(state => state.user.current_user);
    const strings = getTranslate(locale)
    if (!value || value.length === 0) {
        yield put(UserAction.searchReceiverSuccess([]))
        return
    }
    const [error, result] = yield call(UserService.search, {value, created_by: id})
    if (result) {
        const receiverList = []
        result.view_recipients.forEach((recipient) => {
            receiverList.push({...recipient, code: `r-${recipient.id}`})
        })
        result.view_users.forEach((merchant) => {
            receiverList.push({...merchant, id: merchant.merchant_id, key: merchant.merchant_id, users: [{id: merchant.id}], code: `m-${merchant.merchant_id}`})
        })
        yield put(UserAction.searchReceiverSuccess(receiverList))
    } else {
        if (!error.loginError) {
            showError("search_user_error", strings, null, error)
        }
        yield put(UserAction.searchReceiverFailure())
    }
}

export default [
    takeLatest(types.GET_USERS_REQUEST, getUsersRequest),
    takeLatest(types.SEARCH_RECEIVER_REQUEST, searchReceiverRequest),
];
