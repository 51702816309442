import { takeLatest, put, call, select } from "redux-saga/effects";
import { PackageService } from "../../services";
import { LoaderActions } from "../loader";
import { types, default as PackageActions } from "./actions";
import { showSuccess, showError } from "../../utils/notifications-helper";
import { getTranslate } from "react-localize-redux";
import { pick } from "lodash";

function* getPackageTypesRequest() {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(PackageService.packageTypes);
  if (result) {
    yield put(PackageActions.getPackageTypesSuccess(result.package_types));
    yield put(LoaderActions.loaded());
  } else {
    if (!error.loginError) {
      showError("get_package_type_error", strings, null, error);
    }
    yield put(PackageActions.getPackageTypesFailure());
    yield put(LoaderActions.loaded());
  }
}

function* getPackageTypesTeamRequest() {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(PackageService.packageTypesTeam);
  if (result) {
    yield put(
      PackageActions.getPackageTypesTeamSuccess(result.package_types_team)
    );
    yield put(LoaderActions.loaded());
  } else {
    if (!error.loginError) {
      showError("get_package_type_team_error", strings, null, error);
    }
    yield put(PackageActions.getPackageTypesTeamFailure());
    yield put(LoaderActions.loaded());
  }
}

function* createPackageTypeRequest({ values, callback }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const filter = {
    ...pick(values, ["icon_fa", "name"]),
    is_admin: true,
  };
  const [error, result] = yield call(PackageService.createPackageType, filter);
  if (result) {
    if (callback) {
      callback();
    }
    showSuccess("create_package_type_success", strings);
    yield put(
      PackageActions.createPackageTypeSuccess(result.insert_package_types_one)
    );
    yield put(LoaderActions.loaded());
  } else {
    showError("create_package_type_error", strings, null, error);
    yield put(PackageActions.createPackageTypeFailure());
    yield put(LoaderActions.loaded());
  }
}

function* deletePackageTypeRequest({ ids, callback }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(PackageService.deletePackageType, ids);
  if (result) {
    if (callback) {
      callback();
    }
    yield put(
      PackageActions.deletePackageTypeSuccess(
        result.update_package_types.returning[0]
      )
    );
    showSuccess("delete_package_type_success", strings);
  } else if (error) {
    yield put(PackageActions.deletePackageTypeFailure());
    showError("delete_package_type_error", strings, null, error);
  }
  yield put(LoaderActions.loaded());
}

function* getPackageTypeByMerchantRequest({ merchant_id }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const { team_id } = yield select((state) => state.user.current_user);
  const strings = getTranslate(locale);
  let where = { _or: [{ icon: { _eq: "other" } }] };
  if (merchant_id) {
    where = {
      ...where,
      _or: [...where._or, { merchant_id: { _eq: merchant_id } }],
    };
  }
  if (team_id) {
    where = { ...where, _or: [...where._or, { team_id: { _eq: team_id } }] };
  }
  const [error, result] = yield call(
    PackageService.packageTypesByMerchantId,
    where
  );
  if (result) {
    yield put(
      PackageActions.getPackageTypesByMerchantSuccess(result.package_types)
    );
  } else if (error) {
    showError("get_package_type_error", strings, null, error);
    yield put(PackageActions.getPackageTypesByMerchantFailure());
  }
  yield put(LoaderActions.loaded());
}

function* modifyPackageTypesTeamRequest({ teamID, packageTypeIDs }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  let data = [];
  packageTypeIDs.forEach((v) => {
    data.push({
      team_id: teamID,
      package_types_id: parseInt(v),
    });
  });
  const [error, result] = yield call(PackageService.modifyPackageTypesTeam, {
    data,
    teamID,
  });
  if (result) {
    showSuccess("modify_package_type_team_success", strings);
    yield put(PackageActions.modifyPackageTypesSuccess());
    yield put(LoaderActions.loaded());
  } else {
    showError("modify_package_type_team_error", strings, null, error);
    yield put(PackageActions.modifyPackageTypesFailure());
    yield put(LoaderActions.loaded());
  }
}

function* getPackageKindRequest() {
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const [error, result] = yield call(PackageService.getPackageKinds);
  if (result) {
    yield put(PackageActions.getPackageKindSuccess(result.package_kinds));
    yield put(LoaderActions.loaded());
  } else {
    showError("get_package_kind_error", strings, null, error);
    yield put(PackageActions.getPackageKindFailure());
    yield put(LoaderActions.loaded());
  }
}

function* updatePackageTypeRequest({ values, callback }) {
  yield put(LoaderActions.loading());
  const locale = yield select((state) => state.locale);
  const strings = getTranslate(locale);
  const filter = {
    ...pick(values, ["icon_fa", "name"]),
  };
  const [error, result] = yield call(PackageService.updatePackageType, {
    values: filter,
    id: values.id,
  });
  if (result) {
    if (callback) {
      callback();
    }
    yield put(
      PackageActions.updatePackageTypeSuccess(result.update_package_types_by_pk)
    );
    showSuccess("update_package_type_success", strings);
  } else if (error) {
    yield put(PackageActions.updatePackageTypeFailure());
    showError("update_package_type_error", strings, null, error);
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_PACKAGE_TYPES_REQUEST, getPackageTypesRequest),
  takeLatest(
    types.GET_PACKAGE_TYPES_BY_MERCHANT_REQUEST,
    getPackageTypeByMerchantRequest
  ),
  takeLatest(types.GET_PACKAGE_TYPES_TEAM_REQUEST, getPackageTypesTeamRequest),
  takeLatest(types.GET_PACKAGE_KIND_REQUEST, getPackageKindRequest),
  takeLatest(types.CREATE_PACKAGE_TYPE_REQUEST, createPackageTypeRequest),
  takeLatest(types.DELETE_PACKAGE_TYPE_REQUEST, deletePackageTypeRequest),
  takeLatest(types.MODIFY_PACKAGE_TYPES_REQUEST, modifyPackageTypesTeamRequest),
  takeLatest(types.UPDATE_PACKAGE_TYPE_REQUEST, updatePackageTypeRequest),
];
