import moment from "moment-timezone";
import { notification } from "antd";
import TypeColis from "./typeColis";
import plageHours from "./plage-hours";
import tags from "./state";
import { get } from "lodash";

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const formatPlageHour = (value, strings) => {
  if (!value) return null;
  if (typeof value === "number") {
    return plageHours[value].text;
  } else if (typeof value === "string") {
    const tmp = JSON.parse(value);
    return `${strings("between")} ${tmp[0]} ${strings("and")} ${tmp[1]}`;
  }
  return null;
};

export const formatTypePackage = (value, packageTypes, isHostTDP) => {
  if (!packageTypes || packageTypes.length === 0) return null;
  const packageData = packageTypes.find((p) => p?.id === value);
  if (isHostTDP) {
    return TypeColis[packageData.icon].nameTDP;
  } else {
    return packageData?.name || null;
  }
};

export const setColorTask = (values) => {
  let color = false;
  return values
    .filter((v) => v)
    .map((v) => {
      color = !color;
      return {
        ...v,
        color: color,
        key: v.id,
      };
    });
};

export const allInfoData = (v) => {
  let address = get(v, ["address", "value"], "");
  return {
    UID: get(v, "uid", ""),
    Type: get(v, "type", ""),
    "Société de livraison": `${get(v, ["team", "name"], "")}`,
    Statut: get(tags, [v.state, "label"], ""),
    "Donneur d'ordre": `${get(v, ["merchant", "company"], "")} ${get(
      v,
      ["merchant", "users", 0, "name"],
      ""
    )}`,
    Société: get(v, "company", ""),
    "Nom - Prénom": get(v, "name", ""),
    Adresse: address,
    "Code Postal": v?.address?.data?.context?.find((v) =>
      v.id.includes("postcode")
    )?.text,
    Téléphone: get(v, "phone", ""),
    "Date de début": v.start_date
      ? `${moment(v.start_date).format("DD/MM/YYYY")}`
      : "",
    "Date de fin": v.end_date
      ? `${moment(v.end_date).format("DD/MM/YYYY")}`
      : "",
    "Poids total (Kg)":
      v.type === "collecte"
        ? v.packagesByPickupId_aggregate.aggregate.sum.weight
        : v.packagesByDeliveryId_aggregate.aggregate.sum.weight,
    "Volume total (cm3)":
      v.type === "collecte"
        ? v.packagesByPickupId_aggregate.aggregate.sum.volumn
        : v.packagesByDeliveryId_aggregate.aggregate.sum.volumn,
    "Nombres de bon": get(v, "nb_bon", ""),
    Notes: get(v, "notes", ""),
  };
};

export const someInfoData = (v) => {
  let address = get(v, ["address", "value"], "");
  return {
    UID: get(v, "uid", ""),
    Type: get(v, "type", ""),
    Société: get(v, "company", ""),
    Adresse: address,
    "Code Postal": v?.address?.data?.context?.find((v) =>
      v.id.includes("postcode")
    )?.text,
    "Date de début": v.start_date
      ? `${moment(v.start_date).format("DD/MM/YYYY")}`
      : "",
    "Date de fin": v.end_date
      ? `${moment(v.end_date).format("DD/MM/YYYY")}`
      : "",
    "Poids total (Kg)":
      v.type === "collecte"
        ? v.packagesByPickupId_aggregate.aggregate.sum.weight
        : v.packagesByDeliveryId_aggregate.aggregate.sum.weight,
    "Distance (Km)": v.nb_bon !== null ? v.nb_bon.toString().replace('.', ',') : ""
  };
};

function shallowEqual(object1, object2, allowListKey) {
  for (let key of allowListKey) {
    if (object1[key] && object2[key] && object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}

export const updateDataForPackage = (row, packages) => {
  if (row.packages_pickup || row.packages_delivery) {
    switch (row.type) {
      case "collecte":
        row.packagesByPickupId = row.packages_pickup.map((v) => ({
          ...packages.filter((p) => p.key === v)[0],
        }));
        break;
      case "livraison":
        row.packagesByDeliveryId = row.packages_delivery.map((v) => ({
          ...packages.filter((p) => p.key === v)[0],
        }));
        break;
      case "livraison/collecte":
        row.packagesByDeliveryId = row.packages_delivery.map((v) => ({
          ...packages.filter((p) => p.key === v)[0],
        }));
        row.packagesByPickupId = row.packages_pickup.map((v) => ({
          ...packages.filter((p) => p.key === v)[0],
        }));
        break;
      default:
        break;
    }
  }
  return row;
};

export const checkEmailRequired = (email, strings) => {
  if (!email || email.length === 0) {
    notification.error({
      message: strings("email_required"),
      description: strings("email_required_to_mail"),
    });
    return true;
  }
  return false;
};

export const checkDate = (row, strings) => {
  if (row.date && (!row.start || !row.end)) {
    notification.error({
      message: strings("error_date"),
      description: strings("hour_start_end_required"),
    });
    return true;
  }
  return false;
};

export const receiverExist = (data, list) => {
  const tmp = list.filter((v) => {
    if (!v) {
      return false;
    }
    if (
      shallowEqual(
        { ...v, address: v.address ? normalizeString(v.address.value) : null },
        {
          ...data,
          address: data.address ? normalizeString(data.address.value) : null,
        },
        ["address", "name", "phone_number", "company"]
      )
    ) {
      return true;
    } else {
      return false;
    }
  });
  if (
    data &&
    data.address &&
    data.address.value &&
    ((data.name && data.name.length > 0) ||
      (data.company && data.company.length > 0)) &&
    tmp.length > 0
  ) {
    return tmp;
  } else {
    return [];
  }
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const normalizeString = (str) =>
  str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

export const filterOptionReceiver = (v, o) => {
  const value = v
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const arrayData = o.children
    .filter((a) => a)
    .map((v) => {
      return v.props.children;
    });
  return (
    (arrayData[0] && normalizeString(arrayData[0]).includes(value)) ||
    (arrayData[1] && arrayData[1].includes(v)) ||
    (arrayData[2] && normalizeString(arrayData[2]).includes(value)) ||
    (arrayData[3] && normalizeString(arrayData[3]).includes(value))
  );
};

export const disableComma = (evt) => evt.key === "," && evt.preventDefault();

export const getById = (id, list) => {
  if (!id) return null;
  const tmp = list.filter((v) => v && v.id === id);
  return tmp.length > 0 ? tmp[0] : null;
};

export const getByCode = (code, list) => {
  if (!code) return null;
  const tmp = list.filter((v) => v && v.code === code);
  return tmp.length > 0 ? tmp[0] : null;
};

export const checkMaxValue =
  (strings) =>
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value > 999998.9) {
          return Promise.reject(strings("max_value"));
        } else {
          return Promise.resolve();
        }
      },
    });

export const checkPhoneFormat =
  (strings) =>
    ({ getFieldValue }) => ({
      validator(rule, value) {
        var regexp = new RegExp(
          "^0[6-7]{1}(([0-9]{2}){4})|(([0-9]{2}){4})|((-[0-9]{2}){4})$"
        );
        if (!value || value.length === 0) {
          return Promise.resolve();
        } else if (!regexp.test(value)) {
          return Promise.reject(strings("format_not_correct"));
        } else if (value[0] === "+" && value.length !== 12) {
          return Promise.reject(strings("phone_must_be_composed_of_12_long"));
        } else if (value[0] !== "+" && value.length !== 10) {
          return Promise.reject(strings("phone_must_be_10_digits_long"));
        } else {
          return Promise.resolve();
        }
      },
    });

export function addDataWithCode(value, list) {
  let tmp = {};
  let receiver = list.filter((v) => v.code === value)[0];
  if (value) {
    const splt = value.split("-");
    if (splt[0] === "r") {
      tmp.recipient_id = parseInt(splt[1]);
      tmp.hub_id = null;
      tmp.merchant_id = null;
    } else if (splt[0] === "m") {
      tmp.merchant_id = parseInt(splt[1]);
      tmp.hub_id = null;
      tmp.recipient_id = null;
    }
    tmp.company = receiver?.company || null;
    tmp.name = receiver?.name || null;
    tmp.phone = receiver?.phone || null;
    tmp.address = receiver?.address || null;
    tmp.email = receiver?.email || null;
  }
  return tmp;
}

export const calcRequestTime = (title, start_date) => {
  if (start_date) {
    var duration = moment.duration(moment().diff(start_date));
    console.log("duration: " + title, duration.milliseconds());
  }
};

export const getElementOfTable = (key, value, table) => {
  const index = table.findIndex((el) => el[`${key}`] === value);
  if (index > -1) {
    return table[index];
  } else {
    return null;
  }
};
