import { createAction } from '../../utils';

// Types
export const types = {
  GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE: 'GET_ORDERS_FAILURE',

  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',

  UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILURE: 'UPDATE_ORDER_FAILURE',

  DELETE_ORDER_O_REQUEST: 'DELETE_ORDER_O_REQUEST',
  DELETE_ORDER_O_SUCCESS: 'DELETE_ORDER_O_SUCCESS',
  DELETE_ORDER_O_FAILURE: 'DELETE_ORDER_O_FAILURE',

  RESET_ORDERS_REDUC: 'RESET_ORDERS_REDUC',
  RESET_CURRENT_ORDER: 'RESET_CURRENT_ORDER',
  SET_CURRENT_ORDER_REDUC: 'SET_CURRENT_ORDER_REDUC',

  GET_DELIVERY_STATUS_REQUEST: 'GET_DELIVERY_STATUS_REQUEST',
  GET_DELIVERY_STATUS_SUCCESS: 'GET_DELIVERY_STATUS_SUCCESS',
  GET_DELIVERY_STATUS_FAILURE: 'GET_DELIVERY_STATUS_FAILURE'
};

// Actions
export default {
  setCurrentOrderReduc: (values) =>
      createAction(types.SET_CURRENT_ORDER_REDUC, {values}),

  resetCurrentOrder: () =>
      createAction(types.RESET_CURRENT_ORDER),

  resetRequestSuccessReduc: () =>
    createAction(types.RESET_ORDERS_REDUC),

  getOrdersRequest: (hub_id, dispatch) =>
    createAction(types.GET_ORDERS_REQUEST, {hub_id, dispatch}),
  getOrdersSuccess: (data) =>
    createAction(types.GET_ORDERS_SUCCESS, {data}),
  getOrdersFailure: () =>
    createAction(types.GET_ORDERS_FAILURE),

  getDeliveryStatusRequest: () =>
      createAction(types.GET_DELIVERY_STATUS_REQUEST, {}),
  getDeliveryStatusSuccess: (data) =>
      createAction(types.GET_DELIVERY_STATUS_SUCCESS, {data}),
  getDeliveryStatusFailure: () =>
      createAction(types.GET_DELIVERY_STATUS_FAILURE),

  createOrderRequest: (values, hub, callback = null) =>
    createAction(types.CREATE_ORDER_REQUEST, { values, hub, callback }),
  createOrderSuccess: (data) =>
    createAction(types.CREATE_ORDER_SUCCESS, {data}),
  createOrderFailure: () =>
    createAction(types.CREATE_ORDER_FAILURE),

  updateOrderRequest: (values, hub, callback = null) =>
    createAction(types.UPDATE_ORDER_REQUEST, {values, hub, callback}),
  updateOrderSuccess: (data) =>
    createAction(types.UPDATE_ORDER_SUCCESS, {data}),
  updateOrderFailure: () =>
    createAction(types.UPDATE_ORDER_FAILURE),

  deleteOrderRequest: (values) =>
    createAction(types.DELETE_ORDER_O_REQUEST, {values}),
  deleteOrderSuccess: (data) =>
    createAction(types.DELETE_ORDER_O_SUCCESS, {data}),
  deleteOrderFailure: () =>
    createAction(types.DELETE_ORDER_O_FAILURE),
};
