import { createSelector } from "reselect";
import moment from "moment-timezone";

export const selectGroups = createSelector(
  (state) => Object.values(state.groups.list),
  (groups) => {
    return groups
      .map((v) => ({ key: v.id, ...v }))
      .sort(
        (a, b) =>
          moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
      );
  }
);
