import { takeLatest, put, call, select } from 'redux-saga/effects';
import { CoursiersService } from '../../services';
import { LoaderActions } from '../loader';
import { types, default as CoursiersActions } from './actions';
import { showError} from  '../../utils/notifications-helper'
import { getTranslate } from 'react-localize-redux';

function* getCoursiersRequest() {
  //yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)

  const [error, result] = yield call(CoursiersService.getCoursiers)
  if (result) {
    yield put(CoursiersActions.getCoursiersSuccess(result.coursier))
    //yield put(LoaderActions.loaded())
  } else {
    if (!error.loginError) {
      showError("get_coursiers_error", strings, null, error)
    }
    yield put(CoursiersActions.getCoursiersFailure())
    //yield put(LoaderActions.loaded())
  }
}

export default [
  takeLatest(types.GET_COURSIERS_REQUEST, getCoursiersRequest),
];
