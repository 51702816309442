import { call } from "redux-saga/effects";
import { TasksService } from "../services";
import moment from "moment-timezone";

export function* generateIdentifier() {
  let baseIdentifier = moment().format("YYYY-MM-DD");
  let courses = {};
  let identifier = null;
  const [error, response] = yield call(TasksService.getCoursesIdentifier);
  if (response && !error) {
    response.courses.forEach((v) => {
      if (v.identifier) {
        courses[v.identifier] = v;
      }
    });
  } else {
    return null;
  }
  let gen = Math.random().toString(26);
  let r = gen.substring(gen.length - 2, gen.length);
  identifier = baseIdentifier + r.toUpperCase();
  for (; courses[identifier] && courses[identifier].id; ) {
    let gen = Math.random().toString(26);
    let r = gen.substring(gen.length - 2, gen.length);
    identifier = baseIdentifier + r.toUpperCase();
  }
  return identifier;
}
