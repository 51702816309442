import {
  InboxOutlined,
  MailOutlined,
  DropboxOutlined,
  DashOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import enveloppe from "../assets/images/typeColis/enveloppe.svg";
import enveloppe_select from "../assets/images/typeColis/enveloppe-selected.svg";
import box from "../assets/images/typeColis/box.svg";
import box_select from "../assets/images/typeColis/box-selected.svg";
import bigBox from "../assets/images/typeColis/big-box.svg";
import bigBox_select from "../assets/images/typeColis/big-box-selected.svg";
import other from "../assets/images/typeColis/other.svg";
import other_select from "../assets/images/typeColis/other-selected.svg";
import shopping from "../assets/images/typeColis/shopping-bag.svg";
import shopping_select from "../assets/images/typeColis/shopping-bag-selected.svg";

const typeColis = {
  enveloppe: {
    nameTDP: "Jusqu'à 1 sac",
    weightTDP: 5,
    volumeTDP: 200,
    unselected: enveloppe,
    selected: enveloppe_select,
    icon: <MailOutlined style={{ color: "white" }} />,
  },
  box: {
    nameTDP: "De 2 à 5 sacs",
    weightTDP: 10,
    volumeTDP: 400,
    unselected: box,
    selected: box_select,
    icon: <InboxOutlined style={{ color: "white" }} />,
  },
  "big-box": {
    nameTDP: "Très grand format",
    weightTDP: 50,
    volumeTDP: 2000,
    unselected: bigBox,
    selected: bigBox_select,
    icon: <DropboxOutlined style={{ color: "white" }} />,
  },
  "shopping-bag": {
    nameTDP: "De 6 à 10 sacs",
    weightTDP: 20,
    volumeTDP: 800,
    unselected: shopping,
    selected: shopping_select,
    icon: <ShoppingOutlined style={{ color: "white" }} />,
    weight: 5,
    volumn: 40,
  },
  other: {
    nameTDP: "Hors Standard",
    unselected: other,
    selected: other_select,
    icon: <DashOutlined style={{ color: "white" }} />,
  },
};

export default typeColis;
