import { createAction } from '../../utils';

// Types
export const types = {
    GET_AREAS_REQUEST: 'GET_AREAS_REQUEST',
    GET_AREAS_SUCCESS: 'GET_AREAS_SUCCESS',
    GET_AREAS_FAILURE: 'GET_AREAS_FAILURE',
    
    CHECK_AREAS_REQUEST: 'CHECK_AREAS_REQUEST',
    CHECK_AREAS_SUCCESS: 'CHECK_AREAS_SUCCESS',
    CHECK_AREAS_FAILURE: 'CHECK_AREAS_FAILURE',

    GET_NAME_ZONE_ADDRESS_REQUEST: 'GET_NAME_ZONE_ADDRESS_REQUEST',
    GET_NAME_ZONE_ADDRESS_SUCCESS: 'GET_NAME_ZONE_ADDRESS_SUCCESS',
    GET_NAME_ZONE_ADDRESS_FAILURE: 'GET_NAME_ZONE_ADDRESS_FAILURE',
};

// Actions
export default {
    getAreasRequest: () =>
      createAction(types.GET_AREAS_REQUEST),
    getAreasSuccess: (data) =>
      createAction(types.GET_AREAS_SUCCESS, {data}),
    getAreasFailure: () =>
      createAction(types.GET_AREAS_FAILURE),

    checkContainAreasRequest: (values) =>
      createAction(types.CHECK_AREAS_REQUEST, {values}),
    checkContainAreasSuccess: (data) =>
      createAction(types.CHECK_AREAS_SUCCESS, {data}),
    checkContainAreasFailure: () =>
      createAction(types.CHECK_AREAS_FAILURE),

    getNameZoneByAddressRequest: (value) =>
        createAction(types.GET_NAME_ZONE_ADDRESS_REQUEST, {value}),
    getNameZoneByAddressSuccess: (data) =>
        createAction(types.GET_NAME_ZONE_ADDRESS_SUCCESS, {data}),
    getNameZoneByAddressFailure: () =>
        createAction(types.GET_NAME_ZONE_ADDRESS_FAILURE),

};
