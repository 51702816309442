import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  loading: false,
  hasMore: true,
  isInZone: true,
  addrFound: []
};

const getAreasSuccess = (state = initialState, action) => {
  const {data} = action
  const tmp = []
  data.forEach(p => {
    if (tmp[p.team_id] && tmp[p.team_id].length > 0) {
      tmp[p.team_id].push(p)
    } else {
      tmp[p.team_id] = [p]
    }
  })
  return {...state, list: tmp, loading: false};
};

const checkContainAreasSuccess = (state = initialState, action) => {
  const {data} = action
  return {...state, isInZone: data, loading: false};
};

const getNameZoneByAddressSuccess = (state = initialState, action) => {
  const {data} = action
  let tmp = state.addrFound
  let exist = tmp.filter((v) => v.address.value === data.address.value).length > 0
  if (!exist)
    tmp.push(data)
  return {...state, addrFound: tmp, loading: false};
};

const getFailed = (state = initialState) => {
  return {...state};
};

export default createReducer(initialState, {
  [types.GET_AREAS_FAILURE]: getFailed,
  [types.GET_AREAS_SUCCESS]: getAreasSuccess,
  [types.CHECK_AREAS_FAILURE]: getFailed,
  [types.CHECK_AREAS_SUCCESS]: checkContainAreasSuccess,
  [types.GET_NAME_ZONE_ADDRESS_FAILURE]: getFailed,
  [types.GET_NAME_ZONE_ADDRESS_SUCCESS]: getNameZoneByAddressSuccess,
});
