const strings_fr = {
	/* Generic Message */
	unauthorized: "Vous n'êtes pas autorisé à afficher cette page, veuillez vous connecter.",
	authentication_failed: 'Login ou mot de passe incorrect.',
	generic_error: "Une erreur inconnue s'est produite lors du chargement des données.",
	fields_missing: 'Merci de renseigner tous les champs du formulaire.',

	/* Buttons */
	sign_in: "JE M'IDENTIFIE",
	connect: 'CONNEXION',

	/* Authentication */
	email_placeholder: 'Adresse E-mail',
	username: 'Identifiant',
	password_placeholder: 'Mot de passe',
	invalid_credentials: 'Email et/ou mot de passe invalide',
	forgotten_password: 'Mot de passe oublié',
	subscribe_succeed: 'votre compte a bien été créé.',
	login: 'Se connecter',
	/* Password */
	password_modify_error: 'La modification de votre mot de passe a échouée',
	password_modify_success: 'Votre mot de passe a été modifié avec succès',
	create_delivery_success: 'Livraison effectuée avec succès',
	create_delivery_error: 'Un problème est survenu sur la sauvegarde de votre livraison',
	get_all_delivery_error: 'Un problème est survenu sur la récupération des commandes',

	delete_tasks_success: 'Livraison a bien été supprimée',
	delete_tasks_error: 'Un problème est survenu sur la suppression de la livraison',
	get_all_teams_error: 'Un problème est survenu sur la récupération des équipes',
	modify_task_success: 'Livraison modifiée avec succès',
	modify_task_error: 'Un problème est survenu lors de la sauvegarde de la livraison',
	get_all_delivery_from_error: "La date de début dans les filtres doit être inférieur à la date d'aujourd'hui",

	import_tasks_success: 'Les tâches ont été sauvegardées avec succès',
	client_create_success: 'Le client a bien été créé avec succès',
	client_create_error: 'Un problème est survenu lors de la création du client',
	client_delete_success: 'Le client a bien été supprimé',
	client_delete_error: 'Un problème est survenu lors de la suppression du client',
	client_update_success: 'Le client a été modifié avec succès',
	client_update_error: 'Un problème est survenu lors de la modification du client',
	get_clients_error: 'Un problème est survenu lors de la récupération des clients',

	get_all_worker_error: 'Un problème est survenu lors de la récupération des coursiers',
	get_detail_task_error: 'Un problème est survenu lors de la récupération du détail de la tâche',

	merchant_create_success: 'Le commerçant a été créé avec succès',
	merchant_create_error: 'Un problème est survenu lors de la création du commerçant',
	merchants_delete_error: 'Un problème est survenu lors de la suppression des commerçants',
	merchants_delete_success: 'Les commerçants ont bien été supprimés',
	merchant_delete_success: 'Le commerçant a été supprimé avec succès',
	merchant_delete_error: 'Un probème est survenu lors de la suppression du commerçant',
	merchant_update_success: 'Le commerçant a été modifié avec succès',
	merchant_update_error: 'Un problème est survenu lors de la modification du commerçant',
	get_merchants_error: 'Un problème est survenu lors de la récupération des commerçants',
	email_already_used: 'Email déjà utilisé',

	dispatcher_create_success: 'Le dispatcheur a été créé avec succès',
	dispatcher_create_error: 'Un problème est survenu lors de la création du dispatcheur',
	dispatcher_delete_success: 'Le dispatcheur a été supprimé avec succès',
	dispatcher_delete_error: 'Un probème est survenu lors de la suppression du dispatcheur',
	dispatcher_update_success: 'Le dispatcheur a été modifié avec succès',
	dispatcher_update_error: 'Un problème est survenu lors de la modification du dispatcheur',
	get_dispatchers_error: 'Un problème est survenu lors de la récupération des dispatcheurs',

	modify_package_type_team_success: "L'attribution des colis a été modifié avec succès",
	modify_package_type_team_error: "Un problème est survenu lors de la modification de l'attribution des colis",
	delete_package_type_error: 'Un problème est survenu lors de la suppression du type de colis',
	delete_package_type_success: 'Le type de colis a été supprimé avec succès',
	create_package_type_success: 'Le type de colis a été créé avec succès',
	create_package_type_error: 'Un problème est survenu lors de la creation du type de colis',
	get_package_type_team_error: 'Un problème est survenu lors de la récupération des types de colis par équipe',
	get_package_type_error: 'Un problème est survenu lors de la récupération des types de colis',
	create_team_success: 'La société de livraison a été créée avec succès',
	create_team_error: 'Un problème est survenu lors de la création de la société de livraison',
	modify_team_success: 'La société de livraison a été modifiée avec succès',
	modify_team_error: 'Un problème est survenu lors de la modification de la société de livraison',
	delete_team_success: 'La société de livraison a été supprimée avec succès',
	delete_team_error: 'Un problème est survenu lors de la suppression de la société de livraison',

	create_task_success: 'Votre course a été créée avec succès',
	maj_course_success: 'Votre course a été mis à jour avec succès',
	create_task_error: 'Une erreur est survenu lors de la création de la tâche',

	delete_course_error: "Une erreur est survenu lors de la suppression d'une course",
	delete_task_success: 'La course a été supprimer avec succès',

	cancel: 'Annuler',
	back: 'Retour',
	save: 'Sauvegarder',

	send_invoice_success: "L'email a été envoyé",
	generate_pdf_error: 'Un problème est survenu lors de la génération du PDF',

	update_password_error: 'Une erreur est survenu lors de la modification du mot de passe',
	update_password_success: 'Le mot de passe a été modifier avec succès',

	1000: "Les valeurs d'un ou de plusieurs paramètres ne sont pas valables.", //"The values of one or more parameters are invalid.",
	1001: "Les valeurs d'un ou de plusieurs paramètres sont ambiguës.", //"The values of one or more parameters are ambiguous.",
	1005: "Les types de données d'un ou de plusieurs paramètres ne sont pas valables.", //"The data types of one or more parameters are invalid.",
	1009: 'La date de début doit être inférieur à la date de fin',
	1010: 'Numéro de téléphone invalide',
	1011: "Des problèmes avec l'adresse ont été constatés",
	2300: "Vous avez attend le nombre de requête par seconde limite. S'il vous plait, ralentissez",
	order_create_success: "L'ordre a été créé avec succès",
	order_create_error: "Un problème est survenu lors de la creation de l'ordre",
	order_update_success: "L'ordre a été modifié avec succès",
	order_update_error: "Un problème est survenu lors de la sauvegarde de l'ordre",
	order_delete_success: "L'ordre a été supprimé avec succès",
	order_delete_error: "Un problème est survenu lors de la suppression de l'ordre",

	get_hubs_error: 'Un problème est survenu lors de la récupération des hubs',
	get_hub_error: 'Un problème est survenu lors de la récupération du hub',
	hub_create_success: 'Le hub a été créé avec succès',
	hub_create_error: 'Un problème est survenu lors de la création du hub',
	hub_update_success: 'Le hub a été modifié avec succès',
	hub_update_error: 'Un problème est survenu lors de la sauvegarde du hub',
	hub_delete_success: 'Le hub a été supprimé avec succès',
	hub_delete_error: 'Un problème est survenu lors de la suppression du hub',
	hub_user_create_success: 'Le magasinier a été créé avec succès',
	hub_user_create_error: 'Un problème est survenu lors de la création du magasinier',
	hub_user_update_success: 'Le magasinier a été modifié avec succès',
	hub_user_update_error: 'Un problème est survenu lors de la sauvegarde du magasinier',
	hub_user_delete_success: 'Le magasinier a été supprimé avec succès',
	hub_user_delete_error: 'Un problème est survenu lors de la suppression du magasinier',

	modify_order_success: "L'ordre des courses a bien été sauvegardé",
	modify_order_error: "Un problème est survenu lors de la sauvegarde de l'ordre des courses",
	get_delivery_status_error: 'Un problème est survenu lors de la récupération des status de livraisons',
	get_orders_error: 'Un problème est survenu lors de la récupération des ordres',
	get_packages_kinds_error: 'Un problème est survenu lors de la récupération de la nature des colis',

	name: 'Prénom - Nom',
	add_palette: 'Ajouter une palette',
	delete_pallet: 'Supprimer la palette',
	add_package: 'Ajouter un colis',
	update_package: 'Modifier un colis',
	update_the_package: 'Modifier le colis',
	delete_package: 'Supprimer le colis',
	new_pallet: 'Nouvelle palette',
	validate: 'Valider',
	delivery_info: 'Information de livraison',
	create_order: "Créer l'ordre",

	get_tasks_error: 'Un problème est survenu lors de la récupération des courses',
	packages_info: 'Information de colis',
	update_order: "Modifier l'ordre",
	delete_order: "Supprimer l'ordre",
	delivery_time: 'Date de livraison',
	address_hub_required: "L'adresse du Hub doit être renseignée.",
	status: 'Statut',
	email_already_exist: "L'email est déjà utilisé",

	id: 'Identifiant',
	teams_update_success: 'La société de livraison a été modifiée avec succès.',
	teams_update_error: 'Un problème est survenu lors de la modification de la société de livraison.',
	teams_delete_success: 'La société de livraison a été supprimée avec succès.',
	teams_delete_error: 'Un problème est survenu lors de la suppression de la société de livraison.',
	teams_create_success: 'La société de livraison a été créée avec succès.',
	teams_create_error: 'Un problème est survenu lors de la création de la société de livraison.',

	uid_package: 'Identifiant du colis',
	uid_task: 'Identifiant de la livraison',
	download_pdf_delivery: 'PDF livraison',
	generate_pdf_task_error: 'Un problème est survenu lors du téléchargement du PDF',
	sign: 'Signature',
	info_error: 'Informations sur le problème',
	info: 'Informations',
	contractor: "Donneur d'ordre",
	contractor_required: "Donneur d'ordre obligatoire",
	any_contractor: "Aucun donneur d'ordre",

	recipients_delete_error: 'Un problème est survenu lors de la suppression des bénéficiaires',
	recipient_delete_error: 'Un problème est survenu lors de la suppression du bénéficiaire',
	recipient_delete_success: 'Le bénéficiaire a bien été supprimé',
	recipients_delete_success: 'Les bénéficiaires ont bien été supprimés',
	recipient_update_error: 'Un problème est survenu lors de la modification du bénéficiaire',
	recipient_update_success: 'Le bénéficiaire a bien été modifié',
	recipient_create_error: 'Un problème est survenu lors de la creation du bénéficiaire',
	recipient_create_success: 'Le bénéficiaire a bien été créé',
	no_recipient: 'Aucun destinataire trouvé',

	new_recipient: 'Nouveau destinataire',
	my_recipients: 'Mes destinataires',
	create_a_recipient: 'Créer un destinataire',
	update_the_recipient: 'Modifier le destinataire',
	search: 'Rechercher',
	all_your_recipient: 'Tous vos destinataires',
	address: 'Adresse',
	email: 'Email',
	phone: 'Téléphone',
	company: 'Société',
	date_last_update: 'Date derniére modification',
	date_create: 'Date de création',

	all_your_merchants: 'Tous vos clients',
	new_merchant: 'Nouveau client',
	my_merchants: 'Mes clients',
	create_a_merchant: 'Créer un client',
	update_the_merchant: 'Modifier le client',

	upon_receipt_of_the_invoice: 'A réception de la facture',
	days_after_receipt: 'jours après réception',
	payment_deadline: 'Délai de paiement accordé',
	discount_granted: 'Remise accordée',
	special_conditions: 'Conditions particulières',
	taxe: 'TVA intracommunautaire',
	not_fragile: 'Pas fragile',
	nature_of_the_merchandise: 'Nature de la marchandise',
	type_of_package: 'Type de colis',
	type: 'Type',
	format_not_correct: "Le format n'est pas correct",
	phone_must_be_composed_of_12_long: 'Le numéro doit être composé de 12 caractères',
	phone_must_be_10_digits_long: 'Le numéro doit être composé de 10 chiffres',
	of_contact: 'du contact',
	firstname_lastname_of: 'Prénom et Nom du',
	receiver: 'destinataire',
	recipient_address: 'Adresse du destinataire',
	delivery_address: 'Adresse de livraison',
	company_label: "Raison sociale / Nom de l'entreprise",
	individual: 'Particulier',
	professional: 'Professionnel',
	company_delivery: 'Société de livraison',
	email_required: "L'email est obligatoire",
	password: 'Mot de passe',
	password_required: 'Mot de passe obligatoire',
	warning_message_password: 'Laissez le mot de passe vide pour ne pas le modifier',

	new_course: 'Nouvelle course',
	update_course: 'Modifier la course',
	update_the_task: 'Modifier la tâche',
	new_task: 'Nouvelle tâche',
	add_task: 'Ajouter une tâche',
	valid_the_modif: 'Valider la modification',
	add_the_task: 'Ajouter la tâche',
	print_ticket: 'Imprimer étiquette',
	get_pricing_error: 'La récupération des tarifs à échoué',
	modify_pricing_error: 'Un problème est survenu lors de La modification des tarifs',
	modify_pricing_success: 'Les tarifs ont été modifié avec succès',

	no_order_in_progress: 'Il y a aucun ordre en cours pour le moment',
	no_order_done: 'Il y a aucun ordre pour le moment',
	order_in_progress: 'ORDRES EN COURS',
	order_done: 'ORDRES TERMINÉS',
	error_sup_date: "L'heure de début doit être inférieure à l'heure de fin",
	error_inf_date: "L'heure de fin doit être supérieure à l'heure de début",
	notes: 'Notes',
	nb_bon: 'Nombres de bon',
	Receiver: 'Destinataire',
	weight: 'Poids',
	weight_suffix: 'Kg',
	volumn: 'Volume',
	volumn_suffix: 'cm3',
	time_slot: 'Créneau',
	start: 'Début',
	end: 'Fin',
	today: "Aujourd'hui",
	fragile: 'Fragile',
	yes: 'Oui',
	no: 'Non',
	actions: 'Actions',
	pallet_empty: 'Une palette est vide',
	nature_of_package: 'Nature du colis',
	receiver_required: 'Destinataire obligatoire',
	no_package: 'Aucun colis',
	create_recipient: 'Créer un bénéficiaire',
	update_recipient: 'Modifier le bénéficiaire',
	update_merchant: 'Modifier le client',
	add_receiver: 'Ajouter un bénéficiaire',
	max_value: 'Valeur trop grande',
	merchants_deki: 'Les clients Deki',
	all_dispatchers: 'Tous vos dispatcheurs',
	my_dispatchers: 'Mes dispatcheurs',
	update_the_dispatcher: 'Modifier le dispatcheur',
	create_the_dispatcher: 'Créer le dispatcheur',
	keep_password_empty: 'Laissez le mot de passe vide pour ne pas le modifier',
	task_add_to_course: 'Tâches ajoutées à ma course',
	all_courses: 'Toutes vos courses',
	all_hubs: 'Tous vos Hubs',
	name_required: 'Nom obligatoire',
	name_of_team: 'Nom',
	create_hub: 'Créer un Hub',
	hub: 'Hub',
	my_hubs: 'Mes Hubs',
	my_courses: 'Mes Courses',
	new_account: 'Nouveau compte',
	company_client: 'Société client',
	are_you_sure_to_delete: 'Êtes vous sur de vouloir supprimer ?',
	no_data_for_this_date: 'Aucune donnée pour cette date',
	error_date: 'Erreur date',
	hour_start_end_required: 'Une heure de début ou de fin est obligatoire si vous remplissez la date du créneau',
	invoices_title: 'Facturation',
	to_invoice: 'Prestations à facturer',
	send_invoice_button: 'Envoyer le document',
	delete_invoice_text_one: 'Vous êtes sur le point de supprimer le document sélectionné',
	delete_invoice_text_one_plurial: 'Vous êtes sur le point de supprimer les documents sélectionnés',
	delete_invoice_text_two:
		'Êtes vous sûr de vouloir supprimer ce document ? La suppression est définitive et vous ne pourrez plus y avoir accès.',
	delete_invoice_text_two_plurial:
		'Êtes vous sûr de vouloir supprimer ces documents ? La suppression est définitive et vous ne pourrez plus y avoir accès.',
	delete_invoice_button: 'Supprimer le document',
	enveloppe: 'Enveloppe',
	envelope: 'Enveloppe',
	box: 'Boîte',
	'big-box': 'Grosse boîte',
	'shopping-bag': 'Sac de course',
	other: 'Devis',
	brittle: 'Fragile',
	noBrittle: 'Non-fragile',
	noFood: 'Non-alimentaire',
	sec: 'Sec',
	costs: 'Frais',
	fresh: 'Frais',
	freshFrozen: 'Surgelé',
	restricted: 'restreint',
	lessAnHour: "Livraison en moins d'une heure",
	otherDays: 'Livraison le surlendemain',

	select_team: 'Selectionnez une société',
	select_status: 'Sélectionnez un statut',
	no_team: 'Aucune société',
	no_states: 'Aucun statut',
	delete: 'Supprimer',
	are_you_sure_delete: 'Êtes vous sur de vouloir supprimer ?',
	more_option: "Plus d'options",
	market_value: 'Valeur marchande',
	money: '€',
	package_pickup: 'Colis à collecter',
	package_delivery: 'Colis à déposer',
	select_package: 'Sélectionnez un ou plusieurs colis',
	pickup: 'Collecte',
	delivery: 'Livraison',
	weight_required: 'Poids obligatoire',
	volumn_required: 'Volume obligatoire',
	delivery_pickup: 'Livraison + Collecte',
	default_package: 'Ajouter le colis par défaut',
	create_package_error: 'Un problème est survenu lors de la création du colis',
	update_package_error: 'Un problème est survenu lors de la modification du colis',
	groups: 'Groupes',
	/* pricing */
	overcost: 'Surcoût',
	discount: 'Réductions',
	pricing_base: 'Prix de base',
	table_pricing: 'Grille de tarification',
	modify_zone_pricing_success: 'Les tarifs de la zone ont été modifiés avec succès',
	modify_zone_pricing_error: 'Un problème est survenu lors de la modification des tarifs de la zone',
	filter: 'Filtres',
	contractor_lowercase: "Donneur d'ordre",
	transmitter: 'Émetteur',
	transmitter_required: 'Émetteur obligatoire',
	identifier: 'Identifiant',
	client_name: 'Nom du client',
	short_nb_bon: 'Nbrs de bon',
	no_courses: 'Aucune courses',
	see_note: 'Voir le commentaire',
	between: 'Entre',
	and: 'et',
	delivered_by: 'Livré par',
	see_info: 'Voir les informations',
	are_you_sur_delete_task: 'Êtes vous sur de vouloir supprimer la tâche ?',
	packages: 'colis',
	kilo: 'Kg',
	update: 'Modifier',
	duplicate: 'Dupliquer',
	add_a_package: 'Ajouter un colis',
	update_package_types: 'Modifier les types de colis',
	assign_package_types: 'Assignez des types de colis sur le profil du client',
	all_package_type: 'Tous vos types de colis',
	create_package_type: 'Créer un type de colis',
	update_package_type: 'Modifier le type de colis',
	icon: 'Icone',
	new_package_type: 'Nouveau type de colis',
	choose_icon: 'Choisir une icone',
	add_icon: 'Ajouter une icone',
	update_icon: "Modifier l'icone",
	delete_icon: "Supprimer l'icone",
	preview: 'Aperçu',
	simple_name: 'Nom',
	update_package_type_success: 'Le type de colis a bien été modifié',
	update_package_type_error: 'Un problème est survenu lors de la modification du type de colis',
	name_contact: 'Prénom Nom du contact',
	phone_contact: 'Téléphone du contact',
	email_contact: 'Email du contact',
	type_of_package_create_by_merchant: 'Type de colis créé par le client',
	type_of_package_create_by_you: 'Type de colis créé par vous',
	no_zone: 'Aucune zone',
	no_default_package: 'Pas de colis par défaut',
	special_conditions_uppercase: 'CONDITIONS PARTICULIÈRES',
	price_bon: "Prix d'un bon",
	stats_period: 'Période des statistiques',
	nb_courses_total: 'Nombre de courses total',
	activity: 'Activité',
	nb_bon_total: 'Nombre de bons total',
	invoices_caps: 'FACTURES',
	courses_caps: 'COURSES',
	no_invoices: 'Il y a aucune facture pour le moment',
	no_package_type: 'Aucun type de colis',
	beneficiaries: 'Destinataire',
	invoice: 'Facture',
	new_invoice: 'Nouvelle facture',
	remarks: 'REMARQUES',
	Remarks: 'Remarques',
	food: 'Alimentaire',
	nb_colis: 'Nombre de colis',
	pickups: 'Collecte',
	weight_total: 'Poids total',
	add_a_default_package: 'Ajouter le colis par défaut',
	type_required: 'Type obligatoire',
	your_package_type_assign_to_client: 'Vos type de colis assigné à ce client',
	my_courses_treated: 'Mes courses traitées',
	devis: 'Devis',
	to_treated: 'À traiter',
	select_all: 'Tout sélectionner',
	email_invalid: 'Email invalide',
	get_teams_error: 'Un problème est survenu lors de la récupération des sociétés de livraison.',
	new_team: 'Nouvelle société de livraison',
	all_teams: 'Toutes vos sociétés de livraison',
	update_team: 'Modifier la société de livraison',
	create_team: 'Créer la société de livraison',
	my_teams: 'Mes sociétés de livraison',
	collecte: 'Collecte',
	livraison: 'Livraison',
	'livraison/collecte': 'Livraison + Collecte',
	followup_of_deliveries: 'Suivi des livraisons',
	day: 'Jour',
	week: 'Semaine',
	critere: 'Afficher les prix pour le marchant.',
	get_invoices_for_export_error: "Erreur lors de l'exportation",
	search_user_error: 'Erreur lors de la recherche.',
};
export default strings_fr;
