import { push } from 'connected-react-router';
import { takeLatest, put, call } from 'redux-saga/effects';
import { AuthenticationService } from '../../services';
import { CommonActions } from '../common';
import { LoaderActions } from '../loader';
import { types, default as AuthActions } from './actions';
import { default as UserActions } from '../user/actions'
import { select } from 'redux-saga/effects';
import { encode } from "base-64";
import { showSuccess, showError} from  '../../utils/notifications-helper'
import {getTranslate} from "react-localize-redux";
import {graphQLErrorHandling} from "../../services/middleware";

function* login({ username, password }) {
  const locale = yield select(state => state.locale);
  const router = yield select(state => state.router);
  const strings = getTranslate(locale)

  yield put(LoaderActions.loading());

  const [error, response] = yield call(AuthenticationService.login, { username, password });
  if (response && response.request.responseURL) {
    const url = response.request.responseURL;
    const splt = url.split('=');
    const code = splt[1];
    const [tokenError, tokenResponse] = yield call(AuthenticationService.getToken, { username, password, code});
    if (tokenResponse && tokenResponse.data.access_token) {
      const token = tokenResponse.data.access_token;
      const token_type = tokenResponse.data.token_type;
      const refresh_token = tokenResponse.data.refresh_token;
      const authorization = encode(username + ":" + password);
      yield put(AuthActions.loginSuccess({token_type, token, refresh_token, username, authorization}));

      const [newError, newReponse] = yield call(AuthenticationService.getUser, { email: username })
      if (newReponse && newReponse.data) {
        if (newReponse.data.message.role !== "supervisor") {
          yield put(AuthActions.loginFailure());
          showError('authentication_failed', strings);
          yield put(LoaderActions.loaded());
          return;
        }
        yield put(AuthActions.getUserSuccess({role: newReponse.data.message.role, team_id: newReponse.data.message.team_id}));
        yield put(UserActions.getCurrentUserSuccess({user: newReponse.data.message}));
      }
    }
  }
  const {token_type, token} = yield select(state => state.auth);
  yield put(LoaderActions.loaded());
  /*
  {error: "invalid_credentials", message: "The user credentials were incorrect."}
  */
  if (error) {
    yield put(AuthActions.loginFailure());
    showError('authentication_failed', strings);
    return;
  }
  if (router.location.state && router.location.state.pathname.includes("course")) {
    yield put(push(router.location.state.pathname));
  } else {
    yield put(push('/'));
  }
}

function* subscribeRequest({values}) {
  yield put(LoaderActions.loading());
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  values.address = values.address.value

  const [error, response] = yield call(AuthenticationService.subscribeMerchant, values);

  if (error) {
    yield put(LoaderActions.loaded());
    showError('generic_error', strings);
    return;
  } else {
    showSuccess("subscribe_succeed", strings)
    yield put(push('/login'));
  }

  yield put(LoaderActions.loaded());
}

/**
 * Warning: This code is not used. The current refresh action is in middleware
 * @returns {Generator<*, string[]|*[], ?>}
 */
function* refresh() {
  const {refresh_token, authorization, role} = yield select(state => state.auth);
  const [tokenError, tokenResponse] = yield call(AuthenticationService.refreshToken, {authorization, refresh_token});
  if (tokenResponse && tokenResponse.data.access_token) {
    const token = tokenResponse.data.access_token;
    const token_type = tokenResponse.data.token_type;
    const refresh_token = tokenResponse.data.refresh_token;
    yield put(AuthActions.loginSuccess({token_type, token, refresh_token, authorization, role}));
    return [null, `${token_type} ${token}`]
  }
  else {
    yield put(CommonActions.resetReducers());
    yield put(push('/'));
    return [tokenError, null]
  }
}

function* updatePassword({user, password}) {
  yield put(LoaderActions.loading());
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  const [error, response] = yield call(AuthenticationService.updatePassword, {user, password});

  if (error) {
    yield put(LoaderActions.loaded());
    showError('update_password_error', strings, null, error);
    return;
  } else {
    showSuccess("update_password_success", strings)
  }
  yield put(LoaderActions.loaded());
}

function* logout() {
  //yield put(AuthenticationService.logout());
  yield put(CommonActions.resetReducers());
  yield put(push('/'));
}

function *graphqlRetry({services}) {
  yield call(graphQLErrorHandling, services)
}

export default [
  takeLatest(types.GRAPHQL_RETRY, graphqlRetry),
  takeLatest(types.LOGIN_REQUEST, login),
  takeLatest(types.LOGIN_REFRESH, refresh),
  takeLatest(types.SUBSCRIBE_REQUEST, subscribeRequest),
  takeLatest(types.LOGOUT, logout),
  takeLatest(types.UPDATE_PASSWORD, updatePassword),
];
