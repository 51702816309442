import { authenticatedQuery } from './middleware';

const queries = {
	getArea: (point) => `getzonewithpoint(args: {point: "SRID=4326;${point}"}) {
    id
    name
    team_id
    is_admin
  }`,
	users: `users {
      id
      role
      created_at
      updated_at
      firstname
      lastname
      name
      phone_number
      team_id
      address
      email
      merchant {
        id
      }
      dispatchers {
        id
      }
    }`,
	search: (
		value,
		created_by
	) => `view_recipients(where: {_or: [{address_value: {_ilike: "%${value}%"}}, {email: {_ilike: "%${value}%"}}, {name: {_ilike: "%${value}%"}}, {phone_number: {_ilike: "%${value}%"}}, {company: {_ilike: "%${value}%"}}], _and: {created_by: {_eq: ${created_by}}}}) {
      id
      created_at
      created_by
      updated_at
      company
      email
      notes
      name
      phone_number
      address
      team_id
      type
      merchant_id
      default_packages
      package_type_receivers
    }
    view_users(where: {_or: [{address_value: {_ilike: "%${value}%"}}, {email: {_ilike: "%${value}%"}}, {name: {_ilike: "%${value}%"}}, {phone_number: {_ilike: "%${value}%"}}, {company: {_ilike: "%${value}%"}}], _and: {created_by_admin: {_eq: true}}}) {
      id
      created_at
      updated_at
      created_by_admin
      merchant_id
      name
      team_id
      address
      phone_number
      company
      email
      company
      fragile
      food
      package_types_id
      notes
      default_packages
      package_type_receivers
    }
    `,
};

export default {
	users: () => authenticatedQuery(queries.users),
	search: ({ value, created_by }) => authenticatedQuery(queries.search(value, created_by)),
	getArea: ({ point }) => authenticatedQuery(queries.getArea(point)),
};
