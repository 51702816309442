import { methods } from "../utils";
import {authenticatedMutation, authenticatedQuery, authenticatedService} from "./middleware";
import moment from "moment-timezone";
import { gql } from "apollo-boost";

const { POST } = methods;

const RETURNING_MERCHANT = `id
    created_at
    updated_at
    company
    fragile
    food
    package_types_id
    payment_deadline
    discount
    taxe
    bank_ID
    matriculation
    type
    notes
    price_bon
    is_price_visible
    package_types {
      id
      icon_fa
      icon
      desc
      name
      volumn
      weight
      team_id
      merchant_id
    }
    package_type_receivers {
      package_type {
        id
        icon_fa
        icon
        desc
        name
        volumn
        weight
        is_admin
      }
      receiver_recipient_id
      package_type_id
      receiver_merchant_id
    }
    default_packages {
      package {
        id
        fragile
        is_default
        kind_id
        declared_value
        package_type_id
        volumn
        weight
      }
    }
    users(where: {role: {_eq: "merchant_manager"}}) {
      id
      name
      email
      phone_number
      address
      team_id
      new_user
      created_at
      updated_at
      role
    }
`;

/**
 * GRAPHQL QUERIES
 */
const queries = {
  statisticsMerchant: gql`
    query courses_stats($id: Int!, $gte: timestamptz!, $lte: timestamptz!) {
      result: courses_aggregate(
        where: {
          merchant_id: { _eq: $id }
          _and: [
            { tasks: { start_date: { _gte: $gte } } }
            { tasks: { start_date: { _lte: $lte } } }
          ]
        }
      ) {
        aggregate {
          count
          sum {
            nb_bon
          }
        }
      }
    }
  `,
  merchant: (id) => `merchants(where: {id: {_eq: ${id}}}) {
        ${RETURNING_MERCHANT}
  }`,
  merchants: `merchants(where: {created_by_admin: {_eq: true}}) {
      ${RETURNING_MERCHANT}
    }`,
  getMerchantByEmail: (
    email
  ) => `merchants(where: {users: {email: {_eq: "${email}"}}}) {
      id
    }`,
  moreMerchants: (ids) => `merchants(where: {id: {_in: [${ids.join(",")}]}}) {
      ${RETURNING_MERCHANT}
  }`,
  searchMerchants: (
    value
  ) => `view_users(where: {_or: [{address_value: {_ilike: "%${value}%"}}, {email: {_ilike: "%${value}%"}}, {name: {_ilike: "%${value}%"}}, {phone_number: {_ilike: "%${value}%"}}, {company: {_ilike: "%${value}%"}}], _and: {created_by_admin: {_eq: true}}}) {
      id
      created_at
      updated_at
      merchant_id
      name
      address
      phone_number
      company
      email
      company
      fragile
      food
      package_types_id
      notes
      default_packages
      package_type_receivers
      package_types
    }
    `
};
/**
 * GRAPHQL MUTATION
 */

const mutations = {
  importMerchants: (merchants) => `mutation {
    insert_merchants(objects: [${merchants.map(
      (v) => `{
      company: ${v.company ? `"${v.company}"` : `null`},
      created_by_admin: true,
      users: {data: {
        name: ${v.name ? `"${v.name}"` : `null`},
        role: "merchant",
        email: ${merchants.email ? `"${merchants.email}"` : `null`},
        address: ${
          merchants.address
            ? `${JSON.stringify(merchants.address).replace(
                /"(\w+)"\s*:/g,
                "$1:"
              )}`
            : `null`
        },
        phone_number: ${v.phone_number ? `"${v.phone_number}"` : `null`},
        team_id: ${v.team_id ? `${v.team_id}` : `null`},
      }}
    }`
    )}]) {
      returning {
        ${RETURNING_MERCHANT}
      }
    }
  }`,
  merchantCreate: (merchants) => `mutation {
    insert_merchants(objects: {
      company: ${merchants.company ? `"${merchants.company}"` : `null`},
      food: ${
        merchants.food !== undefined && merchants.food !== null
          ? `"${merchants.food}"`
          : `null`
      },
      fragile: ${
        merchants.fragile !== undefined && merchants.fragile !== null
          ? `"${merchants.fragile}"`
          : `false`
      },
      package_types_id: ${
        merchants.package_types_id ? `"${merchants.package_types_id}"` : `null`
      },
      notes: ${
        merchants.notes ? `"${merchants.notes.replace(/\n/g, "\\n")}"` : "null"
      },
      is_price_visible: ${
        merchants.is_price_visible !== undefined && merchants.is_price_visible !== null 
          ? `"${merchants.is_price_visible}"`
          : `false`
      }
      created_by_admin: true,
      users: {data: {
        name: ${merchants.name ? `"${merchants.name}"` : `null`},
        email: ${merchants.email ? `"${merchants.email}"` : `null`},
        role: "merchant_manager",
        address: ${
          merchants.address
            ? `${JSON.stringify(merchants.address).replace(
                /"(\w+)"\s*:/g,
                "$1:"
              )}`
            : `null`
        },
        phone_number: ${
          merchants.phone_number ? `"${merchants.phone_number}"` : `null`
        },
        team_id: ${merchants.team_id ? `${merchants.team_id}` : `null`},
      }}
    })
    {
      returning {
        ${RETURNING_MERCHANT}
      }
    }
  }`,
  merchantUpdate: (merchants) => `mutation {
    update_users(where: {id: {_eq: ${merchants?.users[0]?.id}}},
    _set: {
      name: ${merchants.name ? `"${merchants.name}"` : `null`},
      email: ${merchants.email ? `"${merchants.email}"` : `null`},
      phone_number: ${
        merchants.phone_number ? `"${merchants.phone_number}"` : `null`
      },
      address: ${
        merchants.address
          ? `${JSON.stringify(merchants.address).replace(
              /"(\w+)"\s*:/g,
              "$1:"
            )}`
          : `null`
      },
      team_id: ${merchants.team_id ? `${merchants.team_id}` : `null`}
    }) 
      {
        returning {
          id
          name
          email
          phone_number
          address
          team_id
        }
      },
    update_merchants(where: {id: {_eq: ${merchants.key}}},
     _set: {
      company: ${merchants.company ? `"${merchants.company}"` : `null`},
      food: ${
        merchants.food !== undefined && merchants.food !== null
          ? `"${merchants.food}"`
          : `null`
      },
      fragile: ${
        merchants.fragile !== undefined && merchants.fragile !== null
          ? `"${merchants.fragile}"`
          : `false`
      },
      package_types_id: ${
        merchants.package_types_id ? `"${merchants.package_types_id}"` : `null`
      },
      notes: ${
        merchants.notes ? `"${merchants.notes.replace(/\n/g, "\\n")}"` : "null"
      },
      is_price_visible: ${
        merchants.is_price_visible !== undefined && merchants.is_price_visible !== null 
          ? `"${merchants.is_price_visible}"`
          : `true`
      }
    }),
      {
        returning {
          ${RETURNING_MERCHANT}
        }
      }
  }`,
  merchantDelete: gql`
    mutation update_merchants($id: Int!, $deleted_at: timestamptz!) {
      update_merchants(
        where: { id: { _eq: $id } }
        _set: { deleted_at: $deleted_at }
      ) {
        returning {
          id
        }
      }
    }
  `,
  merchantsDelete: gql`
    mutation update_merchants($ids: [Int!], $deleted_at: timestamptz!) {
      update_merchants(
        where: { id: { _in: $ids } }
        _set: { deleted_at: $deleted_at }
      ) {
        returning {
          id
        }
      }
    }
  `,
};

const routes = {
  userMerchantCreate:
    process.env.REACT_APP_BASE_API_URL + "/users/merchantUser",
  merchantCreate: process.env.REACT_APP_BASE_API_URL + "/users/merchant",
};

export default {
  statisticsMerchant: (values) => authenticatedQuery(queries.statisticsMerchant, values),
  merchant: (id) => authenticatedQuery(queries.merchant(id)),
  merchants: () => authenticatedQuery(queries.merchants),
  moreMerchants: (ids) => authenticatedQuery(queries.moreMerchants(ids)),
  importMerchants: (values) => {
    return authenticatedMutation(mutations.importMerchants(values));
  },
  merchantCreate: (body) => authenticatedService(POST, routes.merchantCreate, body, {}, {}),
  merchantUpdate: (merchant) => authenticatedMutation(mutations.merchantUpdate(merchant)),
  merchantDelete: (data) => {
    return authenticatedMutation(mutations.merchantDelete, {
      id: data,
      deleted_at: moment().format(),
    });
  },
  merchantsDelete: (data) => {
    return authenticatedMutation(mutations.merchantsDelete, {
      ids: data,
      deleted_at: moment().format(),
    });
  },
  createUserMerchant: (body) =>
    authenticatedService(POST, routes.userMerchantCreate, body, {}, {}),
  getMerchantByEmail: (email) => authenticatedQuery(queries.getMerchantByEmail(email)),
  searchMerchants: (value) => authenticatedQuery(queries.searchMerchants(value)),
  //updateUserMerchant: merchant => { return authenticatedMutation(mutations.userMerchantsUpdate(merchant)) },
};
